import { runInAction, decorate, observable } from "mobx";
import {
  getAllTemplates,
  addTemplate,
  addMmsTemplate,
  deleteTemplate,
  updateTemplate,
  updateMmsTemplate,
  getTemplateUser,
  getTemplateById,
  createGlobalTemplate,
  markAsGlobalTemplate
} from "service/templateService";

class ObservableTemplateStore {
  templateList = [];
  selectedTemplate = undefined;
  addTemplateResponse = undefined;
  updateTemplateResponse = undefined;
  deleteTemplateResponse = undefined;
  pageSize = 10;
  current = 1;
  search = "";
  totalPages = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  visibility = "user";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { sortField, sortOrder } = fieldObj;
      this.sortField = sortField;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setVisibility = visibility => {
    runInAction(() => {
      this.visibility = visibility;
    });
  };

  /**
   * Get All Templates
   */
  getAllTemplateList = async args => {
    let params = {
      limit: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search) params.search = this.search;
    if (this.current) params.page = this.current;
    if (args) params = { ...params, ...args };
    const data = await getAllTemplates(params);
    try {
      runInAction(() => {
        this.templateList = data.items;
        this.current = data.current;
        this.pageSize = data.limit;
        this.total = data.total;
        this.totalPages = data.totalPages;
      });
    } catch (error) {
      runInAction(() => {
        this.templateList = error;
      });
    }
    return this.templateList;
  };

  /**
   * Get Template List By User ID
   * Admin
   */
  getTemplateByUser = async userId => {
    const response = await getTemplateUser(userId);
    try {
      runInAction(() => {
        this.templateList = response.data;
        this.selectedTemplate = undefined;
        this.pageSize = 10;
        this.total = 0;
      });
    } catch (error) {
      runInAction(() => {
        this.templateList = error;
      });
    }
    return this.templateList;
  };

  /**
   * Add Template
   */
  addTemplate = async templateData => {
    const addTemplateResponse = await addTemplate(templateData);
    try {
      runInAction(() => {
        this.addTemplateResponse = addTemplateResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addTemplateResponse = error;
      });
    }
    return this.addTemplateResponse;
  };

  /**
   * Add MMS Template
   * @param templateData
   * */
  addMmsTemplate = async templateData => {
    const addTemplateResponse = await addMmsTemplate(templateData);
    try {
      runInAction(() => {
        this.addTemplateResponse = addTemplateResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addTemplateResponse = error;
      });
    }
    return this.addTemplateResponse;
  };

  /**
   * Add Global Template For ADMIN
   * @param templateData
   */
  addGlobalTemplate = async templateData => {
    const addTemplateResponse = await createGlobalTemplate(templateData);
    try {
      runInAction(() => {
        this.addTemplateResponse = addTemplateResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addTemplateResponse = error;
      });
    }
    return this.addTemplateResponse;
  };

  /**
   * Select Template Data
   */
  selectTemplate = templateData => {
    this.selectedTemplate = templateData ? JSON.parse(JSON.stringify(templateData)) : undefined;
  };

  /**
   * Update Template
   */
  updateTemplate = async (templateData, templateId) => {
    const updateTemplateResponse = await updateTemplate(templateData, templateId);
    try {
      runInAction(() => {
        this.updateTemplateResponse = updateTemplateResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateTemplateResponse = error;
      });
    }
    return this.updateTemplateResponse;
  };

  /**
   * Update MMS Template
   * @param templateData
   * */
  updateMmsTemplate = async (templateData, templateId) => {
    const updateTemplateResponse = await updateMmsTemplate(templateData, templateId);
    try {
      runInAction(() => {
        this.updateTemplateResponse = updateTemplateResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateTemplateResponse = error;
      });
    }
    return this.updateTemplateResponse;
  };

  /**
   * mark as global template for admin
   * @param {*} templateId
   * @returns
   */

  markAsGlobalTemplate = async templateId => {
    const markAsGlobalTemplateResponse = await markAsGlobalTemplate(templateId);
    try {
      runInAction(() => {
        this.updateTemplateResponse = markAsGlobalTemplateResponse;
        this.getAllTemplateList();
      });
    } catch (error) {
      runInAction(() => {
        this.updateTemplateResponse = error;
      });
    }
    return this.updateTemplateResponse;
  };

  /**
   * Delete Template
   */
  deleteTemplate = async templateId => {
    const deleteTemplateResponse = await deleteTemplate(templateId);
    try {
      runInAction(() => {
        this.deleteTemplateResponse = deleteTemplateResponse;
        this.selectedTemplate = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.deleteTemplateResponse = error;
      });
    }
    return this.deleteTemplateResponse;
  };

  getTemplateById = async templateId => {
    const data = await getTemplateById(templateId);
    try {
      runInAction(() => {
        this.selectedTemplate = data.data;
      });
    } catch (error) {
      runInAction(() => {
        this.selectedTemplate = error;
      });
    }
    return this.selectedTemplate;
  };
}

decorate(ObservableTemplateStore, {
  templateList: observable,
  selectedTemplate: observable,
  addTemplateResponse: observable,
  updateTemplateResponse: observable,
  deleteTemplateResponse: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  totalPages: observable,
  visibility: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable
});
export default new ObservableTemplateStore();
