import { get, post, put, deleteRequest } from "utils/api";

// get all dedicated numbers list (admin)
export const getAllDedicatedNo = async params => {
  const response = await get(`/admin/dedicated-number/`, params);
  return response.data.data;
};

// get dedicated numbers history (admin)
export const dedicatedNumHistory = async (id, data) => {
  const response = await get(`/admin/get-number-history/${id}`, data);
  return response.data.data;
};

// get dedicated numbers price by product id (admin)
export const getNumberPrice = async id => {
  const response = await get(`/admin/get-price/${id}`);
  return response.data.data;
};

// release dedicated Number of user (admin)
export const releaseDedicationNumber = async id => {
  const response = await put(`/admin/cancel-dedicated-number/${id}`);
  return response.data.data;
};

// delete dedicated number admin
export const deleteDedicatedNumber = async id => {
  const response = await deleteRequest(`/admin/delete-dedicated-number/${id}`);
  return response.data;
};

// add single dedicated number admin
export const addDedicatedNumber = async data => {
  const response = await post(`/admin/dedicated-number`, data);
  return response.data;
};

// edit dedicated number admin
export const editDedicatedNumber = async (id, data) => {
  const response = await put(`/admin/edit-dedicated-number/${id}`, data);
  return response.data;
};

// acitve/inActive dedicated number admin
export const activateDedicatedNum = async params => {
  const response = await put(`/admin/activate-dedicated-number/${params}`);
  return response.data;
};

// acitve/inActive dedicated number admin
export const addBulkDedicatedNums = async data => {
  const response = await post(`/admin/bulk-upload-dedicated-number`, data);
  return response.data;
};

// get all dedicated numbers list user
export const getDedicatedNumList = async params => {
  const response = await get(`/numberassign`, params);
  return response.data.data;
};

// get purchased dedicated number
export const getPurchasedDedicatedNumList = async params => {
  const response = await get(`/numberassign/getUserNumber`, params);
  return response.data.data;
};

// release or remove subscription of dedicated number
export const releaseDedicatedNumber = async id => {
  const response = await put(`/numberassign/${id}`);
  return response.data;
};

// purchase dedicated no
export const purchaseDedicatedNum = async data => {
  const response = await post(`/numberassign`, data);
  return response.data;
};
