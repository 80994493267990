import { runInAction, decorate, observable } from "mobx";
import { getAllIncomings } from "service/incomingService";

class ObservableIncomingStore {
  incomingList = [];
  total = 0;
  pageSize = 10;
  current = 1;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * get all Users
   * @returns {Promise<[]>}
   */
  getAllIncoming = async () => {
    // call & save User list response
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }

    const getAllIncoming = await getAllIncomings(params);

    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getAllIncoming;
        items.forEach(element => {
          element.createdAt = new Date(element.createdAt).toLocaleString();
        });
        this.incomingList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.incomingList;
  };
}

decorate(ObservableIncomingStore, {
  incomingList: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable
});
export default new ObservableIncomingStore();
