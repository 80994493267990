import React from "react";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";
import AppDrawer from "components/AppDrawer/AppDrawer";
import AppButton from "components/AppButton/AppButton";
import CampaignDetails from "components/Campaign/CampaignDetails";
import PhoneContent from "./PhoneContent";
import { toJS } from "mobx";

const ContentDrawer = props => {
  const {
    visible,
    handleClose,
    label = "Campaign Overview",
    messageStore,
    isMms = false,
    actionBtn = false,
    actionBtnType,
    selectedData,
    createCampaignAction,
    handleActions,
    showDetails = true,
    name = "message",
    template = false,
    allowdelete = true,
    allowedit = true,
    setTabName,
    refreshCampaignData
  } = props;

  if (!selectedData) return null;

  return (
    <AppDrawer
      label={label}
      visible={visible}
      onClose={handleClose}
      destroyOnClose
      width={600}
      bodyStyle={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "10px"
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <PhoneContent selectedData={selectedData} messageStore={messageStore} isMms={isMms} visible={visible} template={template} name={name} />
          {/* <PhoneFrame
            headerName={messageStore.phoneFrameSender || "GUNI SMS"}
            currentMessageContent={messageStore.phoneFrameCurrentMessage}
            phoneFrameImageUrl={messageStore.phoneFrameImageUrl}
            isMms={isMms}
          /> */}
        </Col>
      </Row>
      {showDetails && <CampaignDetails campaignData={selectedData} refreshCampaignData={refreshCampaignData} />}
      {actionBtn && actionBtnType === "saved" ? (
        <Row gutter={[16, 16]}>
          <Col span={24} className="d-flex flex-row gap-3">
            <AppButton
              light
              label="Use"
              onClick={() => {
                handleClose();
                createCampaignAction(selectedData, true);
              }}
            />
            {allowdelete && (
              <AppButton
                light
                withoutBg
                label="Delete"
                onClick={() => {
                  handleActions("delete", selectedData);
                  handleClose();
                }}
              />
            )}
          </Col>
        </Row>
      ) : (
        selectedData?.repeat == "one_time" &&
        allowedit && (
          <Row gutter={[16, 16]}>
            <Col span={24} className="d-flex flex-row gap-3">
              <AppButton
                light
                label="Reschedule"
                onClick={() => {
                  handleActions("reschedule", selectedData);
                  handleClose();
                }}
              />
              <AppButton
                light
                withoutBg
                label="Cancel"
                onClick={() => {
                  handleActions("cancel", selectedData);
                  handleClose();
                }}
              />
            </Col>
          </Row>
        )
      )}
    </AppDrawer>
  );
};

export default inject(stores => ({
  messageStore: stores.store.messageStore
}))(observer(ContentDrawer));
