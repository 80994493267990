import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import AppButton from "components/AppButton/AppButton";
import FormInput from "components/FormItems/FormInput";
import styles from "assets/css/modals.module.css";

const Schema = Yup.object().shape({
  longUrl: Yup.string()
    .matches(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, "Enter correct url!")
    .required("Url is required!")
});

const ShortURL = props => {
  const { isVisible, handleCancel, onSubmit } = props;
  const initialValues = { longUrl: "" };

  return (
    <>
      <Modal
        destroyOnClose
        centered
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Create Short URL</h4>
            <p className={`mb-0 ` + styles.modalDes}>
              Create Short URL for your sales,
              <span>
                <br />
                Marketing, Customer Success and Business Communication
              </span>
            </p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={isVisible}
        onCancel={handleCancel}
        maxWidth={600}
        footer={null}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="modal-body px-5">
          <div className="modal-bottom">
            <div className="row justify-content-center">
              <Formik
                initialValues={initialValues}
                validationSchema={Schema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  onSubmit(values);
                  setSubmitting(false);
                  resetForm(initialValues);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormInput name="longUrl" placeholder={"Enter URL"} onChange={handleChange} value={values.longUrl} error={errors.longUrl} touched={touched.longUrl} />
                      <AppButton type="submit" label="Add" light center disabled={isSubmitting} />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShortURL;
