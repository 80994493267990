import { englishCharArr, unicodeCharArr, langRegEx, optOutMessageSample, replyLinkMessageSample, fieldRegEx, replyStopMessageSample } from "../../constant/messageConstants";

/** Check if message is in English
 * @param messageObjParams  => message
 * @returns boolean - true if message is English
 */

export const checkIsEnglish = message => {
  return langRegEx.test(message);
};

/** Compile message into a draft to calculate length
 * trim message to remove white spaces from start to end if applicable - add template variables, replyLinkSample, optOutSample
 * @param messageObjParams @type {object} => message, templateVariables (from group), optout - boolean, replylink - boolean
 * @returns final compiled message
 */

export const compileMessageForLength = messageObjParams => {
  const { message = "", templateVariables, optOut, replyStopToOptOut, replyLink, trimTemplateVariables } = messageObjParams;
  let finalMessage = message;

  if (trimTemplateVariables) finalMessage = getMessageWithoutTemplateVariabls(finalMessage);

  if (templateVariables) finalMessage += ` ${templateVariables}`;

  if (replyLink) finalMessage += `\n${replyLinkMessageSample}`;

  if (optOut) finalMessage += `\n${optOutMessageSample}`;
  else if (replyStopToOptOut) finalMessage += `\n${replyStopMessageSample}`;

  return finalMessage;
};
/** Calculate total message length
 * @param message - text
 * @param isEnglish - boolean - if text is English or Unicode
 * @returns length of message, it computes line breaks as two characters
 */

export const calculateTotalMessageLength = (message, isEnglish) => {
  return isEnglish ? caculateMessageLengthWithLineBreaks(message) : caculateMessageLengthWithLineBreaks(message) * 2;
};

/**
 * Calculates the adjusted length of a message string after applying specific replacements.
 *
 * This function performs the following transformations on the input message:
 * - Replaces placeholders matching the pattern `{{[any word]}}` with a fixed 12-character string.
 * - Replaces all line breaks (`\r\n`, `\r`, or `\n`) with two spaces.
 * - Replaces each occurrence of the special characters |, ^, €, {, }, [, ], ~, \, and newline with two spaces.
 *
 * @param {string} message - The input message to be measured.
 * @returns {number} - The adjusted length of the message.
 */
export const caculateMessageLengthWithLineBreaks = message => {
  if (!message) return 0;
  const placeholderPattern = /\{\{\[[^\]]+\]\}\}/g;
  const placeholderAdjustedMessage = message.replace(placeholderPattern, "XXXXXXXXXXXX");
  const lineBreakAdjustedMessage = placeholderAdjustedMessage.replace(/(\r\n|\r|\n)/g, "  ");
  const specialCharsAdjustedMessage = lineBreakAdjustedMessage.replace(/[|^€{}\[\]~\\\n]/g, "  ");
  return specialCharsAdjustedMessage.length;
};

export const checkTotalMessageCount = (totalCharacters, isEnglish) => {
  if (isEnglish) return englishCharArr.find(x => x.min <= totalCharacters && x.max >= totalCharacters)?.sms;
  else return unicodeCharArr.find(x => x.min <= totalCharacters && x.max >= totalCharacters)?.sms;
};

export const getMessageWithoutTemplateVariabls = message => {
  return message.replace(fieldRegEx, "");
};
