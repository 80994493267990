import { runInAction, decorate, observable, toJS } from "mobx";
import { getAllowedEmails, add, remove } from "../../service/emailSmsService";

class ObservableDeviceStore {
  allowedEmailAddressList = [];
  error = null;

  get = async () => {
    const response = await getAllowedEmails();
    try {
      runInAction(() => {
        this.allowedEmailAddressList = response.data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }

    return this.allowedEmailAddressList;
  };

  add = async ({ email, sender }) => {
    const response = await add({ email, sender });
    try {
      runInAction(() => {
        this.get();
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
    return response;
  };

  remove = async ({ id }) => {
    const response = await remove({ id });
    try {
      runInAction(() => {
        this.get();
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
    return response;
  };
}

decorate(ObservableDeviceStore, {
  allowedEmailAddressList: observable
});
export default new ObservableDeviceStore();
