import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Radio, Row, Col, Tooltip } from "antd";
import FormSelectInput from "components/FormItems/FormSelectInput";
import styles from "./sender-id.module.css";
import { inject, observer } from "mobx-react";
import FormInput from "components/FormItems/FormInput";
import AlertModal from "components/Modals/AlertModal";
import { useEffect } from "react";
import clsx from "clsx";

function SenderIDOption({ authStore, style, index, isSelected, label, value, placeholder, optType, options, setFieldValue, values, messageStore, smallSize = false, disabled = false }) {
  const [addBname, setAddBname] = useState(false);
  const [newSenderIdAlert, setNewSenderIdAlert] = useState(false);
  const history = useHistory();
  const inputRef = useRef();
  const isActive = index === isSelected - 1;

  useEffect(() => {
    if (isSelected !== 3) setAddBname(false);
    if (options && options.length == 1 && index === isSelected - 1) {
      setFieldValue("sender", options[0].value);
      messageStore.setPhoneSender(options[0].value);
    }
  }, [isSelected]);

  useEffect(() => {
    inputRef?.current?.focus({
      cursor: "end"
    });
  }, [inputRef, addBname]);

  const handleChange = e => {
    setFieldValue("sender", e);
    messageStore.setPhoneSender(e);
  };

  return (
    <>
      <Radio className="d-flex align-items-center" style={style} value={value} optType={optType} disabled={disabled}>
        <div className={clsx({ [styles.label]: isActive })}>
          {label}{" "}
          {disabled && (
            <span className="text-danger-color">
              {" "}
              (Get New Sender ID)
              <Tooltip title="More Info">
                <Link to="/sender-ids" className="text-info-color">
                  <i className="fas fa-info-circle ms-2" />
                </Link>
              </Tooltip>
            </span>
          )}
        </div>
      </Radio>
      {options && (
        <div
          className={clsx({ [styles.inputForm]: isActive }, styles.hidden, {
            "ps-4": !smallSize
          })}
        >
          <Row type="flex" align="middle" justify="center" gutter={10}>
            <Col span={22}>
              {addBname ? (
                <FormInput
                  placeholder="Enter Business Name"
                  value={values?.sender}
                  onChange={e => handleChange(e.target.value)}
                  inputProps={{
                    ref: inputRef,
                    maxLength: 11
                  }}
                  size={"default"}
                  name="sender"
                  containerClassname="mb-0"
                />
              ) : (
                <FormSelectInput
                  placeholder={placeholder}
                  name="sender"
                  options={options}
                  size={"default"}
                  onChange={handleChange}
                  value={values?.sender ? values?.sender : undefined}
                  capitalizeLabel={false}
                />
              )}
            </Col>
            <Col span={2} className={clsx({ "p-0": smallSize }, "text-center")}>
              <Tooltip title={"Get New"}>
                <i className={`fas fa-info-circle fs-5 text-info-color`} type="button" onClick={() => history.push("/sender-ids")} />
              </Tooltip>
            </Col>
          </Row>
        </div>
      )}
      <AlertModal
        closable
        visible={newSenderIdAlert}
        title="Use New Sender ID"
        description="You can only use Sender IDs that are assigned to your account. Please contact your parent account to add more."
        primaryBtnProps={{
          onClick: () => setNewSenderIdAlert(false),
          label: "Ok"
        }}
        onCancel={() => setNewSenderIdAlert(false)}
        error
      />
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore,
  messageStore: stores.store.messageStore
}))(observer(SenderIDOption));
