import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Modal, Typography, Row, Col } from "antd";
import styles from "assets/css/modals.module.css";
import clsx from "clsx";
import AppButton from "components/AppButton/AppButton";
import { Form, Formik } from "formik";
import FormInput from "components/FormItems/FormInput";
import * as Yup from "yup";
import FormSelectInput from "components/FormItems/FormSelectInput";
import AppDatePicker from "components/AppPickers/AppDatePicker";
import { messageStatus } from "constant/commonList";
import moment from "moment";

const ExportNotificationModal = ({ modalStore, className, authStore, isMobile = false, limiter = false, isHistory = false, handleExport, ...rest }) => {
  const formikRef = useRef();
  const inputRef = useRef();
  const [tab, setTab] = useState(isHistory ? "0" : "1");

  let initialValues = {
    customEmail: authStore?.user?.email,
    ...(isHistory && {
      dates: [moment().subtract(30, "days"), moment()],
      status: "all"
    })
  };

  return (
    <Modal
      visible={modalStore.showExportNotification}
      onCancel={() => modalStore.toggleModal("showExportNotification", false)}
      footer={null}
      destroyOnClose
      centered
      bordered={false}
      className={clsx("mx-auto p-3 text-center", className)}
      zIndex={1001}
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      title={
        tab !== "2" ? (
          <div className="text-ceter">
            <h5 className="pt-3 fw-bold">
              Export
              <i className="fas fa-file-export ms-3 text-info-color align-middle" style={{ fontSize: "28px" }}></i>
            </h5>
          </div>
        ) : (
          <div className="text-center">
            <i className="fas fa-clock text-info-color" style={{ fontSize: "60px" }}></i>
            <h5 className="pt-3 fw-bold">We are processing your request</h5>
          </div>
        )
      }
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      {...rest}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          customEmail: Yup.string().required("Required"),
          dates: Yup.array().when([], {
            is: () => isHistory,
            then: Yup.array()
              .test("date-range", "Date range must be within one month", function(dates) {
                const startDate = moment(dates[0]);
                const endDate = moment(dates[1]);

                if (!startDate.isValid() || !endDate.isValid()) {
                  return false;
                }

                const dayDifference = endDate.diff(startDate, "days");

                return dayDifference >= 0 && dayDifference <= 30;
              })
              .required("Required")
          }),
          status: Yup.string().when([], {
            is: () => isHistory,
            then: Yup.string().required("Required")
          })
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isHistory && tab === "0") {
            setTab("1");
            setSubmitting(false);
          } else {
            console.log(values);
            handleExport(values, setTab);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, errors, values, handleChange, touched, setFieldValue }) => {
          return (
            <Form>
              {tab === "0" ? (
                <Row>
                  <Col span={24} className="text-start">
                    <label className="fw-bold">Date Range</label>
                    <AppDatePicker
                      errors={errors.dates}
                      touched={touched.dates}
                      onChange={(dates, datesString) => {
                        setFieldValue("dates", dates);
                        setFieldValue("from", datesString[0]);
                        setFieldValue("to", datesString[1]);
                      }}
                      size="default"
                      name="dates"
                      value={values.dates}
                      showToday={false}
                      placeholder="Select Date"
                      className="w-100"
                      rangeSelector
                    />
                  </Col>
                  <Col span={24} className="text-start">
                    <label className="fw-bold">Message Status</label>
                    <FormSelectInput
                      containerClassnames="mb-4"
                      placeholder="Select Status"
                      name="status"
                      size="default"
                      onChange={e => setFieldValue("status", e)}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                      options={messageStatus}
                    />
                  </Col>
                  <Col span={24}>
                    <AppButton label="Next" light center type="submit" className="mt-3" />
                  </Col>
                </Row>
              ) : tab === "1" ? (
                <div className="fadein">
                  <h6 className="mt-2">Exported file will be sent to following Email Id</h6>
                  <div className="vh-center gap-2 align-items-start">
                    <FormInput
                      value={values.customEmail}
                      name="customEmail"
                      onChange={handleChange}
                      placeholder="Email Id"
                      inputProps={{
                        ref: inputRef
                      }}
                      inputClassname="m-0"
                      size="default"
                      error={errors.customEmail}
                      touched={touched.customEmail}
                    />
                    <AppButton
                      label="Custom email"
                      withoutBg
                      className="mt-1 rounded"
                      disabled={isSubmitting}
                      onClick={() => {
                        setFieldValue("customEmail", "");
                        inputRef.current?.focus();
                      }}
                    />
                  </div>
                  <div className="vh-center gap-3">
                    {isHistory && <AppButton label="Back" light className="mt-3" disabled={isSubmitting} onClick={() => setTab("0")} />}
                    <AppButton label="Export" light className="mt-3" type="submit" disabled={isSubmitting} />
                  </div>
                </div>
              ) : (
                <div className="fadein">
                  <Typography.Paragraph strong>
                    Depending on the amount of data you've requested, this may take several minutes. You can move on to your next task in the meantime.
                  </Typography.Paragraph>
                  <Typography.Paragraph className="px-md-5 px-3 mx-md-5">{modalStore.exportMessage}</Typography.Paragraph>
                  <Typography.Paragraph strong className="px-md-5 px-3 mx-md-5 text-info-color">
                    {modalStore.exportEmail || values.customEmail}
                  </Typography.Paragraph>
                  {/* {limiter && (
                    <Typography.Text type="secondary">
                    System will export upto 50k records per request.
                    </Typography.Text>
                    )}
                    <br /> */}
                  <AppButton light label="Done" className="mt-3" onClick={() => modalStore.toggleModal("showExportNotification", false)} />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore
}))(observer(ExportNotificationModal));
