import React from "react";
import { inject, observer } from "mobx-react";
import { Modal } from "antd";
import EmailVerifyForm from "../Form/EmailVerifyForm";
import styles from "assets/css/modals.module.css";

const EmailOtpModal = props => {
  const { modalStore } = props;
  return (
    <Modal
      title={
        <div className="text-center">
          <h4 className="pt-3 fw-bold">Verify your Email</h4>
          <p className={`mb-0`}>
            You need to first verify your email,
            <span>
              <br />
              So you can submit your business profile
            </span>
          </p>
        </div>
      }
      destroyOnClose
      zIndex={1001}
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      onCancel={() => {
        modalStore.toggleModal("verifyEmailOtp", false);
      }}
      visible={modalStore.verifyEmailOtp}
      maxWidth={800}
      footer={null}
      centered
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
    >
      <div className="px-2">
        <EmailVerifyForm />
      </div>
    </Modal>
  );
};
export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(EmailOtpModal));
