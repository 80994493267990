import { runInAction, decorate, observable } from "mobx";
import { SendSMS } from "../../service/smsService";

class ObservableDeviceStore {
  sendStatus = undefined;
  loading = false;

  sendSMS = async data => {
    // call api for add Send Message
    this.loading = true;
    try {
      const response = await SendSMS(data);
      runInAction(() => {
        this.sendStatus = response;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.sendStatus = "Error sending SMS";
      });
    }
    return { response: this.response, loading: this.loading };
  };
}

decorate(ObservableDeviceStore, {
  sendSMS: observable,
  sendStatus: observable,
  loading: observable
});
export default new ObservableDeviceStore();
