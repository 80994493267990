import { Col, Row } from "antd";
import AppButton from "components/AppButton/AppButton";
import FormInput from "components/FormItems/FormInput";
import { Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ZohoLogo from "assets/images/thirdparty app/zoho.png";
import MergeLabelsForm from "components/TargetGroup/GroupForms/MergeLabelsForm";
import GroupForm from "./Forms/GroupForm";
import SpinnerLoader from "components/AppLoader/SpinnerLoader";
import { isEmpty } from "lodash";

const ZohoApp = ({ groupStore, integration, setStep, authStore, onSuccess }) => {
  const { zohoFields, handleConnect, importContacts, groupName, zohoFieldList } = integration;
  const { setGroupData, group } = groupStore;
  const { user } = authStore;

  const [domainStep, setDomainStep] = useState(isEmpty(user?.zohoRefreshToken) ? 1 : 2);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (!isEmpty(zohoFieldList?.type)) {
      setLoading(true);
      zohoFields(zohoFieldList?.type)
        .then(res => {
          if (res.length > 0)
            setGroupData({
              headers: res.map((_, i) => ({
                id: `zoho-${i}`,
                newLabel: _?.label,
                oldLabel: _?.name,
                type: _?.fieldType
              }))
            });
          setFilters();
          setLoading(false);
        })
        .catch(err => {
          setDomainStep(2);
        });
    }
  }, [zohoFieldList?.type]);

  const onMergeLabels = (values, reset) => {
    values.name = groupName;
    values.crmModule = zohoFieldList?.type;
    delete values.headers;

    if (group) {
      values.name = group?.name;
      values.groupid = group?._id;
    }

    if (filters?.filter === "criteria" && filters?.zohoFilter) {
      values.zohoAPIsURL = user?.zohoAPIsURL;
      values.criteria = filters?.zohoFilter
        .filter(_ => _?.field)
        .map(_ => `(${_.field}:${_.operator}:${_.value})`)
        .join("and");
    }
    console.log(values);

    importContacts("zoho", values)
      .then(res => {
        if (res?.status) {
          onSuccess(res.data);
        }
      })
      .finally(() => {
        reset();
      });
  };

  const onBack = () => {
    user?.zohoRefreshToken ? setStep(1) : setDomainStep(1);
  };

  return (
    <Row gutter={20} type="flex" justify="center" className="h-100">
      {domainStep === 1 ? (
        <Col md={20} lg={18} xl={16}>
          <Formik
            enableReinitialize
            initialValues={{
              accountsURL: ""
            }}
            validationSchema={Yup.object().shape({
              accountsURL: Yup.string().when([], {
                is: () => domainStep,
                then: Yup.string()
                  .matches(/^(https?:\/\/)?crm\.zoho\.[a-zA-Z]{2,3}(\.[a-zA-Z]{2,3})?$/, 'URL must be valid Zoho url"')
                  .required("Required")
              })
            })}
            onSubmit={(values, { setSubmitting }) => {
              let payload = values;
              payload = {};
              payload.accountsURL = values.accountsURL.replace(/^https?:\/\/?|crm/g, _ => (_ === "crm" ? "accounts" : ""));

              handleConnect(
                "zoho",
                () => {
                  setDomainStep(2);
                },
                payload
              );
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, errors, touched, values, handleChange }) => {
              return (
                <Form className="pt-5 mt-5">
                  <Row type="flex" justify="center">
                    <Col span={24} className="text-center mb-3">
                      <img src={ZohoLogo} width="95" alt="zoho logo" />
                      <p className="text-muted text-dark fs-5 mt-3">Enter your Zoho CRM domain from the url.</p>
                    </Col>
                    <Col span={20}>
                      <FormInput
                        name={"accountsURL"}
                        value={values.accountsURL}
                        onChange={handleChange}
                        size="default"
                        placeholder="e.g. https://crm.zoho.com, https://crm.zoho.com.au, etc."
                        error={errors.accountsURL}
                        touched={touched.accountsURL}
                        containerClassname="mb-0"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="mt-5 vh-center gap-3">
                      <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setStep(1)} />
                      <AppButton label="Connect" type="submit" postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />} light disabled={isSubmitting} />
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      ) : domainStep === 2 ? (
        <Col span={24}>
          <GroupForm app={"zoho"} onBack={onBack} step={domainStep} setStep={() => setDomainStep(3)} onSuccess={_ => setFilters(_)} />
        </Col>
      ) : (
        <Col span={24}>
          {loading ? (
            <div className="text-center m-5 vh-center gap-3 fw-bold pt-5">
              <SpinnerLoader />
              Fetching Zoho fields...
            </div>
          ) : (
            <MergeLabelsForm
              connectApp
              onSuccess={onMergeLabels}
              defaultLabel={[
                {
                  id: 3,
                  newLabel: "Email",
                  oldLabel: ""
                }
              ]}
              footerProps={
                <Col className="align-self-end">
                  <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setDomainStep(2)} />
                </Col>
              }
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  groupStore: stores.store.groupStore,
  integration: stores.store.integrationStore
}))(observer(ZohoApp));
