import React, { useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";
import { inject, observer } from "mobx-react";
import AppButton from "components/AppButton/AppButton";
import Check from "assets/images/upgradeCheckMark.png";
import SocialOptions from "./Form/SocialOptions";
import AdditionalDetails from "./Form/AdditionalDetails";
import { useHistory } from "react-router";
import styles from "assets/css/modals.module.css";
import EmailOtpModal from "./Modals/EmailOtpModal";

const View = props => {
  const { authStore, modalStore, userVerificationStore } = props;
  const { setVerificationId, setFormData } = userVerificationStore;
  const { user } = authStore;

  const [showUpgrade, setShowUpgrade] = useState(1);
  const history = useHistory();
  const hideShowUpgrade = stage => {
    setShowUpgrade(stage);
  };
  const isVerificationSubmitted = authStore?.user?.sendForVerification;

  useEffect(() => {
    if (modalStore.showUpgrade) {
      hideShowUpgrade(1);
    }
  }, [modalStore.showUpgrade]);

  function handleClick() {
    modalStore.toggleModal("showUpgrade", false);
    if (isVerificationSubmitted) {
      history.push("/user-verification");
    } else {
      if (user?.isEmailVerified) {
        setVerificationId();
        setFormData();
        history.push("/create-user-verification");
      } else {
        modalStore.toggleModal("verifyEmailOtp", true);
        history.push("/user-verification");
      }
    }
  }

  // function handleComplete() {
  //   if (authStore?.user?.isEmailVerified) {
  //     hideShowUpgrade(3);
  //   } else {
  //     hideShowUpgrade(2);
  //   }
  // }

  return (
    <React.Fragment>
      <EmailOtpModal />
      {showUpgrade === 1 && (
        <div className="container-fluid text-dark">
          {
            // (authStore?.user?.isEmailVerified &&
            //   authStore?.user?.isMobileVerified) ||
            // isVerificationSubmitted ? (
            <>
              <div className="mb-3">
                <Typography.Title level={4} className="fw-bolder text-center ">
                  Verification pending
                </Typography.Title>
                <div
                  className={`btn ${styles.modalCloseBtn}`}
                  onClick={() => {
                    history.goBack();
                    modalStore.toggleModal("showUpgrade", false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </div>
              </div>
              <div className="mb-3">
                <Typography.Paragraph className="text-center">
                  {isVerificationSubmitted ? "Your Account verification approval is in-progress." : "Need to Submit & get Approved your account verification form."}
                </Typography.Paragraph>
              </div>
              <div className="my-3 text-center">
                <AppButton light label={isVerificationSubmitted ? "Check Status" : "Continue"} className="mx-2" onClick={() => handleClick()} />
                {/* {!isVerificationSubmitted && (
                  <AppButton
                    withoutBg
                    label="Cancel"
                    className="mx-2"
                    onClick={() => {
                      history.goBack();
                      modalStore.toggleModal("showUpgrade", false);
                    }}
                  />
                )} */}
              </div>
            </>
            // ) : (
            //   <>
            //     <div className="mb-3">
            //       <Typography.Title level={4} className="fw-bolder text-center ">
            //         Complete Profile and unlock powerful features
            //       </Typography.Title>
            //     </div>
            //     <Row className="my-3">
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>Free 25 SMS Credit</div>
            //       </Col>
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>Free 5 MMS Credit</div>
            //       </Col>
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>Buy Credit</div>
            //       </Col>
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>API Integration</div>
            //       </Col>
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>Buy Sender IDs</div>
            //       </Col>
            //       <Col span={12} className="d-flex align-items-center">
            //         <img src={Check} alt="check" className="ms-3" />
            //         <div>And More...</div>
            //       </Col>
            //     </Row>
            //     <div className="text-center">
            //       <AppButton
            //         light
            //         label="Complete Profile"
            //         className="me-2"
            //         onClick={() => handleClick()}
            //       />
            //       <AppButton
            //         withoutBg
            //         className="ms-2"
            //         label="Skip"
            //         onClick={() => {
            //           modalStore.toggleModal("showUpgrade", false);
            //           history.push("/user-verification");
            //         }}
            //       />
            //     </div>
            //   </>
            // )
          }
        </div>
      )}
      {showUpgrade === 2 && (
        <React.Fragment>
          <div className="text-center pb-2">
            <h4 className="pt-3 fw-bold">{"Choose a way to continue"}</h4>
          </div>
          <SocialOptions hideShowUpgrade={hideShowUpgrade} />
        </React.Fragment>
      )}
      {showUpgrade === 3 && <AdditionalDetails />}
    </React.Fragment>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  userVerificationStore: stores.store.userVerificationStore
}))(observer(View));
