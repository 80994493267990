import { deleteRequest, get, post } from "utils/api";

// SQUARE-SPACE
export const getContactInfo = async contact => {
  const addResponse = await get(`/squarespace/get-retail-info/${contact}`);
  return addResponse.data;
};

export const searchContact = async contact => {
  const addResponse = await get(`/squarespace/get-contact-information/${contact}`);
  return addResponse.data;
};

export const sendInvoice = async data => {
  const addResponse = await post(`/squarespace/create-invoice`, data);
  return addResponse.data;
};

// HUBSPOT
export const importHubspotContacts = async data => {
  const addResponse = await post(`/hubspot/createContact`, data);
  return addResponse.data;
};

export const saveHubspotToken = async data => {
  const addResponse = await post(`/hubspot/save-refresh-token`, data);
  return addResponse.data;
};

export const savePipedriveToken = async payload => {
  const response = await post("/pipedrive/save-refresh-token", payload);
  return response.data;
};

export const hubspotFieldList = async () => {
  const addResponse = await get(`/hubspot/fetch-properties`);
  return addResponse.data;
};

export const removeAccount = async data => {
  const deleteResponse = await deleteRequest("/user/removeAccount", data);

  return deleteResponse.data;
};

export const getMessageById = async data => {
  const messageResponse = await get(`/auth/hs/message/${data}`);

  return messageResponse.data;
};

// ZOHO
export const zohoFields = async params => {
  const addResponse = await get(`/zoho/fetch-fields?crmModule=${params}`);
  return addResponse.data;
};

export const saveZohoToken = async data => {
  const addResponse = await post(`/zoho/save-refresh-token`, data);
  return addResponse.data;
};

export const importZohoContacts = async data => {
  const addResponse = await post(`/zoho/createContact`, data);
  return addResponse.data;
};

// GHL
export const saveGHLToken = async data => {
  const addResponse = await post(`/ghl/save-refresh-token`, data);
  return addResponse.data;
};

// ACTIVE CAMPAIGN

export const saveActiveCampToken = async data => {
  const addResponse = await post(`/ac/save-token`, data);
  return addResponse.data;
};

export const activeCamapaignFeilds = async data => {
  const addResponse = await get(`/ac/fetch-fields`, data);
  return addResponse.data;
};

export const activeCamapaignFilter = async data => {
  const addResponse = await get(`/ac/fetch-filters`, data);
  return addResponse.data;
};

export const importACContacts = async data => {
  const addResponse = await post(`/ac/createContact`, data);
  return addResponse.data;
};

export const pipedriveFieldList = async data => {
  const addResponse = await get(`/auth/pipedrive/fetch-properties`);

  return addResponse.data;
};

export const importPipedriveContacts = async data => {
  const addResponse = await post("/auth/pipedrive/createContacts", data);

  return addResponse.data;
};
