import React from "react";
import { Card } from "antd";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import PaymentForm from "../../components/BuyCredits/PaymentForm";

const Checkout = props => {
  const { modalStore, userStore, authStore } = props;

  const { user } = authStore;
  const { specialPlan: plan, buyCreditPackage } = userStore;

  const history = useHistory();

  if (!buyCreditPackage) {
    history.push("/buy-credits");
    return null;
  }

  return (
    <Card className={`my-3 w-100 rounded-xl bg-white shadow mx-auto p-md-3`} bordered={false}>
      <PaymentForm
        bonus={buyCreditPackage?.bonus}
        totalPrice={buyCreditPackage.priceWithTax / 100}
        totalSMS={modalStore.isMms ? buyCreditPackage?.mms : buyCreditPackage?.sms}
        withOutTax={buyCreditPackage.price / 100}
        isMms={modalStore.isMms}
        costperSMS={modalStore?.isMms ? buyCreditPackage?.centsPerMMS : buyCreditPackage?.centsPerSMS}
        isFirstBuy={false}
        specialPlanId={plan && plan._id}
        special={buyCreditPackage.specialPlan}
        isBundle={buyCreditPackage.isBundle}
      />
    </Card>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  userStore: stores.store.userStore
}))(observer(Checkout));
