import React, { useMemo, useState } from "react";
import { Card, Col, Modal, Row, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { guniHelpUrl, sharedNumber } from "constant/constant";
import styles from "components/Dashboard/DashboardCards/cards.module.css";
import AppButton from "components/AppButton/AppButton";
import ImportContact from "components/TargetGroup/Modals/ImportContact";
import modalStyles from "assets/css/modals.module.css";

const QuickActions = props => {
  const { authStore, modalStore, messageStore, gatewayMmsStore, gatewayBulkStore, groupStore, isMobile = false } = props;

  const [modal, setModal] = useState(false);
  const [content, setContent] = useState();

  const { user, allSenders } = authStore;
  const { setGroupData, setResponse } = groupStore;

  const location = useLocation();
  const history = useHistory();

  const { setCampaignData } = useMemo(() => {
    if (modalStore.isMms) {
      return gatewayMmsStore;
    } else {
      return gatewayBulkStore;
    }
  }, [modalStore.isMms]);

  const showQuickSmsModal = () => {
    modalStore.setData("quickSMSContent", "");
    messageStore.setCurrentMessage("");
    messageStore.setPhoneSubject("");
    if (modalStore.isMms) {
      messageStore.setPhoneSender(user?.mobileSenders.includes(user?.defaultSender) ? user?.defaultSender : sharedNumber);
    } else {
      messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
    }
    messageStore.phoneFrameImageUrl = undefined;
    messageStore.phoneFrameImageFileUrl = undefined;
    if (location.pathname !== "/campaign/create" || location.pathname !== "/inbox-messaging" || location.pathname !== "/quick-message" || location.pathname !== "/template/add") {
      localStorage.setItem("lastLocation", location.pathname);
      history.push("/quick-message");
    } else {
      history.push("/quick-message");
    }
  };

  const goToCampaign = () => {
    setCampaignData(null);
    localStorage.setItem("step", 0);
    localStorage.removeItem("campaign");
    messageStore.setCurrentMessage("");
    messageStore.setPhoneSubject("");
    messageStore.setPhoneSender(
      modalStore.isMms
        ? user?.mobileSenders.includes(user?.defaultSender)
          ? user?.defaultSender
          : sharedNumber
        : allSenders.find(item => item.value === user?.defaultSender)
        ? user?.defaultSender
        : sharedNumber
    );
    messageStore.setCurrentImage(null);
    history.push("/campaign/create");
  };

  const uploadContacts = () => {
    modalStore.toggleModal("showImportContactsModal", true);
    setResponse("group", undefined);
    setGroupData(null);
  };

  const createTemplate = () => {
    history.push(`/template/add`);
  };

  const goToEmailToSMS = () => {
    history.push("/email-sms");
  };

  const goToSenderID = () => {
    history.push("/sender-ids");
  };

  const onVideoClick = item => {
    setContent(item.ifram);
    setModal(true);
  };

  const cardContent = [
    // {
    //   title: 'Quick SMS/MMS',
    //   icon: <i className="bx bxs-send text-secondary fs-2 align-middle" />,
    //   videoId: "",
    //   onClick: showQuickSmsModal,
    //   btnLabel: "Send Now",
    //   onMobile: true,
    //   learnLink:
    //     "https://help.gunisms.com.au/knowledge-base/send-simple-message"
    // },
    {
      title: "Campaign",
      icon: <i className="bx bxs-megaphone text-secondary fs-2 align-middle" />,
      videoId: "Y0_fvAYVoYQ",
      onClick: goToCampaign,
      btnLabel: "Run Campaign",
      onMobile: true,
      learnLink: "https://help.gunisms.com.au/kb/start-sms-campaign"
    },
    {
      title: "Upload Contact",
      icon: <i className="bx bxs-user text-secondary fs-2 align-middle" />,
      videoId: "D-ool5mcTNc",
      onClick: uploadContacts,
      btnLabel: "Upload",
      onMobile: false,
      learnLink: "https://help.gunisms.com.au/kb/uploading-contacts-using-excel-file-2"
    },
    // {
    //   title: 'Create Templates',
    //   icon: <i className="bx bxs-file-blank text-secondary fs-2 align-middle" />,
    //   videoId: "",
    //   onClick: createTemplate,
    //   btnLabel: "Create",
    //   onMobile: true,
    //   learnLink:
    //     "https://help.gunisms.com.au/knowledge-base/create-sms-template"
    // },
    {
      title: "Email to SMS",
      icon: <i className="bx bxs-envelope text-secondary fs-2 align-middle" />,
      videoId: "dsei5EIApvk",
      onClick: goToEmailToSMS,
      btnLabel: "Check Now",
      onMobile: false,
      learnLink: "https://help.gunisms.com.au/kb/email-to-sms-feature"
    },
    {
      title: "Sender ID",
      icon: <i className="bx bxs-user-badge text-secondary fs-2 align-middle" />,
      videoId: "nAdJXJdAU-k",
      onClick: goToSenderID,
      btnLabel: "Check Now",
      onMobile: false,
      learnLink: "https://help.gunisms.com.au/kb/set-sender-id"
    }
  ];

  return (
    <React.Fragment>
      <Row type="flex" align="middle" justify="center" gutter={[20, 20]} className="my-3 w-100 rounded-xl bg-white shadow mx-auto p-3">
        <Col span={24} className="text-start text-md-end">
          <AppButton
            size="default"
            light
            type="button"
            prefixIcon={<i className="bx bx-book-bookmark align-middle me-2" />}
            onClick={() => window.open(guniHelpUrl, "_blank")}
            label={"Documentation"}
            className={` ${styles.shortcutBtn}`}
          />
        </Col>
        {cardContent.map((item, key) => {
          return (
            <Col key={key}>
              <Card
                className={`rounded-xl shadow text-start border-0 overflow-hidden`}
                bodyStyle={{ padding: "0px" }}
                cover={
                  <div className="w-100 text-center py-3">
                    <Typography.Text
                      strong
                      level={4}
                      className="my-2 py-3 text-nowrap overflow-hidden"
                      style={{
                        background: "var(--second-bg-color)",
                        webkitBackgroundClip: "text",
                        webkitTextFillColor: "transparent"
                      }}
                    >
                      <span className="mb-1">{item.icon}</span>
                      <span className="text-nowrap mb-1 mx-1">{item.title}</span>
                    </Typography.Text>
                  </div>
                }
              >
                {item.videoId && (
                  <a href={`https://www.youtube.com/embed/${item.videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0`} target="_blank" rel="noopener noreferrer">
                    <img width="322" height="182" alt="thumbnail" src={`http://img.youtube.com/vi/${item.videoId}/mqdefault.jpg`}></img>
                  </a>
                )}
                <div className="p-3 w-100 d-flex justify-content-evenly align-items-center gap-2">
                  <AppButton size="default" light type="button" onClick={() => item.onClick()} label={item.btnLabel} className={` ${styles.shortcutBtn}`} />
                  <AppButton
                    prefixIcon={<i className="bx bx-book-bookmark align-middle me-2" />}
                    size="default"
                    onClick={() => window.open(item.learnLink, "_blank")}
                    label={"Learn"}
                    withoutBg
                    className={``}
                  />
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
      <ImportContact />
      <Modal
        title={null}
        destroyOnClose
        zIndex={1001}
        closeIcon={
          <div className={`btn ${modalStyles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modal}
        onCancel={() => {
          setModal(false);
        }}
        width={620}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="p-0" style={{ minHeight: "300px" }}>
          {content}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  groupStore: stores.store.groupStore
}))(observer(QuickActions));
