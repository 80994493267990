import { runInAction, decorate, observable, toJS } from "mobx";
import {
  getBlockBusinessNames,
  blockBusinessName,
  deleteBlockBusinessName,
  getBusinessNamesVerificationList,
  approveOrRejectBusinessName,
  addBusinessNameForVerification,
  getListBusinessNames,
  deleteBusinessNameAdmin
} from "service/businessNameServices";

class ObservableBusinessNameStore {
  businessNameList = [];
  selectedName = undefined;
  response = undefined;
  pageSize = 10;
  current = 1;
  search = "";
  totalPages = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  selectNameId = id => {
    runInAction(() => {
      this.selectedName = id;
    });
  };

  // admin methods
  getBusinessNamesVerificationList = async () => {
    this.businessNameList = [];
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize
    };
    if (this.search) params.search = this.search;
    const response = await getBusinessNamesVerificationList(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = response;
        this.businessNameList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.response = "success";
      });
    } catch (error) {
      this.businessNameList = [];
      this.response = error;
    }
    return this.response;
  };

  approveOrRejectBusinessName = async (id, status) => {
    const response = await approveOrRejectBusinessName(id, status);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  deleteBusinessNameAdmin = async id => {
    const response = await deleteBusinessNameAdmin(id);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.businessNameList = this.businessNameList.filter(item => item._id !== id);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  getBlockBusinessNames = async () => {
    this.businessNameList = [];
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize
    };
    if (this.search) params.search = this.search;
    const getBlockBusinessNamesResponse = await getBlockBusinessNames(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBlockBusinessNamesResponse;
        this.businessNameList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.response = "success";
      });
    } catch (error) {
      runInAction(() => {
        this.businessNameList = [];
        this.response = error;
      });
    }
    return this.businessNameList;
  };

  deleteBlockBusinessName = async id => {
    const deleteBlockBusinessNameResponse = await deleteBlockBusinessName(id);
    try {
      runInAction(() => {
        this.businessNameResponse = deleteBlockBusinessNameResponse;
        if (deleteBlockBusinessNameResponse.status) {
          this.businessNameList = this.businessNameList.filter(item => item._id !== id);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.businessNameResponse = error;
      });
    }
    return this.businessNameResponse;
  };

  blockBusinessName = async businessName => {
    const blockBusinessNameResponse = await blockBusinessName(businessName);
    try {
      runInAction(() => {
        this.businessNameResponse = blockBusinessNameResponse;
        if (blockBusinessNameResponse.status) {
          this.businessNameList = [
            {
              _id: this.businessNameResponse.data._id,
              senderId: this.businessNameResponse.data.senderId,
              createdAt: new Date(),
              updatedAt: new Date()
            },
            ...this.businessNameList
          ];
          this.businessNameList.pop();
        }
      });
    } catch (error) {
      runInAction(() => {
        this.businessNameResponse = error;
      });
    }
    return this.businessNameResponse;
  };

  // user methods
  addBusinessNameForVerification = async values => {
    const response = await addBusinessNameForVerification(values);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.businessNameList = [
            {
              _id: "newname",
              status: "pending",
              name: values.name,
              createdAt: new Date()
            },
            ...this.businessNameList
          ];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  getListBusinessNames = async () => {
    this.businessNameList = [];
    const response = await getListBusinessNames();
    try {
      runInAction(() => {
        this.businessNameList = response;
        this.response = "success";
      });
    } catch (error) {
      this.businessNameList = [];
      this.response = error;
    }
    return this.response;
  };

  removeBusinessName = id => {
    runInAction(() => {
      this.businessNameList = this?.businessNameList?.filter(item => item._id !== id);
    });
  };
}

decorate(ObservableBusinessNameStore, {
  businessNameList: observable,
  selectedName: observable,
  response: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  totalPages: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable
});
export default new ObservableBusinessNameStore();
