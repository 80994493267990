import { runInAction, decorate, observable } from "mobx";
import { checkShortCode, confirmReply } from "service/replyService";
import { message } from "antd";

class ObservableReplyStore {
  shortCodeResponse = "";
  Logo = "";
  replyLinkMessage = "";

  checkShortCodeResponse = async code => {
    const shortCodeResponse = await checkShortCode(code);
    console.log("\n shortCodeResponse", shortCodeResponse);
    try {
      runInAction(() => {
        (this.Logo = shortCodeResponse.data?.Logo), (this.replyLinkMessage = shortCodeResponse.data?.replyLinkMessage);
        this.shortCodeResponse = shortCodeResponse.data?.result;
      });
    } catch (error) {
      runInAction(() => {
        this.shortCodeResponse = error;
      });
    }
  };

  confirmReply = async (code, params) => {
    const confirmReplyResponse = await confirmReply(code, params);
    try {
      runInAction(() => {
        if (confirmReplyResponse.data.result == "success") {
          message.success("Thanks! Your answer has been submitted.", 6);
          this.shortCodeResponse = "postsuccess";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.shortCodeResponse = error;
      });
    }
  };
}

decorate(ObservableReplyStore, {
  Logo: observable,
  replyLinkMessage: observable,
  shortCodeResponse: observable
});

export default new ObservableReplyStore();
