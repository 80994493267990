import React from "react";

const BUFFER_TIME = 1500;

const useInternetStatus = () => {
  const [internetStatus, setInternetStatus] = React.useState(true);

  function onOffline() {
    setTimeout(() => {
      console.log("Going offline", false);
      setInternetStatus(false);
    }, BUFFER_TIME);
  }

  function onOnline() {
    setTimeout(() => {
      console.log("Going online", true);
      setInternetStatus(true);
    }, BUFFER_TIME);
  }

  React.useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);

  return internetStatus;
};

export default useInternetStatus;
