import { post, get, put, deleteRequest } from "utils/api";

/**
 * Send SMS
 * @returns {Promise<any>}
 */

const domainUrl = "/admin/sharednumber";

export const getSharedNumberList = async params => {
  const SMSResponse = await get(domainUrl, params);
  return SMSResponse.data.data;
};

export const addSharedNumber = async params => {
  const addSharedNumberResponse = await post(domainUrl, params);
  return addSharedNumberResponse.data.data;
};

export const updateSharedNumber = async params => {
  const updateSharedNumberResponse = await put(`${domainUrl}/${params._id}`, params);
  return updateSharedNumberResponse.data.data;
};

export const deleteSharedNumber = async sharedNumberId => {
  const deleteSharedNumberResponse = await deleteRequest(`${domainUrl}/${sharedNumberId}`);
  return deleteSharedNumberResponse.data.data;
};

export const getSharedNumberCountByPool = async pool => {
  const sharedNumberCountByPoolResponse = await get(`/sharednum/count/${pool}`);
  console.log(sharedNumberCountByPoolResponse.data.data);
  return sharedNumberCountByPoolResponse.data.data;
};
