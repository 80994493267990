import React from "react";
import { Card, Typography } from "antd";
import AppButton from "components/AppButton/AppButton";
import clsx from "clsx";

const ProductCard = props => {
  const { fontColor, loading = false, bestseller = false, data, handleBuyNow, isMms = false, isFirstBuy = false } = props;

  let Rate = 0;
  let numberOfCredits = 0;
  let centsPerCredit = 0;

  if (data) {
    Rate = data.price;
    numberOfCredits = data.isMMS == "true" ? data.mms : data.sms;
    centsPerCredit = data.isMMS == "true" ? data.centsPerMMS : data.centsPerSMS;
  }

  if (isFirstBuy) {
    numberOfCredits = (Rate / (data.isMMS == "true" ? 29 : 2.8)).toFixed(0);
    centsPerCredit = data.isMMS == "true" ? 29 : 2.8;
  }

  return (
    <Card
      loading={loading}
      className="text-center mx-auto bg-transparent mb-5 shadow"
      style={{
        borderRadius: "20px",
        maxWidth: "150px"
      }}
      bordered={false}
      title={
        <>
          {bestseller == "true" && (
            <Typography.Text
              style={{
                position: "absolute",
                top: 0,
                fontSize: "x-small",
                right: 20,
                color: "#fff"
              }}
            >
              Best Seller
            </Typography.Text>
          )}
          <Typography.Title level={3} className="m-0 text-white">
            {data.name}
          </Typography.Title>
          {data?.bonus && (
            <Typography.Text
              style={{
                fontSize: 10
              }}
              className="bundle-ribbon bg-danger text-white"
            >
              Bonus {data.bonus} {data.isMMS == "true" ? "MMS" : "SMS"}
            </Typography.Text>
          )}
        </>
      }
      headStyle={{
        backgroundImage: "linear-gradient(90deg, #3560E3 0%, #427CF9 100%)",
        borderRadius: "20px 20px 0 0",
        position: "relative",
        padding: 0,
        border: "2px white solid"
      }}
      bodyStyle={{
        backgroundColor: "#F4F8FF",
        borderRadius: "0 0 20px 20px",
        border: "2px white solid",
        padding: 0
      }}
    >
      <div className="d-flex flex-column align-items-center position-relative pb-4 pt-2">
        <Typography.Text style={fontColor} strong className={clsx("fs-5 lato-bold")}>
          {numberOfCredits} {data.isMMS == "true" ? "MMS" : "SMS"}
        </Typography.Text>
        <Typography.Text style={fontColor} strong className={clsx("fs-5 lato-bold")}>
          {centsPerCredit}c each
        </Typography.Text>
        <AppButton
          label={"Buy Now"}
          className={"bg-dark-green fs-5 px-3 py-1 fw-bold"}
          style={{
            position: "absolute",
            transform: "translate(0px, 60%)",
            bottom: 0
          }}
          onClick={() =>
            handleBuyNow(
              isMms
                ? {
                    id: data.id,
                    priceWithTax: data.price * 1.1,
                    price: data.price,
                    centsPerMMS: centsPerCredit,
                    mms: numberOfCredits,
                    isBundle: true,
                    isFirstBuy: isFirstBuy,
                    bonus: data?.bonus
                  }
                : {
                    id: data.id,
                    priceWithTax: data.price * 1.1,
                    price: data.price,
                    centsPerSMS: centsPerCredit,
                    sms: numberOfCredits,
                    isBundle: true,
                    isFirstBuy: isFirstBuy,
                    bonus: data?.bonus
                  }
            )
          }
        />
      </div>
    </Card>
  );
};

export default ProductCard;
