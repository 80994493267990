import React from "react";
import Offline from "pages/Other/Offline";

//import styles
import "./app-style.css";
import "./App.scss";
import "./global.css";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

//import comps
import RootStore from "store";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import Socket from "utils/socket";
import { loadState } from "store/initial";
import UnauthorizedPages from "routes/Unauthorized";
import AuthorizedPages from "routes/Authorized";
import useInternetStatus from "hooks/useInternetStatus";

//load mobx store
const state = new RootStore();
loadState(state);
//load socket.io
new Socket({ state });

function App() {
  const { token: isTokenAvailable } = state.authStore;
  const internetStatus = useInternetStatus();

  if (!isTokenAvailable) {
    localStorage.setItem("last-path", window.location.pathname + window.location.search);
  }

  if (internetStatus) {
    return (
      <Provider store={state}>
        <BrowserRouter>{!isTokenAvailable ? <UnauthorizedPages /> : <AuthorizedPages />}</BrowserRouter>
      </Provider>
    );
  } else {
    return <Offline />;
  }
}

export default App;
