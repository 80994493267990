import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { authorizedRoutes, authorizedMobileRoutes } from "./mapping";
import DataWrapper from "components/AppLayout/DataWrapper";
import AppLayout from "components/AppLayout/AppLayout";
import loading from "assets/images/infinite-blue.svg";
import styles from "components/AppLayout/applayout.module.css";
import QuickSmsForm from "components/QuickSms/QuickSmsForm";
import QuickSmsWrapper from "components/QuickSms/QuickSmsWrapper";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import TabsMenu from "Mobile/Pages/TabsMenu";
import { addLiveChatScript, removeGoogleTagManagerScript, removeHubspotScript, removeLinkedInScript, removeLiveChatScript, removeMouseflowScript } from "scripts";
import { Helmet } from "react-helmet";
// import SaleCountDown from "components/BuyCredits/SaleCountDown";

function getWindowWidth() {
  const { innerWidth: width } = window;
  return {
    width
  };
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowWidth;
}

function Authorized({ modalStore }) {
  const { width } = useWindowWidth();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [spanNo, setSpanNo] = useState(24);

  useEffect(() => {
    if (modalStore.quickMessage && width > 1200) {
      setSpanNo(17);
    } else if (modalStore.quickMessage && width > 992 && width < 1200) {
      setSpanNo(14);
    } else {
      setSpanNo(24);
    }
  }, [modalStore.quickMessage, width]);

  useEffect(() => {
    if (width < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [width]);

  return (
    <>
      {isSmallScreen ? (
        <Suspense
          fallback={
            <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
              <img src={loading} alt="loading" width="100px" />
            </div>
          }
        >
          <Switch>
            {authorizedMobileRoutes.map(i => (
              <Route
                key={i.path}
                exact={i.exact}
                path={i.path}
                render={props => {
                  removeMouseflowScript();
                  removeLiveChatScript();
                  removeHubspotScript();
                  removeGoogleTagManagerScript();
                  removeLinkedInScript();
                  !i?.stopPreview ? (document.title = `${i.title ? `${i.title} | ` : ""}Guni Online and Bulk SMS Gateway`) : null;
                  return (
                    <DataWrapper>
                      {i?.stopPreview && (
                        <Helmet>
                          <title> </title>
                          <meta property="og:url" content="https://app.gunisms.com.au/nonexistent-url" />
                        </Helmet>
                      )}
                      {/* <SaleCountDown /> */}
                      {i.NavBar && <i.NavBar />}
                      <TabsMenu>
                        <i.Component {...props} />
                      </TabsMenu>
                    </DataWrapper>
                  );
                }}
              />
            ))}
          </Switch>
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <div className="d-flex">
              <div className={`${localStorage.getItem("isSidebarOpen") === "false" ? "" : styles.active} ${styles.sidebar}`}>
                <div className={styles.logo}>
                  <div className={`rounded-pill ${styles.text} ${localStorage.getItem("isSidebarOpen") === "false" ? "" : styles.big}`} />
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            {authorizedRoutes.map(i => (
              <Route
                key={i.path}
                exact={i.exact}
                path={i.path}
                render={props => {
                  document.title = `${i.title ? `${i.title} | ` : ""}Guni Online and Bulk SMS Gateway`;
                  addLiveChatScript();
                  removeHubspotScript();
                  removeMouseflowScript();
                  removeGoogleTagManagerScript();
                  removeLinkedInScript();
                  if (i?.removeSideBar) return <i.Component {...props} {...i.customProps} width={width} />;
                  // i?.stopTrackingScripts ? null : addHubspotScript();
                  !i?.stopPreview ? (document.title = `${i.title ? `${i.title} | ` : ""}Guni Online and Bulk SMS Gateway`) : null;
                  return (
                    <DataWrapper>
                      {i?.stopPreview && (
                        <Helmet>
                          <title> </title>
                          <meta property="og:url" content="https://app.gunisms.com.au/nonexistent-url" />
                        </Helmet>
                      )}
                      <AppLayout retailChatSideBar={i?.retailChatSideBar}>
                        <div id="page-content-wrapper">
                          {i.NavBar && <i.NavBar />}
                          <Row gutter={20} className="px-3">
                            <Col span={24} lg={spanNo} xl={spanNo}>
                              <i.Component {...props} {...i.customProps} width={width} />
                            </Col>
                            <QuickSmsWrapper>
                              <QuickSmsForm />
                            </QuickSmsWrapper>
                          </Row>
                        </div>
                      </AppLayout>
                    </DataWrapper>
                  );
                }}
              />
            ))}
          </Switch>
        </Suspense>
      )}
    </>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(Authorized));
