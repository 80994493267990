import React from "react";
import { inject, observer } from "mobx-react";
import { Modal, Row } from "antd";
import { langRegEx } from "constant/messageConstants";
import AppButton from "../../AppButton/AppButton";
import styles from "assets/css/modals.module.css";

const UnicodeRemove = props => {
  const { modalStore, message, onChange, header, preview } = props;

  function isGSMAlphabet(text) {
    return langRegEx.test(text);
  }

  const handleRemoveUniCode = () => {
    onChange(
      message
        .split("")
        .map(i => (isGSMAlphabet(i) ? i : ""))
        .join("")
    );
    modalStore.toggleModal("showUnicodeRemoveModal", false);
  };

  return (
    <Modal
      style={{ borderRadius: "20px" }}
      title={<div className="ps-2 pt-2 fw-bolder">{header || "Remove Unicode"}</div>}
      className="uniModal"
      closable={true}
      visible={modalStore.showUnicodeRemoveModal}
      onCancel={() => {
        modalStore.toggleModal("showUnicodeRemoveModal", false);
      }}
      width={800}
      footer={null}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      zIndex={1051}
      centered
    >
      <div className="px-2 unicodeRemove">
        <div className="row justify-content-center">
          <div className="text-start bg-light bg-gradient rounded p-3">
            <p className={`${styles.removeUnicodePreview} m-0`}>
              {message?.split("").map((i, k) =>
                isGSMAlphabet(i) ? (
                  i
                ) : (
                  <span
                    style={{
                      textDecoration: "line-through 100% #dc354570"
                    }}
                    className="text-dark"
                    key={k}
                  >
                    {i}
                  </span>
                )
              )}
            </p>
          </div>
          {!preview && (
            <Row type="flex" justify="center" className="flex-row">
              <AppButton
                label="Cancel"
                className="mt-2 me-2"
                light
                withoutBg
                onClick={() => {
                  modalStore.toggleModal("showUnicodeRemoveModal", false);
                }}
              />
              <AppButton label="Remove Unicode" className="mt-2" light onClick={handleRemoveUniCode} />
            </Row>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(UnicodeRemove));
