import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Tooltip } from "antd";
import { checkIsEnglish, compileMessageForLength, caculateMessageLengthWithLineBreaks, checkTotalMessageCount } from "common/utils/messageUtilities";
import AppButton from "components/AppButton/AppButton";
import SenderMenu from "components/GatewayMessage/MessageBox/SenderMenu";
import MessageContainer from "components/GatewayMessage/MessageContainer";
import { getFormatedContacts, checkSender, validAusNumberRegExpDeluxe, contactFieldSeperatorRegEx, getFormatedContact, messageCounts } from "constant/messageConstants";
import { sharedNumber } from "constant/constant";
import AlertModal from "components/Modals/AlertModal";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import MessageType from "components/GatewayMessage/MessageType";
import SaveQuickSmsForm from "./SaveQuickSmsForm";
import moment from "moment";
import ContactSelector from "components/GatewayMessage/ContactSelector/ContactSelector";
import FormSelectInput from "components/FormItems/FormSelectInput";
import { isEmpty, uniqBy } from "lodash";
import View from "components/TargetGroup/View";
import Groups from "components/GatewayMessage/Modals/Groups";

const validationSchema = Yup.object().shape({
  sender: Yup.string().required("Please choose a sender"),
  contacts: Yup.array()
    .when(["groupObj"], {
      is: _ => isEmpty(_),
      then: Yup.array()
        .of(
          Yup.string()
            .matches(validAusNumberRegExpDeluxe, "Invalid phone number")
            .nullable(true)
        )
        .min(1, "Please add atleast one number")
        .max(20, "Maximum 20 numbers allowed")
        .nullable(true)
    })
    .nullable(true),
  message: Yup.string().required("Message is required!"),
  // scheduleAt: Yup.date()
  //   .when("sendType", {
  //     is: "schedule",
  //     then: Yup.date()
  //       .required("Please select a schedule time")
  //       .min(
  //         moment().add(5, "minutes"),
  //         "Time must be at least 5 minutes from now"
  //       )
  //   })
  //   .nullable()
  scheduleAt: Yup.string()
    .when("sendType", {
      is: "schedule",
      then: Yup.string()
        .required("Please select a schedule time")
        .test("is-valid-time", "Time must be at least 5 minutes from now", function(value) {
          return moment(value).diff(moment(), "seconds") >= 270; // 4:30 minutes
        })
    })
    .nullable()
});

const QuickSmsForm = props => {
  const { smallSize = false, modalStore, gatewayMmsStore, gatewayStore, authStore, messageStore, contactStore } = props;
  const { user, balanceObj, dedicatedNumList } = authStore;

  const formikRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const [search, setSearch] = useState(false);
  const [onSearch, setOnSearch] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showMoreGroup, setShowMoreGroup] = useState(false);
  const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
  const [quickApprovalAlert, setQuickApprovalAlert] = useState(false);

  const getSender = () => {
    return modalStore.quickSMSSender
      ? checkSender(modalStore.quickSMSSender, user, messageStore)
      : modalStore.isMms
      ? dedicatedNumList[modalStore.isMms ? "mms" : "sms"].includes(user?.defaultSender)
        ? user?.defaultSender
        : sharedNumber
      : // : allSenders.find(item => item.value === user?.defaultSender)
      [...dedicatedNumList[modalStore.isMms ? "mms" : "sms"], ...dedicatedNumList?.senders, ...dedicatedNumList?.mobile].includes(user?.defaultSender)
      ? user?.defaultSender
      : sharedNumber;
  };

  useEffect(() => {
    if (modalStore.isMms) {
      if (dedicatedNumList[modalStore.isMms ? "mms" : "sms"].includes(formikRef.current?.values?.sender)) {
        formikRef.current.setFieldValue("sender", formikRef.current?.values?.sender);
      } else {
        formikRef.current.setFieldValue("sender", sharedNumber);
      }
      if (messageStore.phoneFrameImageUrl) {
        formikRef.current.setFieldValue("media", messageStore.phoneFrameImageUrl ? messageStore.phoneFrameImageUrl : formikRef.current?.values?.media);
      }
    } else {
      if (
        // allSenders.find(
        //   item => item.value === formikRef.current?.values?.sender
        // )
        [...dedicatedNumList[modalStore.isMms ? "mms" : "sms"], ...dedicatedNumList?.senders, ...dedicatedNumList?.mobile].includes(formikRef.current?.values?.sender)
      ) {
        formikRef.current.setFieldValue("sender", formikRef.current?.values?.sender);
      } else {
        formikRef.current.setFieldValue("sender", sharedNumber);
      }
    }
  }, [modalStore.isMms]);

  useEffect(() => {
    if (user) {
      if (modalStore.isMms) {
        if (user.firstQuickMMS && user.mobile) {
          const mobile = getFormatedContacts([user?.mobile]);
          formikRef.current?.setFieldValue("contacts", mobile);
          formikRef.current?.setFieldValue("numberList", [{ label: mobile?.[0], value: mobile?.[0] }]);
          contactStore.setSearchContacts([{ label: mobile?.[0], value: mobile?.[0] }], true);
        }
      } else {
        if (user.firstQuickSMS && user.mobile) {
          const mobile = getFormatedContacts([user?.mobile]);
          formikRef.current?.setFieldValue("contacts", mobile);
          formikRef.current?.setFieldValue("numberList", [{ label: mobile?.[0], value: mobile?.[0] }]);
          contactStore.setSearchContacts([{ label: mobile?.[0], value: mobile?.[0] }], true);
        }
      }
    }
  }, [user, modalStore.isMms]);

  useEffect(() => {
    formikRef.current.setFieldValue("message", modalStore.quickSMSContent ? modalStore.quickSMSContent : formikRef.current?.values?.message);
    if (modalStore.isMms) {
      formikRef.current.setFieldValue(
        "contacts",
        modalStore.quickSMSContact
          ? getFormatedContacts([modalStore.quickSMSContact])
          : user && user.firstQuickMMS && user.mobile
          ? [...getFormatedContacts([user?.mobile]), ...formikRef.current?.values?.contacts].filter((item, index, self) => self.indexOf(item) === index)
          : formikRef.current?.values?.contacts
      );
    } else {
      formikRef.current.setFieldValue(
        "contacts",
        modalStore.quickSMSContact
          ? getFormatedContacts([modalStore.quickSMSContact])
          : user && user.firstQuickSMS && user.mobile
          ? [...getFormatedContacts([user?.mobile]), ...formikRef.current?.values?.contacts].filter((item, index, self) => self.indexOf(item) === index)
          : formikRef.current?.values?.contacts
      );
    }
    formikRef.current.setFieldValue("sender", getSender());
    messageStore.setPhoneSender(getSender());
    formikRef.current.setFieldValue("subject", modalStore.subject ? modalStore.subject : formikRef.current?.values?.subject);
    formikRef.current.setFieldValue("longUrl", modalStore.longUrl ? modalStore.longUrl : formikRef.current?.values?.longUrl);
    if (formikRef.current?.resetForm) modalStore.setReset(formikRef.current?.resetForm);
  }, [
    // modalStore.quickSMSContent, // creating issue, reinitialize values on typing msg
    // modalStore.longUrl,
    // modalStore.quickSMSContact,
    modalStore.shortUrl,
    modalStore.quickSMSSender,
    modalStore.isMms
  ]);

  function sendGatewayMessage(values) {
    let data = {
      ...values
    };
    let formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (!["contacts"].includes(key)) formData.append(key, value);
    });
    formData.append("contacts", JSON.stringify(values.contacts));
    if (values?.numberList) formData.set("numberList", JSON.stringify(values.numberList));
    formData.append("bucket", "general");
    formData.append("category", "group");
    if (modalStore.isMms) {
      return gatewayMmsStore.addGatewayMMS(formData);
    } else {
      return gatewayStore.addGatewaySMS(data);
    }
  }

  function redirect(campaign = false) {
    modalStore.toggleModal("quickMessage", false);
    history.push({
      pathname: campaign ? "/campaign" : "/history",
      state: { messageType: modalStore.isMms ? "mms" : "sms" }
    });
  }

  const getValidNumber = e => {
    return [
      ...new Set(
        e.flatMap(input =>
          !contactFieldSeperatorRegEx.test(input)
            ? input.match(validAusNumberRegExpDeluxe)
              ? [getFormatedContact(input)]
              : input
                  .split(" ")
                  .filter(subInput => subInput.match(validAusNumberRegExpDeluxe))
                  .map(getFormatedContact)
            : input.split(contactFieldSeperatorRegEx).flatMap(subInput =>
                subInput.trim().match(validAusNumberRegExpDeluxe)
                  ? getFormatedContact(subInput.trim())
                  : subInput
                      .split(" ")
                      .filter(_ => _.match(validAusNumberRegExpDeluxe))
                      .map(getFormatedContact)
              )
        )
      )
    ];
  };

  const initialValues = {
    contacts: [],
    searchedContacts: [],
    sender: getSender(),
    message: "",
    replyLink: false,
    longUrl: undefined,
    subject: undefined,
    // helper fields
    numberList: [],
    contactData: [],
    groupObj: []
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          let newData = { ...values };
          newData.contacts = newData.contacts.filter(x => x);
          newData.contacts = [...new Set(newData.contacts)];
          if (values?.numberList) newData.numberList = uniqBy([...values.numberList, ...values.contacts.map(i => ({ label: i, value: i, number: i }))], "value");
          Object.keys(newData).forEach(key => newData[key] === undefined && delete newData[key]);
          setSubmitting(true);
          let tempMessage = compileMessageForLength({
            message: newData?.message,
            replyLink: newData?.replyLink
          });
          let totalCharacterCount = caculateMessageLengthWithLineBreaks(tempMessage);
          let totalMessagesCount = checkTotalMessageCount(totalCharacterCount, checkIsEnglish(newData?.message));

          // handling groups
          if (isEmpty(newData?.groupObj) || modalStore.isMms) {
            delete newData?.group;
            delete newData?.groupObj;
            delete newData.name;
            delete newData.group_array;
            delete newData.totalContacts;
            delete newData.unsubscribe;
            delete newData.group;
          } else if (!user?.quickApproved) {
            newData.name = `Quick Campaign ${moment().format("DD/MM HH:mm")}`;
            newData.group_array = newData.group;
            newData.group = null;
            newData.campaign_type = "notification";
            newData.optout = false;
            newData.replyStopToOptOut = false;

            delete newData?.contacts;
            delete newData?.contactData;
            delete newData?.searchedContacts;
            delete newData?.numberList;
            delete newData?.senders;
            delete newData?.groupObj;
          }

          const totalContacts = newData?.group_array ? newData?.totalContacts : newData?.contacts?.length;

          if (modalStore.isMms) {
            if (totalContacts > balanceObj.maxPossibleCredits.mms) {
              setLowBalanceAlert(true);
              setSubmitting(false);
              return;
            }
          } else {
            if (totalMessagesCount * totalContacts > balanceObj.maxPossibleCredits.sms) {
              setLowBalanceAlert(true);
              setSubmitting(false);
              return;
            }
          }
          let media = newData.media ? true : false;
          let url = newData.url ? true : false;
          if (modalStore.isMms && !(media ^ url)) {
            modalStore.toggleModal("showAttachFileModal", true);
            setSubmitting(false);
            return;
          }
          sendGatewayMessage(newData)
            .then(res => {
              if (res.status) {
                resetForm(initialValues);
                contactStore.setSearchContacts();
                messageStore.setPhoneSubject(undefined);
                messageStore.setCurrentImage(undefined);
                modalStore.setQuickSMSData(undefined);
                // modalStore.toggleModal("quickMessage", false);
                if (res.message.includes("Waiting for admin approval")) {
                  setSuccessMessage("Your quick message is waiting for admin approval");
                  setQuickApprovalAlert(true);
                } else {
                  redirect(!!newData?.group_array);
                }
              }
            })
            .finally(() => setSubmitting(false));
        }}
        innerRef={formikRef}
      >
        {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <SaveQuickSmsForm />
              <Row type="flex" justify="start" gutter={[10, 10]}>
                <Col span={24}>
                  <MessageType label={"Message Type"} />
                </Col>
                {isEmpty(values.groupObj) && (
                  <>
                    <Col span={24} className="vh-center gap-3 align-items-end">
                      <FormSelectInput
                        size="default"
                        label={<span className="fw-bold">To</span>}
                        containerProps={{ colon: false }}
                        name="contacts"
                        mode="tags"
                        placeholder="Enter mobile numbers"
                        error={errors.contacts}
                        touched={touched.contacts}
                        showSearch
                        value={values.contacts ? values.contacts : undefined}
                        options={values.contacts.map(i => ({
                          label: i,
                          value: i
                        }))}
                        onChange={e => {
                          const output = getValidNumber(e);
                          setFieldValue("contacts", output);
                        }}
                        onDeselect={e => {
                          setFieldValue(
                            "numberList",
                            values.numberList.filter(x => x.value !== e)
                          );
                          if (values.numberList.length === 0) setOnSearch();
                        }}
                        secondary
                        selectProps={{
                          onSearch: e => setOnSearch(e),
                          menuItemSelectedIcon: <i className="bx bx-x d-none" />,
                          dropdownClassName: "d-none"
                        }}
                      />
                      <div role="button" onClick={() => setSearch(true)}>
                        <Tooltip title="Search Contact  ">
                          <i className="bx bx-search-alt fs-3" />
                        </Tooltip>
                      </div>
                    </Col>
                    <Col span={24} className="text-muted py-1" style={{ fontSize: "x-small" }}>
                      <i className="bx bx-info-circle align-middle me-1" />
                      You can paste or enter contacts with separator comma, semi-colon or space.
                    </Col>
                  </>
                )}
                {!modalStore.isMms && isEmpty(values.contacts) && isEmpty(onSearch) && !user?.quickApproved && (
                  <Col span={24} className="mt-2">
                    {!isEmpty(values.groupObj) && (
                      <label className="ant-form-item-no-colon mb-3" title="">
                        <span className="fw-bold">To</span>
                      </label>
                    )}
                    <AppButton prefixIcon={<PlusOutlined />} label={"Select Group"} className={"vh-center gap-2"} withoutBg size="small" onClick={() => setGroupModal(true)} />
                    {values?.groupObj && (
                      <>
                        <div className={`my-2 text-info-color px-2 d-flex flex-column gap-2`}>
                          {values?.groupObj &&
                            values?.groupObj?.map((_, i) => {
                              if (i < 3 || showMoreGroup) {
                                return (
                                  <div className="text-truncate d-flex align-items-end">
                                    <span className="text-truncate">{_?.name}</span>
                                    <span className="badge-soft-info ms-1">{_?.totalContacts}</span>
                                    {values.groupObj.length === i + 1 ? "" : ", "}
                                  </div>
                                );
                              }
                            })}
                        </div>
                        {values?.groupObj.length > 3 && (
                          <div className="" onClick={() => setShowMoreGroup(!showMoreGroup)}>
                            <span role="button">
                              show {values?.groupObj.length - 3} {showMoreGroup ? "less" : "more"}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                    <Groups
                      group={values.group}
                      selectType="checkbox"
                      isVisible={groupModal}
                      handleCancel={() => setGroupModal(false)}
                      handleGroupSelect={group => {
                        const { unsubscribe, total } = messageCounts(group.groupObj);
                        setFieldValue("group", group.group);
                        setFieldValue("groupObj", group.groupObj);
                        setFieldValue("unsubscribe", unsubscribe);
                        setFieldValue("totalContacts", total);

                        setGroupModal(false);
                      }}
                    />
                  </Col>
                )}
                {user && (
                  <Col span={24}>
                    <SenderMenu isMms={modalStore.isMms} setFieldValue={setFieldValue} errors={errors} touched={touched} values={values} isQuick={true} size="default" />
                  </Col>
                )}
                <MessageContainer
                  size="small"
                  formikRef={formikRef}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                  name={"message"}
                  quickTestSMS
                  quickMessage={isEmpty(values?.groupObj)}
                  optoutCheckBox={false}
                  testSMS={!user?.quickApproved}
                  isMms={modalStore.isMms}
                  smallSize={smallSize}
                  minRows={3}
                  scheduler={isEmpty(values?.groupObj) && !user?.quickApproved}
                  handleSubmit={handleSubmit}
                  endProp={
                    <AppButton
                      light
                      type="submit"
                      className="rounded-xl"
                      label={
                        submitting ? (
                          <>
                            <LoadingOutlined /> Sending...
                          </>
                        ) : (
                          "Send"
                        )
                      }
                      disabled={submitting}
                      size="default"
                    />
                  }
                />
              </Row>
              <AlertModal
                visible={search}
                title={null}
                description=""
                btn={false}
                closable
                onCancel={() => setSearch(false)}
                withoutHeader
                endPropContainerClass="w-100 mt-0 flex-column align-items-center p-3"
                endComponent={
                  <>
                    <p className="text-muted mt-3">
                      <i className="bx bx-search-alt align-middle fs-3 me-2"></i>
                      Search contacts here by name or number
                    </p>
                    {search && (
                      <ContactSelector
                        label={null}
                        containerClassname="m-0 w-100"
                        placeholder="Search"
                        containerProps={{ colon: false }}
                        name="searchedContacts"
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        filterContactBy="contacts"
                        contactDataField="contactData"
                        onChange={e => {
                          if (!values.searchedContacts.includes(getFormatedContacts(e)[e.length - 1])) setFieldValue("searchedContacts", getFormatedContacts(e));
                        }}
                        onDeselect={e => {
                          if (values.searchedContacts.includes(e))
                            setFieldValue(
                              "searchedContacts",
                              values.searchedContacts.filter(i => i !== e)
                            );
                        }}
                      />
                    )}
                    <AppButton
                      label="Add"
                      className="mt-4"
                      prefixIcon={<i className="bx bx-plus align-middle me-2" />}
                      onClick={() => {
                        setFieldValue("numberList", [...values?.numberList, ...values.contactData]);
                        setFieldValue("contacts", [...new Set([...values.contacts, ...values.searchedContacts])]);
                        setFieldValue("searchedContacts", []);
                        setFieldValue("contactData", []);
                        setSearch(false);
                      }}
                    />
                  </>
                }
              />
            </Form>
          );
        }}
      </Formik>
      <AlertModal
        visible={lowBalanceAlert}
        title="Low Balance"
        description="You don't have enough credits to send this message. Please add credits to continue."
        onCancel={() => setLowBalanceAlert(false)}
        primaryBtnProps={{
          label: "Add Credits",
          onClick: () => {
            setLowBalanceAlert(false);
            modalStore.toggleModal("showQuickSMSModal", false);
            history.push("/buy-credits", {
              from: location.pathname
            });
          }
        }}
        secondaryBtnProps={{
          label: "Cancel",
          onClick: () => setLowBalanceAlert(false)
        }}
        secondaryBtn
        closable
        error
      />
      <AlertModal
        visible={quickApprovalAlert}
        title={<span className="px-3">{successMessage}</span>}
        description=""
        onCancel={() => setQuickApprovalAlert(false)}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setQuickApprovalAlert(false);
            redirect();
          }
        }}
        closable
      />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  gatewayStore: stores.store.gatewayStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  authStore: stores.store.authStore,
  messageStore: stores.store.messageStore,
  contactStore: stores.store.contactStore
}))(observer(QuickSmsForm));
