import * as Yup from "yup";
import { Formik, Form } from "formik";
import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { Typography, Form as AntForm, Col, Row } from "antd";
import { contactMaskRegExp, resendCounterTime } from "constant/messageConstants";
import { AutoSubmit, OtpInputComponent } from "components/Authentication/Common/OtpInput";
import { useHistory } from "react-router-dom";

const EmailVerifyForm = props => {
  const { authStore, modalStore } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [resendAlert, setResendAlert] = useState(false);
  const [counter, setCounter] = useState(resendCounterTime);

  const getMaskedContact = () => {
    if (!authStore?.user?.email) return "";
    const contact = authStore?.user?.email;
    return authStore?.user?.isEmailVerified ? contact.replace(contactMaskRegExp, "$1XXXXX$2") : contact;
  };

  const startCounter = () => {
    if (counter > 0) setCounter(counter - 1);
  };

  useEffect(() => {
    setResendAlert(true);
    startCounter();
  }, []);

  useEffect(() => {
    if (counter < resendCounterTime && counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0) {
      setCounter(resendCounterTime);
      setResendAlert(false);
    }
  }, [counter]);

  useEffect(() => {
    if (authStore?.user) {
      setLoading(true);
      authStore.resendSignUpMail({ email: authStore?.user?.email }).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={Yup.object().shape({
          otp: Yup.string()
            .max(6, "Enter Valid OTP")
            .min(6, "Enter Valid OTP")
            .required("Enter OTP")
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setError();
          await authStore
            .verifySignUpMail({
              email: authStore?.user?.email,
              code: values.otp
            })
            .then(res => {
              if (!res.data?.message?.includes("otp verification failed")) {
                localStorage.removeItem("additional-info");
                localStorage.removeItem("contact");
                modalStore.toggleModal("showUpgrade", false);
                modalStore.toggleModal("verifyEmailOtp", false);

                setTimeout(() => (window.location.href = "/create-user-verification"), 2000);
              } else {
                setError("OTP verification failed !");
                resetForm();
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, resetForm }) => {
          return (
            <Form onSubmit={handleSubmit} className="my-3 mx-2">
              <Typography.Text className="text-muted" style={{ fontSize: "small" }}>
                We just sent you an OTP on <span className="text-info">{getMaskedContact()}</span> to secure your account.
              </Typography.Text>
              <AutoSubmit />
              <AntForm.Item validateStatus={errors.otp && touched.otp ? "error" : ""} colon={false} help={touched.otp && errors.otp ? errors.otp : ""} className="mb-4 mt-3">
                <OtpInputComponent value={values.otp} onChange={otp => setFieldValue("otp", otp)} isDisabled={isSubmitting} />
              </AntForm.Item>
              {error && <div className="text-center text-danger w-100">{error}</div>}
              <Row className=" mb-4 " type="flex" align="middle">
                <Col span={24} className="text-center">
                  {resendAlert ? (
                    <span className="text-info-color pe-auto fw-bold">
                      OTP code expires in{" "}
                      <button
                        role="none"
                        className="rounded ms-1 text-center border-0 text-info-color"
                        style={{
                          background: "#149cbe40",
                          fontSize: "13px",
                          width: "25px",
                          height: "25px"
                        }}
                      >
                        {counter}
                      </button>
                    </span>
                  ) : (
                    <span
                      className="text-info-color pe-auto text-decoration-underline"
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        setLoading(true);
                        authStore.resendSignUpMail({ email: authStore?.user?.email }).then(res => {
                          if (res.status) {
                            resetForm();
                            setResendAlert(true);
                            startCounter();
                          }
                        });
                      }}
                    >
                      {" "}
                      Resend OTP
                    </span>
                  )}
                </Col>
              </Row>
              <Typography.Text style={{ fontSize: "small" }}>Please do not close or refresh this page while we confirm.</Typography.Text>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(EmailVerifyForm));
