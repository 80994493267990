import { Radio, Row, Col, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { messageTypes } from "constant/commonList";

const MessageType = ({ modalStore, label = "Medium", authStore, hideMms = false }) => {
  const { isMms, setIsMms } = modalStore;
  const { user } = authStore;

  const [radioBtns, setRadioBtns] = useState([]);
  const [radioValue, setRadioValue] = useState("sms");

  useEffect(() => {
    if (user?.role === "subaccount" && user?.access.find(access => access === "sms") && user?.access.find(access => access === "mms")) {
      if (!!hideMms) {
        setRadioBtns([
          {
            value: "sms",
            label: "SMS"
          }
        ]);
      } else {
        setRadioBtns(messageTypes);
      }
    } else if (user?.role === "subaccount" && user?.access.find(access => access === "sms")) {
      setRadioBtns([
        {
          value: "sms",
          label: "SMS"
        }
      ]);
      setIsMms(false);
      setRadioValue("sms");
    } else if (user?.role === "subaccount" && user?.access.find(access => access === "mms")) {
      if (!!!hideMms) {
        setRadioBtns([
          {
            value: "mms",
            label: "MMS"
          }
        ]);
        setIsMms(true);
        setRadioValue("mms");
      }
    } else if (user?.role === "subaccount") {
      setRadioBtns([
        {
          value: "sms",
          label: "SMS"
        }
      ]);
      setIsMms(false);
      setRadioValue("sms");
    } else {
      if (!!hideMms) {
        setRadioBtns([
          {
            value: "sms",
            label: "SMS"
          }
        ]);
      } else {
        setRadioBtns(messageTypes);
      }
    }
  }, [user, isMms]);

  useEffect(() => {
    setRadioValue(isMms ? "mms" : "sms");
  }, [isMms, hideMms]);

  function handleChange(event) {
    setIsMms(event.target.value === "mms");
    setRadioValue(event.target.value);
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Typography.Title level={4} className="fs-6 mb-3">
            {label}
          </Typography.Title>
        </Col>
        <Col span={24} className="text-start">
          <Radio.Group onChange={handleChange} value={radioValue}>
            {radioBtns.map((btn, key) => (
              <Radio key={key} value={btn.value}>
                {btn.label}
              </Radio>
            ))}
          </Radio.Group>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default inject(stores => ({
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore
}))(observer(MessageType));
