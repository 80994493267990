import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { decode } from "jsonwebtoken";
import { Col, Modal } from "antd";
import AppleSignin from "react-apple-signin-auth";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google";

import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID } from "constant/apiService";
import { AUTH_TOKEN } from "constant/constant";

import errorIcon from "assets/images/modal/alert.svg";
import facebookIcon from "assets/images/login-signup/facebook.png";
import googleIcon from "assets/images/login-signup/google.png";
import appleIcon from "assets/images/login-signup/apple.png";
import styles from "./auth.module.css";
import { getData } from "constant/browserInfo";
import useWindowWidth from "hooks/useWindowWidth";
import { cookie } from "utils/cookie";

const GoogleButton = ({ text, loginBySocial, ip, allowCustom = false }) => {
  const { width } = useWindowWidth();
  const googleBtnWidth = width < 768 ? "300px" : "420px";

  const getDecodedOAuthJwtGoogle = async token => {
    try {
      const userData = decode(token);
      localStorage.setItem("contact", userData.email);
    } catch (error) {
      console.log(error);
    }
    try {
      const signupdata = {
        token,
        loginMethod: "google"
      };
      await loginBySocial(signupdata);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <GoogleLogin
        onSuccess={credentialResponse => {
          getDecodedOAuthJwtGoogle(credentialResponse.credential);
        }}
        flow="auth-code"
        onError={error => {
          console.log(error);
        }}
        width={googleBtnWidth}
        shape="rectangular"
        type="standard"
        theme="filled_blue"
        text={text.includes("in") ? "signin_with" : text.includes("up") ? "signup_with" : "continue_with"}
      />
    </div>
  );
};

const SocialLogins = ({ authStore, isProfileSigup = false, isSignUp = false, text = "Continue with" }) => {
  const history = useHistory();
  const [ip, setIp] = useState("");
  const [locationInfo, setLocationInfo] = useState();

  const urlparams = new URLSearchParams(useLocation().search);
  const section = urlparams.get("section");
  const page = urlparams.get("page");
  const { width } = useWindowWidth();

  const setData = async () => {
    const data = await getData();
    setLocationInfo(data);
  };
  const appleBtnWidth = width < 768 ? "23.2rem" : "31rem";

  useEffect(() => {
    setData();

    // getData();
  }, []);

  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   setIp(res.data);
  // };

  function setResponse(res) {
    authStore.setData("user", res.response.data);
    authStore.setData("token", res.response.data.token);
    cookie.set(AUTH_TOKEN, res.response.data.token);

    if (res.response.data.message && res.response.data.message.includes("not active")) return;
    if (res.response.data.message && res.response.data.message.includes("number")) {
      localStorage.setItem("contact", res.response.data.mobile);
    } else if (res.response.data.message && res.response.data.message.includes("email")) {
      localStorage.setItem("contact", res.response.data.email);
    }
    if (res.response.data.isEmailVerified && res.response.data.active && isProfileSigup) {
      window.location.href = "/";
    }

    if (!res.response.data.isEmailVerified && !res.response.data.isMobileVerified) {
      localStorage.setItem("contact", res.response.data.email);
      history.push("/signup-verify");
    } else {
      if (res.response.data.isEmailVerified || res.response.data.isMobileVerified) {
        history.push("/verification");
        return;
      }
      // if (!isSignUp && !isProfileSigup) {
      //   localStorage.setItem("contact", res.response.data.email);
      //   history.push("/verification");
      // }
    }
    if (isSignUp && !isProfileSigup) {
      history.push("/signup-verify");
    }
  }

  async function loginBySocial(signupdata) {
    if (isProfileSigup) {
      // signup triggered for completing profile
      const userParams = authStore?.user?._id;
      authStore.updateProfileSocially(signupdata, userParams).then(res => {
        if (res.response.status) {
          setResponse(res);
        }
      });
    } else {
      // normal signup or signin
      if (window.location?.pathname.includes("/signup")) {
        try {
          switch (signupdata?.loginMethod) {
            case "apple":
              const appleData = decode(signupdata?.token);
              authStore.setLoginData({
                loginMethod: "apple",
                contact: appleData.email
              });
              break;

            case "google":
              const googleData = decode(signupdata?.token);
              authStore.setLoginData({
                loginMethod: "google",
                contact: googleData.email,
                firstName: googleData?.name.split(" ")[0] || "",
                lastName: googleData?.name.split(" ")[1] || ""
              });
              break;

            case "facebook":
              axios.post(`https://graph.facebook.com/me?access_token=${signupdata.token}&fields=email,name`).then(res => {
                if (res.status) {
                  authStore.setLoginData({
                    loginMethod: "facebook",
                    contact: res.data.email,
                    firstName: res.data?.name.split(" ")[0] || "",
                    lastName: res.data?.name.split(" ")[1] || ""
                  });
                  history.push(`signup-welcome${page ? `?page=${page}` : ""}${section ? `&section=${section}` : ""}`);
                }
              });
              break;
          }

          if (signupdata?.loginMethod !== "facebook") {
            history.push(`signup-welcome${page ? `?page=${page}` : ""}${section ? `&section=${section}` : ""}`);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        authStore
          .loginBySocial({
            ...signupdata,
            signup: isSignUp,
            ...(locationInfo && { information: locationInfo }),
            ...(page && { page: page }),
            ...(section && { section: section })
          })
          .then(res => {
            if (res.response.status) {
              setResponse(res);
            } else {
              if (!res?.response?.message.includes("not active")) history.push(isSignUp ? "/" : "/signup");
            }
          });
      }
    }
  }

  function countDown() {
    let secondsToGo = 7;
    const modal = Modal.warning({
      title: <span className="fw-bold text-center fs-5">Email is Private</span>,
      content: <span className="text-center">Please try Signup with another method!</span>,
      icon: <img src={errorIcon} style={{ margin: "0 auto 10px", width: "100%", height: "50px" }} />,
      centered: true,
      zIndex: 1003,
      onOk: () => {
        history.push("/signup");
      },
      okButtonProps: { className: "px-4" }
    });
    setTimeout(() => {
      clearInterval();
      modal.destroy();
    }, secondsToGo * 1000);
  }

  const appleSignUp = async response => {
    const json = decode(response.authorization.id_token, { complete: true });
    const isMobile = navigator.userAgentData;
    const information = {
      ip: ip?.IPv4,
      country: ip?.country_name,
      countrycode: ip?.country_code,
      lat: ip?.latitude,
      lng: ip?.longitude,
      platform: isMobile?.platform,
      mobile: isMobile?.mobile,
      browser: isMobile?.brands[2]?.brand
    };
    if (response.user) {
      const signupdata = {
        token: response.authorization.id_token,
        loginMethod: "apple",
        firstName: response.user.name.firstName,
        lastName: response.user.name.lastName
      };
      if (json.payload.is_private_email === "true") {
        countDown();
      } else {
        if (response.user.email) signupdata.email = response.user.email;

        await loginBySocial(signupdata);
      }
    } else {
      const signupdata = {
        token: response.authorization.id_token,
        loginMethod: "apple"
      };
      if (json.payload.is_private_email === "true") {
        countDown();
      } else {
        await loginBySocial(signupdata);
      }
    }
  };

  const responseFacebook = async response => {
    const isMobile = navigator.userAgentData;
    const information = {
      ip: ip?.IPv4,
      country: ip?.country_name,
      countrycode: ip?.country_code,
      lat: ip?.latitude,
      lng: ip?.longitude,
      platform: isMobile?.platform,
      mobile: isMobile?.mobile,
      browser: isMobile?.brands[2]?.brand
    };
    let signupdata;
    if (response.status === "unknown") {
      return false;
    } else if (!response.email) {
      countDown();
    } else {
      signupdata = {
        loginMethod: "facebook",

        token: response.accessToken
      };
      if (response.email) localStorage.setItem("contact", response.email);
      await loginBySocial(signupdata);
    }
  };

  return (
    <>
      <Col span={24} className={`text-start ${styles.loginsocialicon}`}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <button className={`border-0 p-0 bg-transparent `}>
            <GoogleButton text={text} loginBySocial={loginBySocial} ip={ip} />
          </button>
        </GoogleOAuthProvider>
      </Col>
      {/* <Col
        span={12}
        className={`text-center pe-1 pe-md-2 ${styles.loginsocialicon}`}
      >
        <FacebookLogin
          appId={FACEBOOK_CLIENT_ID}
          fields="name,email,picture"
          size="medium"
          cookie="false"
          render={renderProps => (
            <button
              style={{ width: "100%" }}
              className="bg-transparent text-start border border-muted rounded p-1 p-md-2 text-start rounded-3"
              onClick={renderProps.onClick}
            >
              <span>{text} Facebook</span>
              <img
                src={facebookIcon}
                alt="social-icon"
                className="img-fluid mx-2"
              />
            </button>
          )}
          callback={responseFacebook}
        />
      </Col> */}
      <Col span={24} className={`text-center ${styles.loginsocialicon}`}>
        <AppleSignin
          authOptions={{
            clientId: `${APPLE_CLIENT_ID}`,
            scope: "email name",
            redirectURI: `${APPLE_REDIRECT_URI}`,
            state: "origin:web",
            nonce: "nonce",
            usePopup: true
          }}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          buttonExtraChildren="Continue with Apple"
          onSuccess={response => appleSignUp(response)}
          onError={error => console.error(error)}
          skipScript={false}
          render={props => (
            <button {...props} style={{ width: appleBtnWidth }} className="bg-transparent text-start justify-content-between border border-muted rounded p-1 p-md-2 text-start rounded-3">
              <span className="ms-2">{text} Apple</span>
              <img src={appleIcon} alt="social-icon" className="img-fluid mx-2" />
            </button>
          )}
        />
      </Col>

      {/* <Col
        span={24}
        className={`border border-muted rounded text-center py-2 w-100 ${styles.loginsocialicon}`}
        type="button"
      >
        
      </Col> */}
      {/* <Col span={24} className={`text-center w-100`}>
        <div
          style={{ width: "246px" }}
          className={`border border-muted rounded-pill text-center py-2 ${styles.loginsocialicon}`}
        >
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleButton loginBySocial={loginBySocial} ip={ip} allowCustom />
          </GoogleOAuthProvider>
        </div>
      </Col>
      <Col span={24} className={`mb-3`} /> */}
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore
}))(observer(SocialLogins));
