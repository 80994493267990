import React, { useState, useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col, Typography, Form as AntForm, Select } from "antd";
import AppButton from "components/AppButton/AppButton";
import FormInput from "components/FormItems/FormInput";
import { LoadingOutlined } from "@ant-design/icons";

const InvoiceForm = props => {
  const { integrationStore, modalStore } = props;
  const { contact, sendInvoice } = integrationStore;
  const formikRef = useRef();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (modalStore?.retailChatInvoice) {
      setError(false);
      formikRef.current?.resetForm();
    }
  }, [modalStore?.retailChatInvoice]);

  // useEffect(() => {
  //   console.log(contact)
  //   if(contact) {
  //     formikRef.current?.setFieldValue('squareSpaceEmail', contact?.email);
  //     formikRef.current?.setFieldValue('name', contact?.firstName);
  //   }
  // }, [contact])

  const initialValues = {
    squareSpaceEmail: contact?.email || "",
    name: contact?.firstName || "",
    unit_amount: "",
    quantity: "",
    description: ""
  };

  const validationSchema = Yup.object().shape({
    squareSpaceEmail: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    // unit_amount: Yup.number()
    //   .typeError("Should be a number")
    //   .positive()
    //   .min(0, "Should be greater than 0")
    //   .required("Required"),
    unit_amount: Yup.string()
      .matches(/^\d*\.?\d*$/, "Should contain only numbers")
      .matches(/^\d+(\.\d{1,2})?$/, "Two Digit Allowed after decimal")
      .test("is-greater-than-zero", "Amount must be greater than zero", _ => (isNaN(_) ? parseFloat(_) > 0 : true))
      .required("Required"),
    quantity: Yup.string()
      // .typeError("Should be a number")
      // .integer()
      // .positive()
      // .min(0, "Should be greater than 0")
      .matches(/^[1-9]\d*$/, "Decimal not allowed")
      .required("Required"),
    description: Yup.string().required("Required")
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setError(false);
        sendInvoice({
          ...values,
          unit_amount: Number(values.unit_amount) * 100
        })
          .then(res => {
            if (res?.data?.invoice_url) {
              resetForm();
              modalStore.toggleModal("retailChatInvoice", false);
            } else if (res?.message?.includes("Please Provide stripe key")) {
              setError(true);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Row type="flex" justify="start" gutter={[20, 20]}>
            <Col span={24} md={12}>
              <FormInput
                label={<span className="fw-bold">Name</span>}
                containerProps={{ colon: false }}
                placeholder={null}
                value={values.name}
                error={errors.name}
                touched={touched.name}
                containerClassname="mb-0"
                name="name"
                onChange={e => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col span={24} md={12}>
              <FormInput
                label={<span className="fw-bold">Email</span>}
                containerProps={{ colon: false }}
                placeholder={null}
                value={values.squareSpaceEmail}
                error={errors.squareSpaceEmail}
                touched={touched.squareSpaceEmail}
                containerClassname="mb-0"
                name="squareSpaceEmail"
                onChange={e => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                label={<span className="fw-bold">Item Name</span>}
                containerProps={{ colon: false }}
                textarea
                size="small"
                placeholder={null}
                containerClassname="mb-0"
                value={values.description}
                error={errors.description}
                touched={touched.description}
                name="description"
                onChange={e => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col span={24} md={8}>
              <FormInput
                label={<span className="fw-bold">Price per unit ($)</span>}
                containerProps={{ colon: false }}
                placeholder={null}
                value={values.unit_amount}
                error={errors.unit_amount}
                touched={touched.unit_amount}
                containerClassname="mb-0"
                name="unit_amount"
                onChange={e => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col span={24} md={2} className="d-flex align-items-end justify-content-center fw-bold">
              <span className="mb-3">X</span>
            </Col>
            <Col span={24} md={8}>
              <FormInput
                label={<span className="fw-bold">Quantity</span>}
                containerProps={{ colon: false }}
                placeholder={null}
                value={values.quantity}
                error={errors.quantity}
                touched={touched.quantity}
                containerClassname="mb-0"
                name="quantity"
                onChange={e => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col span={24} md={6} className="ant-form-item-label text-center">
              <label className="d-block fw-bold mt-2">Total Price</label>
              <span className="d-block text-center pt-4 mt-1 fw-bold">$ {values.unit_amount && values.quantity ? (Number(values.unit_amount) * Number(values.quantity)).toFixed(2) : 0}</span>
            </Col>
            {error && (
              <Col span={24} className="mb-2 text-center text-danger mt-3">
                Stripe key is required.{" "}
                <span className="text-decoration-underline" role="button" onClick={() => window.open("/integrations", "_blank")}>
                  Click here
                </span>{" "}
                to Add.
              </Col>
            )}
            <Col span={24} className="mt-2">
              <AppButton label={"Submit"} type="submit" light center postfixIcon={isSubmitting && <LoadingOutlined className="align-middle ms-1" />} disabled={isSubmitting} />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default inject(stores => ({
  integrationStore: stores.store.integrationStore,
  modalStore: stores.store.modalStore
}))(observer(InvoiceForm));
