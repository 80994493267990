import React from "react";
import AppButton from "components/AppButton/AppButton";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useWindowWidth from "hooks/useWindowWidth";

const Header = props => {
  const {
    btnLabel = "",
    btnIcon,
    headerName = "",
    btn = false,
    onClick,
    btnProps,
    className,
    path,
    secondaryBtn = false,
    secondaryBtnProps = {
      onClick: () => {},
      label: "",
      prefix: null
    }
  } = props;
  const { width } = useWindowWidth();
  const styleLowWidth = width <= 400 && secondaryBtn;

  return (
    <div className={`d-flex align-items-center justify-content-between ${styleLowWidth ? "flex-wrap" : ""}`}>
      <h5 className={clsx("mb-0 text-dark fw-bold", { "w-50": btn }, { "w-100": !btn }, { "w-100 mb-2": styleLowWidth }, className)}>{headerName}</h5>
      {btn &&
        (path ? (
          <Link to={path}>
            <AppButton light label={btnLabel} prefixIcon={btnIcon} onClick={onClick} {...btnProps} />
          </Link>
        ) : (
          <AppButton light label={btnLabel} prefixIcon={btnIcon} onClick={onClick} {...btnProps} />
        ))}
      {secondaryBtn && <AppButton light {...secondaryBtnProps} />}
    </div>
  );
};

export default Header;
