import React from "react";
import { inject, observer } from "mobx-react";
import AppButton from "components/AppButton/AppButton";
import File from "../Modals/File";

function AttachFile({ modalStore, onSubmit, btnProps = {}, size = "large", values, prefixIcon, label = "Add Attachment", acceptFileType }) {
  function handleClick() {
    modalStore.toggleModal("showAttachFileModal", true);
  }

  return (
    <>
      <AppButton
        withoutBg
        onClick={handleClick}
        className={`border-0 bg-light text-info-color text-decoration-underline vh-center`}
        prefixIcon={prefixIcon ? prefixIcon : <i className="fas fa-paperclip me-2" />}
        label={<span className="d-flex gap-2">{values?.mediaName ? (values.mediaName.length >= 14 ? values.mediaName.substring(0, 13) + "..." : values.mediaName) : label ? label : null}</span>}
        size={size}
        {...btnProps}
      />
      <File handleSubmit={onSubmit} acceptFileType={acceptFileType} />
    </>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore
}))(observer(AttachFile));
