import React, { useState, useEffect } from "react";
import { Modal, Skeleton, Col, Row, Typography } from "antd";
import styles from "assets/css/modals.module.css";

const ViewMedia = props => {
  const { isVisible, handleCancel, fileurl, getUploadedDocument } = props;
  const [loading, setLoading] = useState(true);
  const [filedata, setFiledata] = useState(null);
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    if (fileurl && fileurl?.includes("/mms-temp/")) setFilename(fileurl.split("/mms-temp/")[1]);
    else setFilename(fileurl);
  }, [fileurl]);

  useEffect(() => {
    if (fileurl && fileurl?.includes("/mms-temp/") && filename && isVisible) {
      setLoading(true);
      getUploadedDocument(filename)
        .then(res => {
          if (res.status) {
            setFiledata(res.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setFiledata(fileurl);
    }
    if (!isVisible) setFiledata(null);
  }, [filename, isVisible]);

  if (!fileurl) return null;

  return (
    <>
      <Modal
        destroyOnClose
        centered
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Media Preview</h4>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={isVisible}
        onCancel={handleCancel}
        width={500}
        footer={null}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
        zIndex={1031}
      >
        <Row gutter={[20, 20]} type="flex" justify="center">
          <Col span={20} className="text-center">
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                {fileurl && !fileurl.includes("/mms-temp/") && <img src={fileurl} className="w-auto" />}
                {fileurl && fileurl.includes("/mms-temp/") && (
                  <>
                    {filedata ? (
                      <iframe src={`data:${filedata.contentType};base64,${filedata.fileString}`} height="400" className="w-auto" id="user-doc-view"></iframe>
                    ) : (
                      <Typography.Text className="text-center text-danger-color">No Document Found</Typography.Text>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ViewMedia;
