import React from "react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import { inject, observer } from "mobx-react";
import InvoiceForm from "../Forms/InvoiceForm";

const AddInvitation = props => {
  const { modalStore } = props;

  return (
    <>
      <Modal
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Create Invoice</h4>
            <p className={`mb-0 ${styles.modalDes}`}>Create Your Invoice and Send to your user</p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.retailChatInvoice}
        onCancel={() => modalStore.toggleModal("retailChatInvoice", false)}
        maxWidth={800}
        width={600}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="px-5">
          <InvoiceForm />
        </div>
      </Modal>
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(AddInvitation));
