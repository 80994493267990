import React from "react";
import styles from "./custom-button.module.css";

function CustomButton({ label, onClick, preFixIcon, postFixIcon, className, ...rest }) {
  return (
    <>
      <button className={`${className} ${styles.btn} rounded-pill py-1 px-3`} onClick={onClick} {...rest}>
        {preFixIcon} {label} {postFixIcon}
      </button>
    </>
  );
}

export default CustomButton;
