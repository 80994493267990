import { get, put, deleteRequest } from "utils/api";

/**
 *  Api call for get all mobiles
 * @returns {Promise<any>}
 */
export const getAllMobileList = async () => {
  // save api response for mobile list
  const mobileListResponse = await get(`/phone`);
  return mobileListResponse.data;
};

/**
 * Update existing phone Api
 * @param phoneData
 * @param phoneId
 * @returns {Promise<any>}
 */
export const updatePhone = async (phoneData = {}, phoneId) => {
  const updatePhoneResponse = await put(`/phone/${phoneId}`, phoneData);
  return updatePhoneResponse.data;
};
/**
 * Delete by phone ID
 * @param phoneId
 * @returns {Promise<T>}
 */
export const deletePhone = async phoneId => {
  const deletePhoneResponse = await deleteRequest(`/phone/${phoneId}`);
  return deletePhoneResponse.data;
};
/**
 * Get phone history based on Test Case Id and Serial Number
 * @param testCaseId
 * @param serialNumber
 * @returns {Promise<T>}
 */
export const getPhoneHistory = async (testCaseId, serialNumber) => {
  const getPhoneHistoryResponse = await get(`/phoneHistory?test=${testCaseId}&serialNumber=${serialNumber}`);

  return getPhoneHistoryResponse.data;
};
