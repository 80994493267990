import { get, post, put, deleteRequest } from "utils/api";

export const createOrUpdate = async data => {
  const response = await post("/contactform", data);
  return response.data;
};

export const getList = async () => {
  const response = await get("/contactform");
  return response.data;
};

export const deleteById = async ({ _id }) => {
  const response = await deleteRequest("/contactform", { _id });
  return response.data;
};
