import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Modal, Row, Typography } from "antd";
import styles from "assets/css/modals.module.css";
import confirm from "assets/images/modal/confirm.png";
import AppButton from "components/AppButton/AppButton";
import { useHistory } from "react-router-dom";
import { sharedNumber } from "constant/constant";

const CreateCampaignModal = ({ authStore, modalStore, messageStore }) => {
  const { user, allSenders } = authStore;

  const [draftAlert, setDraftAlert] = useState(false);
  const history = useHistory();

  if (!user) return null;

  useEffect(() => {
    if (modalStore.createCampaignModal) createCampaignAction();
  }, [modalStore.createCampaignModal]);

  const createCampaignAction = (newCamp = false) => {
    if (localStorage.getItem("campaign") && JSON.parse(localStorage.getItem("campaign")).camp_type === (modalStore.isMms ? "mms" : "sms") && !newCamp) {
      setDraftAlert(true);
    } else {
      localStorage.setItem("step", 0);
      localStorage.removeItem("campaign");
      messageStore.setCurrentMessage("");
      messageStore.setPhoneSubject("");
      messageStore.setPhoneSender(
        modalStore.isMms
          ? user?.mobileSenders.includes(user?.defaultSender)
            ? user?.defaultSender
            : sharedNumber
          : allSenders.find(item => item.value === user?.defaultSender)
          ? user?.defaultSender
          : sharedNumber
      );
      modalStore.toggleModal("createCampaignModal", false);
      messageStore.setCurrentImage(null);
      history.push("/campaign/create");
    }
  };

  return (
    <Modal
      visible={draftAlert}
      centered
      title={
        <div className="text-center">
          <img src={confirm} alt="alert" style={{ width: "60px", height: "auto" }} />
        </div>
      }
      zIndex={1005}
      closeIcon={
        <div className={`btn ` + styles.modalCloseBtn}>
          <i className="fas fa-times"></i>
        </div>
      }
      destroyOnClose
      closable
      onCancel={() => {
        modalStore.toggleModal("createCampaignModal", false);
        setDraftAlert(false);
      }}
      width={415}
      footer={null}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      bodyStyle={{
        padding: "0"
      }}
    >
      <Row type="flex" justify="center" className="pb-4">
        <Col span={24} className="text-center">
          <Typography.Text strong className="text-dark fs-5 text-capitalize">
            Use Draft Campaign
          </Typography.Text>
        </Col>
        <Col span={20} className="text-center mb-2">
          <Typography.Text className="m-0">You have a pending campaign stored. Wanna use it ?</Typography.Text>
        </Col>
        <Col span={24} className={`d-flex flex-row justify-content-center gap-3`}>
          <AppButton
            light
            withoutBg
            label={"Create New"}
            onClick={() => {
              createCampaignAction(true);
            }}
          />
          <AppButton
            light
            label={"Use Draft"}
            onClick={() => {
              modalStore.toggleModal("createCampaignModal", false);
              setDraftAlert(false);
              history.push("/campaign/create");
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore,
  authStore: stores.store.authStore
}))(observer(CreateCampaignModal));
