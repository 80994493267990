import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Slider } from "antd";
import AppButton from "components/AppButton/AppButton";
import { ScissorOutlined } from "@ant-design/icons";
import { MAX_FILE_SIZE } from "constant/constant";

let ffmpeg; //Store the ffmpeg instance
const VideoTrimerForm = props => {
  const { gatewayMmsStore, modalStore } = props;
  const { mmsMedia, handleSubmit } = gatewayMmsStore;

  useEffect(() => {
    if (mmsMedia) {
      handleFileUpload(mmsMedia?.media);
    }
  }, [mmsMedia]);

  const [videoDuration, setVideoDuration] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFileValue, setVideoFileValue] = useState("");
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoTrimmedUrl, setVideoTrimmedUrl] = useState("");
  const [videoTrimmedFile, setVideoTrimmedFile] = useState();
  const [msg, setMsg] = useState({ err: "", loading: false });
  const videoRef = useRef();
  let initialSliderValue = 0;

  //Created to load script by passing the required script and append in head tag
  const loadScript = src => {
    return new Promise((onFulfilled, _) => {
      const script = document.createElement("script");
      let loaded;
      script.async = "async";
      script.defer = "defer";
      script.setAttribute("src", src);
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          onFulfilled(script);
        }
        loaded = true;
      };
      script.onerror = function() {
        console.log("Script failed to load");
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  };

  useEffect(() => {
    //Load the ffmpeg script
    loadScript("https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js").then(() => {
      if (typeof window !== "undefined") {
        ffmpeg = window.FFmpeg.createFFmpeg({ log: true });
        ffmpeg.load();
        setIsScriptLoaded(true);
      }
    });
  }, []);

  //Handle Upload of the video
  const handleFileUpload = event => {
    const blobURL = URL.createObjectURL(event);
    setVideoFileValue(event);
    setVideoSrc(blobURL);
  };

  //Convert the time obtained from the video to HH:MM:SS format
  const convertToHHMMSS = val => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    let time;
    // only mm:ss
    if (hours === "00") {
      time = minutes + ":" + seconds;
    } else {
      time = hours + ":" + minutes + ":" + seconds;
    }
    return time;
  };

  //Get the duration of the video using videoRef
  useEffect(() => {
    if (videoRef && videoRef.current) {
      const currentVideo = videoRef.current;
      currentVideo.onloadedmetadata = () => {
        setEndTime(currentVideo.duration);
        setVideoDuration(currentVideo.duration);
      };
    }
  }, [videoSrc]);

  //Called when handle of the slider is being dragged
  const updateOnSliderChange = values => {
    setVideoTrimmedUrl("");
    setMsg({ err: "", loading: false });
    let readValue;
    if (values) {
      readValue = values[1] || 0;
      if (endTime !== readValue && readValue <= videoDuration) {
        setEndTime(readValue);
      }

      readValue = values[0] || 0;
      if (initialSliderValue !== readValue && readValue <= videoDuration - 1) {
        initialSliderValue = readValue;
        if (videoRef && videoRef.current) {
          videoRef.current.currentTime = readValue;
          setStartTime(readValue);
        }
      }
    }
  };

  //Pause the video when then the endTime matches the currentTime of the playing video
  const handlePauseVideo = e => {
    const currentTime = Math.floor(e.currentTarget.currentTime);
    if (currentTime === endTime) {
      e.currentTarget.pause();
    }
  };

  //Trim functionality of the video
  const handleTrim = async () => {
    setMsg({ err: "Please wait for 10 - 15 seconds", loading: true });
    if (isScriptLoaded) {
      const { name, type } = videoFileValue;
      ffmpeg.FS("writeFile", name, await window.FFmpeg.fetchFile(videoFileValue));
      const videoFileType = type.split("/")[1];
      await ffmpeg.run(
        "-i",
        name,
        "-ss", // for trimming
        `${convertToHHMMSS(startTime)}`,
        "-to",
        `${convertToHHMMSS(endTime)}`,
        "-acodec",
        "copy",
        "-vcodec",
        "copy",
        "-c:v", // for compression
        "libx264",
        "-crf",
        "30",
        "-preset",
        "fast",
        `out.${videoFileType}`
      );
      const data = ffmpeg.FS("readFile", `out.${videoFileType}`);
      const url = URL.createObjectURL(new Blob([data.buffer], { type: videoFileValue.type }));
      setMsg({ err: "", loading: false });
      setVideoTrimmedUrl(url);
      const file = new File([data.buffer], `${mmsMedia.fileName}`, {
        type: videoFileValue.type
      });
      setVideoTrimmedFile(file);
    }
  };

  const handleNextClick = () => {
    const fileSize = videoTrimmedFile ? videoTrimmedFile?.size : mmsMedia?.media?.size;

    if (fileSize > MAX_FILE_SIZE) {
      setMsg({
        err: "Your File size is still more than 1mb need to trim more",
        loading: false
      });
    } else {
      handleSubmit({
        ...mmsMedia,
        ...(videoTrimmedFile && { media: videoTrimmedFile })
      });
      modalStore.toggleModal("showVideoTrimmerModal", false);
      setMsg({ err: "", loading: false });
      setVideoFileValue();
      setVideoTrimmedFile();
      setVideoTrimmedUrl();
      setStartTime(0);
      setEndTime(0);
      setVideoDuration(0);
    }
  };

  return (
    <div>
      {videoSrc.length ? (
        <React.Fragment>
          <p className="mb-4 text-muted text-center">Now you can trim your video clip and make it more useful.</p>
          <div className="d-flex justify-content-around align-items-center">
            <video controls src={videoSrc} style={{ width: "20rem" }} ref={videoRef} onTimeUpdate={handlePauseVideo} className="rounded-4">
              <source src={videoSrc} type={videoFileValue?.type} />
            </video>
            {videoTrimmedUrl && (
              <>
                <ScissorOutlined className="text-dark" style={{ fontSize: "2em" }} />
                <video controls style={{ width: "20rem" }} className="rounded-4">
                  <source src={videoTrimmedUrl} type={videoFileValue?.type} />
                </video>
              </>
            )}
          </div>
          <div className="w-100 text-center py-3">
            <Slider range step={1} max={30} defaultValue={[0, 30]} value={[startTime, endTime]} onChange={updateOnSliderChange} />
            <p className="my-3">
              Start: {convertToHHMMSS(startTime)} &nbsp; End:
              {convertToHHMMSS(endTime)}
            </p>
            <p className="w-100 text-center text-danger">{msg.err}</p>
            {!msg.loading && (
              <div className="me-3 mb-md-0 mb-3 d-flex justify-content-center">
                <AppButton light type="button" className="mx-2" label="Trim" onClick={handleTrim} />
                {isScriptLoaded && <AppButton light type="button" className="mx-2" label="Next" onClick={handleNextClick} />}
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default inject(stores => ({
  gatewayMmsStore: stores.store.gatewayMmsStore,
  modalStore: stores.store.modalStore
}))(observer(VideoTrimerForm));
