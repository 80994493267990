import React, { useState } from "react";

import floatStyle from "./floatLabel.module.css";

const FloatLabel = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, className = "" } = props;

  const labelClass = focus || (value && value.length !== 0) ? `${floatStyle.label} ${floatStyle.labelFloat}` : `${floatStyle.label}`;

  return (
    <div className={`${floatStyle.floatLabel} ${className}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;
