import React, { useState } from "react";
import { Modal, Typography, Row, Col, Form } from "antd";
import * as mt from "moment";
import moment from "moment-timezone";
import AppButton from "components/AppButton/AppButton";
import AppCalendar from "components/AppCalendar/AppCalendar";
import AppTimePicker from "components/AppPickers/AppTimePicker";
import styles from "assets/css/modals.module.css";
import { dateFormat } from "constant/constant";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  selectedDateTime: Yup.date()
    .required("Time is required")
    .min(mt().add(5, "minutes"), "Time must be at least 5 minutes from now")
});

const ScheduleMessage = props => {
  const { isVisible, handleCancel, onSubmit, formValues, removeSchedule, modify = false } = props;
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const currentTime = mt().format("HH:mm A");
  const tz = moment.tz.guess();
  const tzName = moment.tz(tz).zoneAbbr();

  const initialValues = {
    selectedDateTime: formValues?.scheduleAt ? mt(formValues.scheduleAt) : mt().add(5, "minutes")
  };

  return (
    <Modal
      destroyOnClose
      centered
      title={
        <div className="text-center">
          <h4 className="pt-3 fw-bold">{modify ? "Change Time & Date" : "Select Time & Date"}</h4>
        </div>
      }
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      visible={isVisible}
      onCancel={handleCancel}
      width={600}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      footer={null}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit({
            selectedDateTime: values.selectedDateTime,
            bulk: formValues
          });
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <Row type="flex" justify="center" gutter={[10, 20]}>
              <Col span={24} md={12} className="order-2 order-md-1">
                <Row type="flex" justify="center">
                  <AppCalendar
                    onChange={date => {
                      setFieldValue("selectedDateTime", date);
                    }}
                    className="shadow-sm rounded w-100"
                    value={values.selectedDateTime}
                  />
                </Row>
                <Row type="flex" justify="center" align="middle" className="mt-3">
                  <Typography.Text>
                    <i className="fas fa-globe-asia me-2" />
                    {tzName} - {tz} ({currentTime})
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={24} md={12} lg={10} className="order-1 order-md-2 d-flex align-items-center justify-content-start flex-column gap-3">
                <Row type="flex" justify="center" className="mt-2 mt-md-5">
                  <Typography.Title level={4} className="text-info-color">
                    {mt(values.selectedDateTime).format(dateFormat)}
                  </Typography.Title>
                </Row>
                <Row type="flex" justify="center">
                  <AppTimePicker
                    errors={errors.selectedDateTime}
                    touched={touched.selectedDateTime}
                    open={openTimePicker}
                    onOpenChange={setOpenTimePicker}
                    value={values.selectedDateTime}
                    use12Hours
                    onChange={date => {
                      if (date) {
                        setFieldValue("selectedDateTime", date);
                      }
                    }}
                    allowClear={false}
                    format="h:mm A"
                    className="w-100"
                    addon={() => <AppButton size="small" light label="Ok" onClick={() => setOpenTimePicker(false)} />}
                  />
                </Row>
              </Col>
              <Col span={24} className="d-flex justify-content-center gap-3 order-4">
                {!modify && <AppButton label="Remove Schedule" light withoutBg onClick={removeSchedule} />}
                <AppButton label={!modify ? "Schedule Message" : "Reschedule Message"} light type="submit" disabled={isSubmitting} />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ScheduleMessage;
