import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { NavLink, useHistory, Link, useLocation } from "react-router-dom";
import { Popover, Menu, Modal, Row, Col, Tooltip } from "antd";
import styles from "./navbar.module.scss";
import { cookie } from "utils/cookie";
import { AUTH_TOKEN, ADMIN_TOKEN } from "constant/constant";
import { guniHelpUrl } from "constant/constant";
import AppButton from "components/AppButton/AppButton";
import notificationIcon from "assets/images/navbar/notification.svg";
import notificationIconActive from "assets/images/navbar/notification-active.svg";
import notificationIconAlert from "assets/images/navbar/notification-alert.svg";
import CustomDropdown from "components/AppDropdown/CustomDropdown/CustomDropdown";
import View from "components/TrialUser/Modals/UpgradeModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import MenuIcon from "assets/images/circled-menu.png";
import clsx from "clsx";
import { removeDataCookies } from "constant/contacts";
const adminToken = cookie.get(ADMIN_TOKEN);

const NavBar = props => {
  const { authStore, headerName, modalStore } = props;
  const { user, balanceObj, newUserNotification, userNotification, setReadNotification, logout } = authStore;
  const history = useHistory();
  const location = useLocation();
  const [navToggle, setNavToggle] = useState(false);
  const [twoToneColor, setTwoToneColor] = useState("#000000");
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [activeIcon, setActiveIcon] = useState(false);
  const trialUser = user?.role === "trial" ? true : false;

  if (!user || !balanceObj) {
    return (
      <nav className={`navbar navbar-expand-lg justify-content-between py-2 px-4 bg-white ${styles.nav}`}>
        <div className={`rounded-pill ${styles.text}`} />
        <div className={`me-2 ${styles.image}`} />
      </nav>
    );
  }

  let profileMenu = [
    {
      label: "Profile",
      path: "/profile",
      icon: "user-circle"
    },
    {
      label: "Sender Ids",
      path: "/sender-ids",
      icon: "id-badge"
    },
    {
      label: "Security",
      path: "/security",
      icon: "shield-alt"
    },
    user.role !== "subaccount" && {
      label: "Settings",
      path: "/settings",
      icon: "cog"
    }
  ].filter(Boolean);

  const handleAdminLogin = () => {
    if (adminToken && (adminToken.role == "superAdmin" || adminToken.role == "user")) {
      removeDataCookies();
      cookie.set(AUTH_TOKEN, adminToken.token);
      cookie.erase(ADMIN_TOKEN);
      window.location.href = adminToken.role == "superAdmin" ? "/admin/users" : "/sub-accounts";
    }
  };

  const handleToggle = () => {
    setNavToggle(!navToggle);
  };

  const handleUpgradClick = () => {
    modalStore.toggleModal("showUpgrade", true);
  };

  const noNewNotification = () => {
    let show = false;
    Object.keys(userNotification).map(x => {
      if (userNotification[x].show) show = true;
    });
    return show;
  };

  const goToNotification = (type, isMms) => {
    history.push({ pathname: "/history", key: isMms ? "mms" : "sms" });
    setActiveIcon(false);
    setReadNotification(true, true, type);
  };

  const markAsRead = type => {
    setReadNotification(true, true, type);
  };

  const menu = (
    <Menu className="border-0">
      {noNewNotification() ? (
        Object.keys(userNotification).map(x => {
          if (userNotification[x].show)
            return (
              <Menu.Item className="d-flex flex-row gap-2 p-0" key={x}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "var(--appMainDarkColor)",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                    color: "white"
                  }}
                  onClick={() => goToNotification(userNotification[x].type, userNotification[x].isMms)}
                >
                  {userNotification[x].type.charAt(0).toUpperCase()}
                </div>
                <span className="fw-bold text-info-color" onClick={() => goToNotification(userNotification[x].type)}>
                  {userNotification[x].message}
                </span>
                <span onClick={() => markAsRead(userNotification[x].type)} onMouseEnter={() => setTwoToneColor(true)} onMouseLeave={() => setTwoToneColor(false)}>
                  <i className={`fas fa-trash-alt ms-2 ${twoToneColor ? "text-danger-color" : "text-info-color"}`} />
                </span>
              </Menu.Item>
            );
        })
      ) : (
        <Menu.Item className="p-0 pe-none">
          <span className="fw-bold text-info-color">No new notification.</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleNotification = e => {
    setActiveIcon(e);
    setReadNotification(e);
  };

  return (
    <>
      <nav className={clsx("navbar navbar-light navbar-expand-lg bg-light py-2 px-4 sticky-top", styles.nav, { [styles.navToggle]: navToggle })} style={{ zIndex: 2 }}>
        <div className={clsx("d-flex align-items-center m-0 fw-bold text-info-color", styles.navTitle)}>{headerName}</div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={navToggle}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={clsx({ show: navToggle }, "collapse navbar-collapse")} id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto align-items-center">
            {/* {trialUser && (
              <li className="nav-item mb-2 mb-lg-0">
                <div className={`nav-link px-2 fw-bold`}>
                  <AppButton
                    type="button"
                    style={{ backgroundColor: "#f1bd6c", color: "black" }}
                    onClick={() => handleUpgradClick()}
                    label={
                      user?.isEmailVerified && user?.isMobileVerified
                        ? "Verification Pending"
                        : "Upgrade"
                    }
                    prefixIcon={<i className="fas fa-crown me-2" />}
                  />
                </div>
              </li>
            )} */}
            {adminToken && (adminToken.role == "superAdmin" || adminToken.role == "user") && (
              <li className="nav-item mb-2 mb-lg-0">
                <div className={`nav-link px-2 fw-bold`}>
                  <AppButton
                    type="button"
                    style={{
                      backgroundColor: adminToken.role == "superAdmin" ? "#f5222d" : "#f1bd6c",
                      color: "white"
                    }}
                    onClick={() => handleAdminLogin()}
                    label={adminToken.role == "superAdmin" ? "Super Admin" : "Admin"}
                    prefixIcon={<i className="fas fa-user-shield me-2" />}
                  />
                </div>
              </li>
            )}
            <li className="nav-item">
              <div className="nav-link">
                <CustomDropdown balance={balanceObj?.maxPossibleCredits} />
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link">
                <AppButton light type="button" onClick={() => history.push("/quick-actions")} label="Shortcuts" />
              </div>
            </li>
            <li className="nav-item mb-2 mb-lg-0">
              <div className={`nav-link px-2 bg-white rounded text-dark d-flex align-items-center`} style={{ fontSize: 14 }}>
                <span className="fs-6">{balanceObj ? balanceObj.symbol[0] + balanceObj.balance : "Loading..."}</span>
                {user.profileType !== "postpaid" &&
                  ((user?.role === "subaccount" && user?.deductFromAccount) || user?.role !== "subaccount") &&
                  (trialUser ? (
                    <NavLink to="#" onClick={() => modalStore.toggleModal("showUpgrade", true)} className="d-flex align-items-center">
                      <PlusCircleOutlined className="text-dark ms-2 fs-4" style={{ verticalAlign: "0.2em" }} />
                    </NavLink>
                  ) : (
                    <NavLink
                      to={{
                        pathname: "/buy-credits",
                        state: { from: location.pathname }
                      }}
                      className="d-flex align-items-center"
                    >
                      <PlusCircleOutlined className="text-dark ms-2 fs-4" style={{ verticalAlign: "0.2em" }} />
                    </NavLink>
                  ))}
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link">
                <Tooltip title="Documentation" placement="bottomRight" trigger={["click", "hover"]}>
                  <div
                    onClick={() => window.open(guniHelpUrl, "_blank")}
                    type="button"
                    className="pe-auto d-flex align-items-center justify-content-center bg-white"
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      width: 36,
                      height: 36,
                      borderRadius: "50%"
                    }}
                  >
                    <i className="fa fa-question text-dark fs-3" />
                  </div>
                </Tooltip>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link bg-white rounded-circle ms-2">
                <Popover placement="bottomRight" content={menu} trigger={["click", "hover"]} onVisibleChange={handleNotification} arrowPointAtCenter>
                  <img
                    src={newUserNotification && !activeIcon ? notificationIconAlert : activeIcon ? notificationIconActive : notificationIcon}
                    className={newUserNotification && !activeIcon ? "bell-tada" : activeIcon ? "bell-tada" : ""}
                    height="20px"
                    width="24px"
                    alt="notification-icon"
                    type="button"
                  />
                </Popover>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link">
                <Popover
                  overlayClassName="popover-custom"
                  placement="bottomRight"
                  trigger={["click", "hover"]}
                  content={
                    <>
                      <div>
                        <ul className={`list-unstyled me-1 ${styles.profileBtn}`}>
                          {profileMenu.map((item, index) => (
                            <NavLink
                              key={index}
                              to={{
                                pathname: item.path,
                                state: { from: location.pathname }
                              }}
                            >
                              <li>
                                <i className={`fas fa-${item.icon}`} />
                                <span>{item.label}</span>
                              </li>
                            </NavLink>
                          ))}
                          {user.isSpecialPlanActive && user.profileType !== "postpaid" && (
                            <NavLink
                              to={{
                                pathname: "/buy-credits",
                                state: { from: location.pathname }
                              }}
                            >
                              <li>
                                <i className="fas fa-star" />
                                <span>Special Plan</span>
                              </li>
                            </NavLink>
                          )}
                          <a href={guniHelpUrl} target="_blank">
                            <li style={{ cursor: "pointer" }}>
                              <i className="fas fa-book" />
                              {/* <a href={guniHelpUrl} target="_blank"> */}
                              <span>Documents</span>
                              {/* </a> */}
                            </li>
                          </a>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLogoutPopup(true);
                            }}
                          >
                            <i className="fas fa-sign-out-alt" />
                            <span>Logout</span>
                          </li>
                          <hr />
                          <li className="d-flex justify-content-between fw-bold pe-none">
                            <span>SMS :</span>
                            <span>{balanceObj?.maxPossibleCredits?.sms}</span>
                          </li>
                          <li className="d-flex justify-content-between fw-bold pe-none">
                            <span>MMS :</span>
                            <span>{balanceObj?.maxPossibleCredits?.mms}</span>
                          </li>
                        </ul>
                      </div>
                    </>
                  }
                >
                  <div type="button" style={{ width: 40, height: 40 }} className={`nav-link bg-white text-dark rounded-circle d-flex align-items-center justify-content-center fw-bold`}>
                    {/* {`${username}`} */}
                    <img src={MenuIcon} alt="menu" />
                  </div>
                </Popover>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {trialUser && <View />}
      {logoutPopup && (
        <Modal
          destroyOnClose
          visible={logoutPopup}
          onOk={() => setLogoutPopup(false)}
          onCancel={() => setLogoutPopup(false)}
          centered
          title={
            <div className="text-center">
              <h4 className="pt-3 fw-bold">Log out ?</h4>
            </div>
          }
          closeIcon={
            <div className={`btn ${styles.modalCloseBtn}`} data-dismiss="modal" key={1} type="button">
              <i className="fas fa-times"></i>
            </div>
          }
          maxWidth="1000px"
          footer={null}
          maskStyle={{
            background: "linear-gradient(135deg, #00000050, #107c9850)"
          }}
        >
          <Row gutter={[32, 32]} className="px-5">
            <Col span={24} className="text-center">
              <h5 className="fw-bold text-center">Are you sure you want to log out ?</h5>
            </Col>
            <Col span={12} className="text-center">
              <AppButton
                label="Log Out"
                light
                onClick={() => {
                  logout();
                }}
                className="mx-3"
              />
            </Col>
            <Col span={12} className="text-center">
              <AppButton label="Cancel" light withoutBg onClick={() => setLogoutPopup(false)} className="mx-3" />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(NavBar));
