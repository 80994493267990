import React from "react";
import { inject, observer } from "mobx-react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import EditTemplateForm from "../TemplateForms/EditTemplateForm";

const TemplateModal = props => {
  const { modalStore, isMms } = props;
  return (
    <>
      <Modal
        destroyOnClose
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Create Template</h4>
          </div>
        }
        zIndex={1032}
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showCreateTemplateModal}
        onCancel={() => {
          modalStore.toggleModal("showCreateTemplateModal", false);
        }}
        maxWidth={800}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="px-5">
          <EditTemplateForm addTempBtn={false} isMms={isMms} />
        </div>
      </Modal>
    </>
  );
};
export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(TemplateModal));
