import React, { useCallback, useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import Cropper from "react-easy-crop";
import { Slider, Row, Col, Tooltip } from "antd";
import { ScissorOutlined } from "@ant-design/icons";
import AppButton from "components/AppButton/AppButton";
export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, mmsMedia, flip = { horizontal: false, vertical: false }) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve(new File([file], mmsMedia?.media?.name, { type: mmsMedia?.media?.type }));
      // resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}

const MyCropper = props => {
  const { gatewayMmsStore, modalStore } = props;
  const { mmsMedia, handleSubmit } = gatewayMmsStore;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image, setImage] = useState();
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [activeOption, setActiveOption] = useState("rotate");
  const [imgDimension, setImagDimension] = useState();

  useEffect(() => {
    if (mmsMedia) {
      setTimeout(() => {
        setImage(URL.createObjectURL(mmsMedia?.media));
      }, 200);
    }
  }, [mmsMedia]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation, mmsMedia);
      setImagDimension(croppedAreaPixels);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const handleNextClick = async () => {
    handleSubmit({
      ...mmsMedia,
      ...(croppedImage ? { media: croppedImage } : { media: image })
    });
    modalStore.toggleModal("showImageCropModal", false);
    setCroppedImage(null);
    setImage();
    setAspectRatio(16 / 9);
  };

  const commonClass = "btn border rounded border-dark mx-2";
  const ratios = [
    {
      label: "1:1",
      value: 1 / 1,
      class: "p-1 px-2",
      callBack: () => setAspectRatio(1 / 1)
    },
    {
      label: "5:4",
      value: 5 / 4,
      class: "px-2 px-1",
      callBack: () => setAspectRatio(5 / 4)
    },
    {
      label: "9:16",
      value: 9 / 16,
      class: "py-2 px-1",
      callBack: () => setAspectRatio(9 / 16)
    },
    {
      label: "16:9",
      value: 16 / 9,
      class: "px-2 py-1",
      callBack: () => setAspectRatio(16 / 9)
    }
  ];

  return (
    <div className="w-100">
      <Row type="flex" align="stretch" justify="center" className="mb-3">
        <Col xs={24} md={2} className="d-flex flex-md-column">
          <Tooltip placement="right" title="Rotate">
            <div role="button" className={`p-3 my-3 ${activeOption === "rotate" ? "text-info-color" : ""}`} onClick={() => setActiveOption("rotate")}>
              <i className="fas fa-sync-alt" style={{ fontSize: "2em" }} />
            </div>
          </Tooltip>
          <Tooltip placement="right" title="Crop">
            <div role="button" className={`p-3 my-3 ${activeOption === "crop" ? "text-info-color" : ""}`} onClick={() => setActiveOption("crop")}>
              <i className="fas fa-crop" style={{ fontSize: "2em" }} />
            </div>
          </Tooltip>
          <Tooltip placement="right" title="Zoom">
            <div role="button" className={`p-3 my-3 ${activeOption === "zoom" ? "text-info-color" : ""}`} onClick={() => setActiveOption("zoom")}>
              <i className="fas fa-search-plus" style={{ fontSize: "2em" }} />
            </div>
          </Tooltip>
        </Col>
        <Col xs={24} md={22}>
          <Row type="flex" justify="center" align="stretch" className="mb-3">
            <Col xs={24} md={10} style={{ position: "relative", width: "18rem", height: "18rem" }} className="rounded-4 image-fluid overflow-hidden">
              <Cropper
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </Col>
            {croppedImage && (
              <>
                <Col xs={24} md={2} className="d-flex justify-content-center align-items-center">
                  <ScissorOutlined className="text-dark" style={{ fontSize: "2em" }} />
                </Col>
                <Col xs={24} md={10} className="rounded-4 d-flex align-items-center justify-content-center overflow-hidden" style={{ background: "#7f7f7f" }}>
                  <img
                    src={URL.createObjectURL(croppedImage)}
                    alt="img"
                    style={{
                      maxHeight: "230px",
                      width: imgDimension?.height > imgDimension?.width ? "auto" : "100%"
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <div className="d-flex justify-content-evenly mt-3">
        {activeOption === "rotate" && (
          <label className="w-25">
            Rotate
            <Slider value={rotation} min={0} max={360} step={1} aria-labelledby="rotate" onChange={rotation => setRotation(rotation)} />
          </label>
        )}
        {activeOption === "crop" && (
          <label className="w-50">
            <p>Ratio</p>
            <div className="d-flex align-items-center justify-content-evenly fs-5">
              {ratios.map((item, key) => {
                return (
                  <span key={key} className={`${commonClass} ${item.class} ${aspectRatio === item.value ? "text-info-color border-info-color" : ""}`} onMouseOver={item?.callBack}>
                    {item.label}
                  </span>
                );
              })}
            </div>
          </label>
        )}
        {activeOption === "zoom" && (
          <label className="w-25">
            Zoom
            <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="zoom" onChange={zoom => setZoom(zoom)} className="" />
          </label>
        )}
      </div>
      <div className="w-100 me-3 mb-md-0 my-4 d-flex justify-content-center">
        {croppedImage && <AppButton withoutBg type="button" className="mx-2" label="Reset" onClick={onClose} />}
        <AppButton withoutBg type="button" className="mx-2" label="Crop" onClick={showCroppedImage} />
        {croppedImage && <AppButton light type="button" className="mx-2" label="Next" onClick={handleNextClick} />}
      </div>
    </div>
  );
};

export default inject(stores => ({
  gatewayMmsStore: stores.store.gatewayMmsStore,
  modalStore: stores.store.modalStore
}))(observer(MyCropper));
