import { get, post, put, deleteRequest } from "utils/api";

// Total System Usage API
export const getTotalSystemUsage = async params => {
  const TotalSystemSpentResponse = await get("/admin/smsExpense", params);
  return TotalSystemSpentResponse.data;
};

// Total System Purchase API
export const getTotalSystemPurchase = async params => {
  const TotalSystemPurchaseResponse = await get(`/admin/purchase`, params);
  return TotalSystemPurchaseResponse.data;
};

// Users Expense List API
export const getUsersExpense = async params => {
  const UsersExpenseResponse = await get("/admin/usage", params);
  return UsersExpenseResponse.data;
};

// Users Tokens List API
export const getUsersTokens = async params => {
  const UsersTokensResponse = await get("/admin/view-token", params);
  return UsersTokensResponse.data;
};

// provider routes page apis
export const getProviderRoutes = async params => {
  const response = await get("/admin/sender-provider", params);
  return response.data.data;
};

export const deleteProviderRoutes = async (params, id) => {
  const response = await deleteRequest(`/admin/sender-provider/${id}`, params);
  return response.data;
};

export const mapProviderRoute = async params => {
  const response = await post(`/admin/sender-provider`, params);
  return response.data;
};

export const updateProviderMapping = async (id, params) => {
  const response = await put(`/admin/sender-provider/${id}`, params);
  return response.data;
};

// provider SQS apis
export const getProviderSQS = async params => {
  const response = await get("/admin/provider-meta", params);
  return response.data.data;
};

export const deleteProviderSQS = async (params, id) => {
  const response = await deleteRequest(`/admin/provider-meta/${id}`, params);
  return response.data;
};

export const mapProviderSQS = async params => {
  const response = await post(`/admin/provider-meta`, params);
  return response.data;
};

export const updateProviderSQS = async (id, params) => {
  const response = await put(`/admin/provider-meta/${id}`, params);
  return response.data;
};

export const getAllProviders = async params => {
  const response = await get(`/admin/provider`, params);
  return response.data.data;
};

export const getDefaultProvider = async params => {
  const response = await get(`/admin/default-provider`, params);
  return response.data;
};

export const setDefaultProvider = async params => {
  const response = await post(`/admin/default-provider`, params);
  return response.data;
};
