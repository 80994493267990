import { runInAction, decorate, observable } from "mobx";
import { getOptOutInformation, updateIsBlockedByUser, UserGetOptOutInformation, UserUpdateIsBlockedByUser } from "service/optOutService";

class ObservableOptOutStore {
  updateBlockStatusResponse = {};

  updateUserBlockStatus = async args => {
    const updateBlockStatusResponse = await updateIsBlockedByUser(args);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };
  getOptOutInformation = async code => {
    const updateBlockStatusResponse = await getOptOutInformation(code);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };

  UserUpdateUserBlockStatus = async args => {
    const updateBlockStatusResponse = await UserUpdateIsBlockedByUser(args);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };

  UserGetOptOutInformation = async code => {
    const updateBlockStatusResponse = await UserGetOptOutInformation(code);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };
}
decorate(ObservableOptOutStore, {});

export default new ObservableOptOutStore();
