import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Tooltip, Skeleton, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import FormInput from "components/FormItems/FormInput";
import { getFormatedContact } from "constant/messageConstants";
import FormSelectInput from "components/FormItems/FormSelectInput";
import { sharedNumber } from "constant/constant";
import { Link as AppLink } from "react-router-dom";
import { toJS } from "mobx";

function SenderMenu(props) {
  const {
    isMms = false,
    errors,
    addIcon = false,
    label = (
      <span className="fw-bold">
        Who is sending this {isMms ? "mms" : "sms"}{" "}
        <Tooltip
          title={
            <span className="text-center fs-6 text-normalize">
              For Dedicated Number {!isMms && "or Business Name"} go to the{" "}
              <AppLink to="/sender-ids" target="_blank" className="text-info-color text-hover-underline">
                sender ids
              </AppLink>{" "}
              page.
            </span>
          }
        >
          <i className="fa fa-exclamation-circle text-info-color" />
        </Tooltip>
      </span>
    ),
    Link = true,
    touched,
    values,
    setFieldValue,
    messageStore,
    authStore,
    size = "large",
    isQuick = false,
    secondary = false,
    personalNumberStore,
    dedicatedNumStore,
    selectInputClass = "",
    selectProps = {}
  } = props;
  const [businessName, setBusinessName] = useState(false);
  const [options, setOptions] = useState([]);
  const inputRef = useRef();
  const [personalSendersList, setPersonalSendersList] = useState([]);

  const { user, allSenders, dedicatedNumList } = authStore;
  const { getPersonalNumbers } = personalNumberStore;
  const { getPurchasedDedicatedNumList, releaseDedicatedNumber, purchasedNum } = dedicatedNumStore;
  const [dList, setDList] = useState([]);

  useEffect(() => {
    getPersonalNumbers().then(res => {
      setPersonalSendersList(res);
    });
    getPurchasedDedicatedNumList().then(res => {
      setDList(toJS(res));
    });
  }, []);

  useEffect(() => {
    if (values?.sender === sharedNumber) {
      setFieldValue("senders", "1");
      if (!isQuick) {
        setFieldValue("optout", false);
        setFieldValue("replyStopToOptOut", true);
        setFieldValue("optType", "replyStopToOptOut");
      }
      setFieldValue("senderType", "shared");
      messageStore.setPhoneSender(sharedNumber);
    } else if (user?.personalSenders ? [user.mobile, ...user?.personalSenders].filter(x => x).includes(values?.sender) : [user?.mobile].filter(x => x).includes(values?.sender) && !isMms) {
      setFieldValue("senders", "4");
      if (!isQuick) {
        setFieldValue("optout", true);
        setFieldValue("optType", "optout");
        setFieldValue("replyStopToOptOut", false);
      }
      messageStore.setPhoneSender(getFormatedContact(values?.sender));
      setFieldValue("senderType", "personal");
    } else if (dedicatedNumList[isMms ? "mms" : "sms"].includes(values?.sender)) {
      setFieldValue("senders", "2");
      messageStore.setPhoneSender(values?.sender);
      if (!isQuick) {
        setFieldValue("optout", false);
        setFieldValue("replyStopToOptOut", true);
        setFieldValue("optType", "replyStopToOptOut");
      }
      setFieldValue("senderType", "dedicated");
    } else if (user?.senders.includes(values?.sender) && !isMms) {
      setFieldValue("senders", "3");
      if (!isQuick) {
        setFieldValue("optout", true);
        setFieldValue("optType", "optout");
        setFieldValue("replyStopToOptOut", false);
      }
      messageStore.setPhoneSender(values?.sender);
      setFieldValue("senderType", "business");
    }
  }, [isMms, values?.sender, values.senderType]);

  useEffect(() => {
    inputRef?.current?.focus({
      cursor: "end"
    });
  }, [inputRef, businessName]);

  useEffect(() => {
    const activeNumbers = dList?.filter(num => num.active) || [];

    if (isMms) {
      setOptions([
        {
          label: sharedNumber,
          value: sharedNumber
        },
        ...dedicatedNumList?.mms
          .map(sender => {
            const dedicatedNumber = activeNumbers.find(dn => dn.dedicatedid.number === sender && (dn.dedicatedid.type === "mms" || dn.dedicatedid.type === "common"));

            if (!dedicatedNumber) return null;

            return {
              label: `Dedicated: ${sender} ${dedicatedNumber?.reference ? `(${dedicatedNumber.reference})` : ""}`,
              value: sender
            };
          })
          .filter(Boolean)
      ]);
    } else {
      setOptions([
        ...user?.senders.map(sender => ({
          label: `Business: ${sender}`,
          value: sender
        })),
        ...dedicatedNumList?.sms
          .map(sender => {
            const dedicatedNumber = activeNumbers.find(dn => dn.dedicatedid.number === sender && (dn.dedicatedid.type === "sms" || dn.dedicatedid.type === "common"));

            if (!dedicatedNumber) return null;

            return {
              label: `Dedicated: ${sender} ${dedicatedNumber?.reference ? `(${dedicatedNumber.reference})` : ""}`,
              value: sender
            };
          })
          .filter(Boolean),
        ...dedicatedNumList?.mobile.map(sender => ({
          label: `Personal: ${sender} ${
            personalSendersList.length > 0 && personalSendersList.find(pn => pn.number === sender)?.reference ? `(${personalSendersList.find(pn => pn.number === sender).reference})` : ""
          }`,
          value: sender
        })),
        {
          label: sharedNumber,
          value: sharedNumber
        }
      ]);
    }
  }, [allSenders, user, isMms, personalSendersList, dList]);

  if (!allSenders || !user) {
    return <Skeleton />;
  }

  return (
    <>
      {!isMms && businessName ? (
        <FormInput
          size={size}
          label={label}
          secondary={secondary}
          containerProps={{ colon: false }}
          error={errors.sender}
          touched={touched.sender}
          containerClassname="mb-0"
          name="sender"
          value={values.sender}
          onChange={value => {
            setFieldValue("sender", value.target.value);
            messageStore.setPhoneSender(value.target.value);
          }}
          inputProps={{
            ref: inputRef,
            maxLength: 11
          }}
          suffix={
            <Tooltip title="Change Sender">
              <EditOutlined
                onClick={() => {
                  setBusinessName(false);
                  setFieldValue("sender", "");
                  messageStore.setPhoneSender("------");
                  if (Link) {
                    if (isQuick) {
                      setFieldValue("replyLink", false);
                      setFieldValue("replyLinkVisible", false);
                    }
                  }
                }}
              />
            </Tooltip>
          }
          placeholder={"Enter Business Name"}
        />
      ) : (
        <div className="d-flex align-items-center">
          <FormSelectInput
            size={size}
            selectProps={selectProps}
            capitalizeLabel={false}
            secondary={secondary}
            label={label}
            containerProps={{ colon: false }}
            error={errors.sender}
            touched={touched.sender}
            name="sender"
            selectEleClassnames={selectInputClass}
            value={values.sender ? values.sender : undefined}
            onChange={value => {
              setFieldValue("sender", value);
              messageStore.setPhoneSender(value);
              if (Link) {
                if (isQuick) {
                  setFieldValue("replyLinkVisible", user.senders && user.senders.includes(value));
                  if (user.senders && !user.senders.includes(value)) {
                    setFieldValue("replyLink", false);
                  }
                } else {
                  setFieldValue("optout", user.senders && user.senders.includes(value));
                  setFieldValue("replyStopToOptOut", value === sharedNumber || (user.mobileSenders && user.mobileSenders.includes(value)));
                }
              }
            }}
            options={options}
            placeholder={"Sender e.g. John"}
            dropdownRender={option => (
              <>
                {option}
                {user.role === "subaccount" ? null : (
                  <Typography.Text
                    className={`text-info-color ant-select-dropdown-menu-item`}
                    onClick={() => window.open(`${window.location.origin}/sender-ids`, "_blank")}
                    onMouseDown={e => e.preventDefault()}
                  >
                    Add New Sender
                  </Typography.Text>
                )}
              </>
            )}
          />
          {addIcon && user.role !== "subaccount" && (
            <Tooltip title={"Add New Sender"}>
              <i className="fa fa-plus-circle text-info-color fs-5 ms-4" />
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore,
  messageStore: stores.store.messageStore,
  personalNumberStore: stores.store.personalNumberStore,
  dedicatedNumStore: stores.store.dedicatedNumStore
}))(observer(SenderMenu));
