import { runInAction, decorate, observable } from "mobx";
import { getAllBulks, addBulk, resendFailedBulk } from "service/bulkService";
import { getBulkDetails } from "../../service/bulkService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableBulkStore {
  bulkList = [];
  bulkDetails = [];
  addBulkResponse = undefined;
  selectedBulk = undefined;
  resendBulkResponse = undefined;
  pageSize = 10;
  current = 1;
  totalPages = 0;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * get all Bulks
   * @returns {Promise<[]>}
   */
  getAllBulkList = async isAdd => {
    const params = {
      page: isAdd ? this.current + 1 : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search.length > 0) params.search = this.search.search;
    const bulkListResponse = await getAllBulks(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = bulkListResponse;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || bulkListResponse), "_id") : _.uniq(items || bulkListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.bulkList;
  };

  getBulkDetails = async bulk => {
    const bulkDetailResponse = await getBulkDetails(bulk);
    try {
      runInAction(() => {
        this.bulkDetails = bulkDetailResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.bulkDetails;
  };

  /**
   * add new Bulk
   * @param bulkData
   * @returns {Promise<*>}
   */
  addBulk = async (bulkData, mode) => {
    // call api for add new Bulk
    // console.log("inspide api");
    const addBulkResponse = await addBulk(bulkData, mode);
    // console.log("inspide api", addBulkResponse);
    try {
      runInAction(() => {
        this.addBulkResponse = addBulkResponse;
      });
      this.getAllBulkList();
    } catch (error) {
      runInAction(() => {
        this.addBulkResponse = error;
      });
    }
    return this.addBulkResponse;
  };

  resendFailedBulk = async failedBulkId => {
    const resendBulkResponse = await resendFailedBulk(failedBulkId);
    console.log("inspide api", failedBulkId);
    try {
      runInAction(() => {
        if (resendBulkResponse.data) {
          this.resendBulkResponse = resendBulkResponse;
        }
      });
      this.getAllBulkList();
    } catch (error) {
      runInAction(() => {
        this.resendBulkResponse = error;
      });
    }
    return this.resendBulkResponse;
  };

  /**
   * select Bulk Data for Update
   * @param bulkData
   */
  selectBulk = bulkData => {
    this.selectedBulk = bulkData ? JSON.parse(JSON.stringify(bulkData)) : undefined;
  };
}

decorate(ObservableBulkStore, {
  bulkList: observable,
  bulkDetails: observable,
  addBulkResponse: observable,
  updateBulkResponse: observable,
  resendBulkResponse: observable,
  selectedBulk: observable,
  deleteBulkResponse: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable
});
export default new ObservableBulkStore();
