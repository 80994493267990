import React from "react";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import Templates from "components/GatewayMessage/Modals/Templates";

function AddTemplateBtn(props) {
  const { modalStore, btnProps = {}, templateStore, onSubmit, smallSize, size = "large", zIndex, setFileAttachBtn, isMms, preMade = true } = props;

  function toggleTemplateModal(show = true) {
    modalStore.toggleModal("showChooseTemplateModal", show);
    templateStore.setPage(1);
    templateStore.setPageSize(10);
    templateStore.setSearch("");
  }

  function handleSelect(template) {
    modalStore.setData("selectedTemplate", template);
    toggleTemplateModal(false);
    if (onSubmit) onSubmit(template);
  }
  return (
    <>
      <AppButton
        className="border-0 bg-light"
        withoutBg
        prefixIcon={<span className="text-decoration-underline text-info-color">Use Template</span>}
        onClick={() => {
          if (setFileAttachBtn) setFileAttachBtn(false);
          toggleTemplateModal(true);
        }}
        size={size}
        {...btnProps}
      />
      <Templates
        preMade={preMade}
        isMms={isMms}
        active
        isVisible={modalStore.showChooseTemplateModal}
        handleCancel={() => {
          if (setFileAttachBtn) setFileAttachBtn(true);
          toggleTemplateModal(false);
        }}
        onTemplateSelect={handleSelect}
        smallSize={smallSize}
        zIndex={zIndex}
      />
    </>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  templateStore: stores.store.templateStore
}))(observer(AddTemplateBtn));
