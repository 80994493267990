import React, { useState } from "react";
import { Col, Radio, Row, DatePicker, Typography } from "antd";
import { dateTimeFormat } from "constant/constant";
import moment from "moment";
import styles from "assets/css/components.module.css";

const QuickSchedule = props => {
  const { setFieldValue, values, error, touched, scheduler = false } = props;
  const [sendType, setSendType] = useState("now");
  let types = [
    {
      title: "Now",
      value: "now"
    }
  ];

  if (scheduler) {
    types = [
      {
        title: "Now",
        value: "now"
      },
      {
        title: "Later",
        value: "schedule"
      }
    ];
  }

  return (
    <Radio.Group
      onChange={e => {
        setSendType(e.target.value);
        if (e.target.value === "now") {
          setFieldValue("scheduleAt", null);
          setFieldValue("sendType", "now");
        } else {
          setFieldValue("sendType", "schedule");
          setFieldValue(
            "scheduleAt",
            moment()
              .add(5, "minute")
              .toISOString()
          );
        }
      }}
      value={sendType}
      className="d-flex gap-3 campaign-radio"
    >
      <Row type="flex" gutter={[10, 10]}>
        {types.map((type, i) => (
          <Col key={i}>
            <Radio.Button className={`${sendType === type.value ? "border-info-color" : "border"} rounded-xl`} value={type.value}>
              <Row type="flex" align="middle">
                <Col span={2}>{sendType === type.value ? <i className="fas fa-check-circle text-info-color" /> : <i className="far fa-circle text-info-color" />}</Col>
                <Col span={20}>
                  <Typography.Title level={4} className="fs-6 mb-0 ms-3">
                    {type.title}
                  </Typography.Title>
                </Col>
              </Row>
            </Radio.Button>
          </Col>
        ))}
        {sendType === "schedule" ? (
          <Col>
            <DatePicker
              onChange={time => {
                if (!time) return setFieldValue("scheduleAt", null);
                setFieldValue("scheduleAt", time.toISOString());
              }}
              value={values.scheduleAt ? moment(values.scheduleAt) : undefined}
              format={dateTimeFormat}
              minuteStep={15}
              use12Hours
              disabled={sendType !== sendType}
              showTime
              style={{
                borderRadius: "10px !important"
              }}
              size="default"
              className={`${styles.antPickerInput} ${sendType !== sendType ? "campaign-not-schedule" : ""}`}
              placeholder="Select Time"
              showToday={false}
            />
          </Col>
        ) : null}
        {error && touched && (
          <Col span={24}>
            <Typography.Text className="text-danger-color fs-6">{error}</Typography.Text>
          </Col>
        )}
      </Row>
    </Radio.Group>
  );
};

export default QuickSchedule;
