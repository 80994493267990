import { runInAction, decorate, observable } from "mobx";
import {
  getAllBulks,
  addBulk,
  editBulk,
  resendFailedBulk,
  getExportBulkLogList,
  getBulkDetails,
  renameBulk,
  getBulkByStatus,
  getBulkDetailsById,
  getAllSavedBulks,
  saveBulkData,
  getSavedBulkById,
  updateSavedBulk,
  deleteSavedBulk,
  getBulkMessages,
  getBulkShortUrlAnalytics,
  getBulkListForApprovalOfUser,
  getScheduledSMSBulkList,
  updateScheduleBulk,
  getCostOfCampaign,
  cancelScheduleBulk,
  getCampaignDetails,
  exportClicksAnalytics,
  getOptoutList,
  addBulkByList,
  sendBatchMessage,
  addBulkForMultiGroup,
  cancelSchedule
} from "service/gatewayService/index";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableGatewayBulkStore {
  bulkList = [];
  messagesList = [];
  clicksList = [];
  bulkDetails = undefined;
  campaignReport = undefined;
  click = undefined;
  addBulkResponse = undefined;
  selectedBulk = undefined;
  resendBulkResponse = undefined;
  pageSize = 10;
  current = 1;
  total = 0;
  totalPages = 0;
  search = "";
  campaign = undefined;
  sortField = "createdAt";
  sortOrder = "descend";
  exportBulkLogList = [];
  renameBulkResponse = undefined;
  getBulkByStatusList = [];
  cancelScheduleBulkResponse = undefined;
  optoutList = [];
  status = "";
  savedBulkId = undefined;
  savedBulkDetails = undefined;
  updateSavedBulkResponse = undefined;
  updateBulk = false;
  editBulkResponse = undefined;
  costOfCampaign = undefined;
  cancelScheduleData = undefined;

  setCampaignData = campaign => {
    runInAction(() => {
      this.campaign = campaign ? JSON.parse(JSON.stringify(campaign)) : undefined;
    });
  };

  setUpdate = update => {
    runInAction(() => {
      this.updateBulk = update;
    });
  };

  getExportBulkLogList = async params => {
    const exportBulkLogListResponse = await getExportBulkLogList(params);
    try {
      runInAction(() => {
        this.exportBulkLogList = exportBulkLogListResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    } finally {
      return this.exportBulkLogList;
    }
  };

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setStatus = status => {
    runInAction(() => {
      this.status = status;
    });
  };
  setPageSize = size => {
    runInAction(() => {
      this.pageSize = size;
      this.current = 1;
    });
  };

  setClick = click => {
    runInAction(() => {
      this.click = click;
      this.current = 1;
    });
  };

  sendBatchMessage = async data => {
    const response = await sendBatchMessage(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  /**
   * get all Bulks
   * @returns {Promise<[]>}
   */
  getAllBulkList = async args => {
    const params = {
      page: args ? this.current + args : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    const bulkListResponse = await getAllBulks(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = bulkListResponse;
        this.bulkList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.bulkList;
  };

  getBulkMessages = async (bulkId, args) => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };
    const getBulkMessagesResponse = await getBulkMessages(bulkId, params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBulkMessagesResponse.data;
        this.messagesList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.messagesList;
  };

  getBulkShortUrlAnalytics = async (bulkId, args) => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };

    params.clickstatus = this.click;
    const getBulkShortUrlAnalyticsResponse = await getBulkShortUrlAnalytics(bulkId, params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBulkShortUrlAnalyticsResponse.data;
        this.clicksList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.clicksList;
  };

  getAllSavedBulks = async args => {
    const params = {
      page: args ? this.current + args : this.current,
      limit: this.pageSize,
      camp_type: "sms",
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    const bulkListResponse = await getAllSavedBulks(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = bulkListResponse;
        this.bulkList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.bulkList;
  };

  saveBulkData = async bulkData => {
    const bulkDataResponse = await saveBulkData(bulkData);
    try {
      runInAction(() => {
        this.addBulkResponse = bulkDataResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addBulkResponse = error;
      });
    }
    return this.addBulkResponse;
  };

  setSavedBulkId = id => {
    runInAction(() => {
      this.savedBulkId = id;
    });
  };

  getSavedBulkById = async id => {
    const savedBulkByIdResponse = await getSavedBulkById(id);
    try {
      runInAction(() => {
        this.savedBulkDetails = savedBulkByIdResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.savedBulkDetails;
  };

  updateSavedBulk = async (id, bulkData) => {
    const updateSavedBulkResponse = await updateSavedBulk(id, bulkData);
    try {
      runInAction(() => {
        this.updateSavedBulkResponse = updateSavedBulkResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.updateSavedBulkResponse;
  };

  deleteSavedBulk = async id => {
    const deleteSavedBulkResponse = await deleteSavedBulk(id);
    try {
      runInAction(() => {
        this.updateSavedBulkResponse = deleteSavedBulkResponse;
        this.getAllSavedBulks();
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.updateSavedBulkResponse;
  };

  getBulkDetails = async bulk => {
    const bulkDetailResponse = await getBulkDetails(bulk);
    try {
      runInAction(() => {
        this.bulkDetails = bulkDetailResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.bulkDetails;
  };

  getBulkDetailsById = async id => {
    const bulkDetailResponse = await getBulkDetailsById(id);
    try {
      runInAction(() => {
        this.campaignReport = bulkDetailResponse[0];
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.campaignReport;
  };

  /**
   * add new Bulk
   * @param bulkData
   * @returns {Promise<*>}
   */
  addBulk = async (bulkData, mode) => {
    // call api for add new Bulk
    const method = bulkData?.group_array ? addBulkForMultiGroup : addBulk;
    const addBulkResponse = await method(bulkData, mode);
    try {
      runInAction(() => {
        this.addBulkResponse = addBulkResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addBulkResponse = error;
      });
    }
    return this.addBulkResponse;
  };

  editBulk = async (editData, mode) => {
    const editBulkResponse = await editBulk(editData, mode);

    try {
      runInAction(() => {
        if (editBulkResponse.data) {
          this.editBulkResponse = editBulkResponse;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.editBulkResponse = error;
      });
    }
  };

  addBulkByList = async (bulkData, mode) => {
    // call api for add new Bulk
    const addBulkResponse = await addBulkByList(bulkData, mode);
    try {
      runInAction(() => {
        this.addBulkResponse = addBulkResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addBulkResponse = error;
      });
    }
    return this.addBulkResponse;
  };

  resendFailedBulk = async failedBulkId => {
    const resendBulkResponse = await resendFailedBulk(failedBulkId);
    try {
      runInAction(() => {
        if (resendBulkResponse.data) {
          this.resendBulkResponse = resendBulkResponse;
        }
      });
      this.getAllBulkList();
    } catch (error) {
      runInAction(() => {
        this.resendBulkResponse = error;
      });
    }
    return this.resendBulkResponse;
  };

  /**
   * select Bulk Data for Update
   * @param bulkData
   */
  selectBulk = bulkData => {
    this.selectedBulk = bulkData ? JSON.parse(JSON.stringify(bulkData)) : undefined;
  };

  renameBulk = async (id, data) => {
    const renameBulkResponse = await renameBulk(id, data);
    try {
      runInAction(() => {
        this.renameBulkResponse = renameBulkResponse;
      });
      this.getAllBulkList();
    } catch (error) {
      runInAction(() => {
        this.renameBulkResponse = error;
      });
    }
    return this.renameBulkResponse;
  };

  getBulkByStatus = async (key, isAdd) => {
    const params = {
      page: isAdd ? this.current + isAdd : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search) params.search = this.search;
    const getBulkByStatusListResponse = await getBulkByStatus(key, params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = getBulkByStatusListResponse.data;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || getBulkByStatusListResponse), "_id") : _.uniq(items || getBulkByStatusListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.getList;
  };

  getBulkListForApprovalOfUser = async (isAdd, args) => {
    const params = {
      page: isAdd ? this.current + isAdd : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      camp_type: "sms",
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    const getBulkApprovalListResponse = await getBulkListForApprovalOfUser(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = getBulkApprovalListResponse;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || getBulkApprovalListResponse), "_id") : _.uniq(items || getBulkApprovalListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.bulkList;
  };

  getScheduledSMSBulkList = async isAdd => {
    const params = {
      page: isAdd ? this.current + isAdd : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      camp_type: "sms"
    };

    if (this.search) params.search = this.search;
    if (this.status && this.status !== "all") params.status = this.status;

    const scheduledSMSBulkList = await getScheduledSMSBulkList(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = scheduledSMSBulkList;
        this.bulkList = isAdd ? _.uniq(_.concat(mobx.toJS(this.bulkList), items || scheduledSMSBulkList), "_id") : _.uniq(items || scheduledSMSBulkList, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedBulk = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.bulkList = error;
      });
    }
    return this.bulkList;
  };

  updateScheduleBulk = async data => {
    const response = await updateScheduleBulk(data);
    try {
      runInAction(() => {
        this.updateSavedBulkResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.updateSavedBulkResponse = error;
      });
    }
    return this.updateSavedBulkResponse;
  };

  getCostOfCampaign = async (count, isMms) => {
    let params = {};
    if (isMms) {
      params = { totalMMS: count };
    } else {
      params = { totalSMS: count };
    }
    const response = await getCostOfCampaign(params);
    try {
      runInAction(() => {
        this.costOfCampaign = response;
      });
    } catch (error) {
      runInAction(() => {
        this.costOfCampaign = error;
      });
    }
    return this.costOfCampaign;
  };

  cancelScheduleBulk = async id => {
    const response = await cancelScheduleBulk(id);
    try {
      runInAction(() => {
        this.cancelScheduleBulkResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.cancelScheduleBulkResponse = error;
      });
    }
    return this.cancelScheduleBulkResponse;
  };

  getCampaignDetails = async id => {
    const response = await getCampaignDetails(id);
    try {
      runInAction(() => {
        this.selectedBulk = response.data;
      });
    } catch (error) {
      runInAction(() => {
        this.selectedBulk = error;
      });
    }
    return this.selectedBulk;
  };

  exportClicksAnalytics = async params => {
    const response = await exportClicksAnalytics(params);
    try {
      runInAction(() => {
        this.exportBulkLogList = response;
      });
    } catch (error) {
      runInAction(() => {
        this.exportBulkLogList = error;
      });
    }
    return this.exportBulkLogList;
  };

  getOptoutList = async (id, params) => {
    const response = await getOptoutList(id, params);
    console.log(response);
    try {
      runInAction(() => {
        this.optoutList = response;
      });
    } catch (error) {
      runInAction(() => {
        this.optoutList = error;
      });
    }

    return this.optoutList;
  };

  cancelRecurranceSchedule = async params => {
    const response = await cancelSchedule(params);
    console.log(response);
    try {
      runInAction(() => {
        this.cancelScheduleData = response;
      });
    } catch (error) {
      runInAction(() => {
        this.cancelScheduleData = error;
      });
    }

    return this.cancelScheduleData;
  };
}

decorate(ObservableGatewayBulkStore, {
  bulkList: observable,
  messagesList: observable,
  clicksList: observable,
  bulkDetails: observable,
  campaignReport: observable,
  exportBulkLogList: observable,
  addBulkResponse: observable,
  updateBulkResponse: observable,
  resendBulkResponse: observable,
  selectedBulk: observable,
  deleteBulkResponse: observable,
  click: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  totalPages: observable,
  campaign: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  renameBulkResponse: observable,
  getBulkByStatusList: observable,
  savedBulkId: observable,
  savedBulkDetails: observable,
  updateSavedBulkResponse: observable,
  cancelScheduleBulkResponse: observable,
  updateBulk: observable,
  costOfCampaign: observable,
  optoutList: observable,
  editBulkResponse: observable,
  cancelScheduleData: observable,
  status: observable
});
export default new ObservableGatewayBulkStore();
