import React from "react";
import AppButton from "components/AppButton/AppButton";
import { Link } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import useInternetStatus from "hooks/useInternetStatus";

function ErrorBoundaryFallback() {
  const internetStatus = useInternetStatus();

  if (!internetStatus) {
    return (
      <div className="container-fluid vh-center flex-column">
        <div className="bg-offline d-flex justify-content-center align-items-center"></div>
        <div className="text-center">
          <p className="fw-bold h3">No Internet Connection</p>
          <div className="text-start mb-5">
            <i className="bx bxs-check-circle align-middle me-2 h5 m-0 text-secondary"></i>
            Check your modem or router
            <br />
            <i className="bx bxs-check-circle align-middle me-2 h5 m-0 text-secondary"></i>
            Reconnet to Wi-Fi
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-75 w-100 d-flex justify-content-center align-items-center text-center">
      <div>
        <WarningOutlined style={{ fontSize: "xxx-large", color: "var(--main-color)" }} />
        <h5 className="text-secondary my-4">Oops! Something went wrong</h5>
        <div>
          <AppButton
            light
            onClick={() => {
              window.location.reload();
            }}
            label="Reload"
            className="me-2"
          />
          <Link to="/">
            <AppButton className="ms-2" withoutBg light label="Dashboard" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ErrorBoundaryFallback;
