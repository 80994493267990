import React, { useState } from "react";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import TestSMS from "../Modals/TestSMS";
import { message as notification } from "antd";
import AlertModal from "components/Modals/AlertModal";
import clsx from "clsx";
import { optOutMessageSample, replyStopMessageSample } from "constant/messageConstants";
import { isEmpty } from "lodash";

function SendTestSMS(props) {
  const {
    modalStore,
    gatewayStore,
    gatewayMmsStore,
    btnProps = {},
    isMms,
    name = "message",
    label = "Send Test Message",
    size = "large",
    isFirstTime,
    setIsFirstTime,
    values,
    quickMessage = false,
    skip = false,
    saveAndContinue,
    setFieldValue
  } = props;
  const [successAlert, setSuccessAlert] = useState(false);

  function toggleModal(show = true) {
    modalStore.toggleModal("showTestSMSModal", show);
  }

  function handleClick() {
    if (!values[name]) {
      notification.warn("Please Enter a message", 3);
      return;
    } else if (!values.sender) {
      notification.warn("Please Choose a sender", 3);
      return;
    }
    let media = values.media ? true : false;
    let url = values.url ? true : false;
    if (isMms && !(media ^ url)) {
      notification.warn("Please Add a file", 3);
      modalStore.toggleModal("showAttachFileModal", true);
      return;
    }
    toggleModal(true);
  }

  async function sendGatewayMessage(data) {
    let newData = { ...data };

    newData.contacts = newData.contacts.filter(x => x);
    newData.message = data[name];
    if (data[name] && values.optout && !data.message.includes(optOutMessageSample)) newData.message = `${data[name]}\n${optOutMessageSample}`;
    if (data[name] && values.replyStopToOptOut && !data.message.includes(replyStopMessageSample)) newData.message = `${data[name]}\n${replyStopMessageSample}`;
    // if (data.group) newData.groupid = data.group;

    if (!isEmpty(newData?.groupObj)) {
      newData.groupid = Array.isArray(newData?.groupObj) ? newData?.group[0] : newData?.group;
    }

    delete newData.groupObj;
    delete newData.group;

    if (isMms) {
      if (!newData.media) delete newData.media;
      if (!newData.url) delete newData.url;
      let formData = new FormData();
      Object.entries(newData).forEach(([key, value]) => {
        if (!["contacts"].includes(key)) formData.append(key, value);
      });
      formData.append("contacts", JSON.stringify(newData.contacts));
      formData.append("bucket", "general");
      formData.append("category", "group");
      return gatewayMmsStore.addGatewayMMS(formData).then(res => {
        if (res.status) {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
          }, 3000);
          toggleModal(false);
          isFirstTime && setIsFirstTime(false);
        }
      });
    } else {
      return gatewayStore.addGatewaySMS(newData).then(res => {
        if (res.status) {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
          }, 3000);
          toggleModal(false);
          isFirstTime && setIsFirstTime(false);
        }
      });
    }
  }
  return (
    <>
      <AppButton
        light
        withoutBg={quickMessage || !isFirstTime}
        className={clsx({
          "text-decoration-underline text-info-color border-0 bg-light": quickMessage
        })}
        label={label}
        onClick={handleClick}
        size={size}
        {...btnProps}
      />
      {!isFirstTime && !quickMessage && <AppButton light label="Next" size={size} onClick={saveAndContinue} />}
      <TestSMS
        isMms={isMms}
        isVisible={modalStore.showTestSMSModal}
        handleCancel={() => toggleModal(false)}
        sendGatewayMessage={sendGatewayMessage}
        skip={skip}
        isFirstTime={isFirstTime}
        values={values}
        name={name}
        saveAndContinue={saveAndContinue}
        setFieldValue={setFieldValue}
        quickMessage={quickMessage}
      />
      <AlertModal
        visible={successAlert}
        title="Message sent!"
        description="Your test message has been sent successfully"
        primaryBtnProps={{ onClick: () => setSuccessAlert(false), label: "Ok" }}
        closable
        onCancel={() => setSuccessAlert(false)}
      />
    </>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  gatewayStore: stores.store.gatewayStore,
  gatewayMmsStore: stores.store.gatewayMmsStore
}))(observer(SendTestSMS));
