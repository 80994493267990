import React from "react";
import { Menu, Dropdown, Button, Icon } from "antd";
import AppButton from "components/AppButton/AppButton";

const AppDropdown = ({
  value,
  label = "Select",
  options,
  onClick,
  className,
  btnClassName,
  icon = false,
  lastChildComponent = false,
  placement = "bottomCenter",
  style,
  trigger = ["click", "hover"],
  btnProps
}) => {
  const menu = (
    <Menu
      onClick={({ key, domEvent }) => {
        domEvent.stopPropagation();

        onClick(key);
      }}
      selectedKeys={[value]}
      className={className}
      style={style}
    >
      {options &&
        options.map(option => {
          return (
            <Menu.Item key={option.value}>
              {option.icon && <i className={`me-2 ${option.icon}`} />} {option.label}
            </Menu.Item>
          );
        })}
      {lastChildComponent && lastChildComponent}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={trigger} placement={placement}>
        {icon ? <Button type="link">{icon}</Button> : <AppButton label={label} className={btnClassName} {...btnProps} />}
      </Dropdown>
    </>
  );
};

export default AppDropdown;
