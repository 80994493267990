import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { Row, Typography } from "antd";
import AppTable from "components/AppTable/AppTable";
import AppButton from "components/AppButton/AppButton";
import Header from "Mobile/components/Header/Header";
import moment from "moment";
import { dateFormat, timeFormat } from "constant/constant";

const Invoice = props => {
  const { authStore } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (authStore?.user?.role === "trial") return history.push("/");
    authStore.getInvoices();
  }, []);

  if (!authStore.invoices) {
    return <h1>Loading...</h1>;
  }

  const invoices = toJS(authStore.invoices);

  const onChange = e => {
    authStore.setInvoiceDataPage(e);
  };

  const onTableRowExpand = (expended, record) => {
    setExpandedRowKeys(expended ? [record._id] : []);
  };

  function downloadInvoice(url) {
    window.open(url, "_blank");
  }

  const columns = [
    {
      title: (
        <span ellipsis style={{ fontSize: "12px" }}>
          Email
        </span>
      ),
      key: "bill_to",
      render: message => {
        return (
          <span ellipsis style={{ fontSize: "10px" }}>
            {message.bill_to}
          </span>
        );
      },
      ellipsis: true
    },
    {
      title: (
        <span ellipsis style={{ fontSize: "12px" }}>
          Date
        </span>
      ),
      key: "createdAt",
      render: message => {
        return (
          <span ellipsis style={{ fontSize: "10px" }}>
            {moment(message.createdAt).format(dateFormat)}
          </span>
        );
      },
      ellipsis: true
    },
    {
      title: (
        <span ellipsis style={{ fontSize: "12px" }}>
          Amount
        </span>
      ),
      key: "totalAmount",
      render: message => {
        let amount = message.totalAmount / 100 + message.totalAmount / 1000;
        return (
          <span ellipsis style={{ fontSize: "10px" }}>
            $ {amount.toFixed(2)}
          </span>
        );
      },
      ellipsis: true
    }
  ];

  return (
    <>
      <Header headerName="Invoice" />
      <hr />
      <Row>
        {invoices ? (
          <AppTable
            columns={columns}
            dataSource={invoices}
            pagination={{
              current: authStore.invoiceDatacurrent,
              total: authStore.invoiceTotal,
              pageSize: authStore.invoiceDatapageSize,
              onChange: onChange,
              hideOnSinglePage: true,
              showQuickJumper: true
            }}
            secondary
            scroll={{ y: "calc(100vh - 350px)" }}
            rowKey={record => record._id}
            expandRowByClick
            onExpand={onTableRowExpand}
            tableLayout="fixed"
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={record => {
              return (
                <div className="d-flex flex-column justify-content-between">
                  <span className="text-dark" style={{ fontSize: "12px" }}>
                    Invoice Generated at {moment(record.createdAt).format(timeFormat)}
                  </span>
                  <span className="text-dark" style={{ fontSize: "12px" }}>
                    Puschase Type:{" "}
                    {record.payment_type === "SpecialPlan"
                      ? "Special"
                      : record.payment_type === "AutoCredit"
                      ? "Auto Credit"
                      : record.payment_type === "SignupReward"
                      ? "SignUp Reward"
                      : record.payment_type === "Admin"
                      ? "Credits By Admin"
                      : "Normal"}
                  </span>
                  <span className="text-dark" style={{ fontSize: "12px" }}>
                    SMS/MMS: {record.isSMSAllow ? "SMS" : "MMS"}
                  </span>
                  <AppButton light className="mt-3" style={{ fontSize: "10px" }} label="Download" onClick={() => downloadInvoice(record.invoice_pdf)} />
                </div>
              );
            }}
          />
        ) : (
          <div className="card p-4">
            <div className="card-body text-center">
              <h1 className="mt-3">Oops!</h1>
              <h2 className="mt-3">No matching records found!</h2>
            </div>
          </div>
        )}
      </Row>
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore
}))(observer(Invoice));
