import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Col, Typography, List, Skeleton, Spin } from "antd";
import CardIcon from "./CardIcon";
import clsx from "clsx";

const CardSelect = props => {
  const { userStore, values, setFieldValue } = props;
  const { getCards, deleteCard } = userStore;

  const [initLoading, setInitLoading] = useState(false);
  const [cardsList, setCardsList] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [total, setTotal] = useState(0);
  const [cards, setCards] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setInitLoading(true);
    getCards()
      .then(res => {
        if (res.status && res.data.length > 0) {
          setTotal(res.data.length);
          setCards(res.data);
          setCardsList(res.data.slice(0, pageSize));
          setFieldValue("card", res.data[0].id);
        } else {
          setFieldValue("card", "NewCard");
        }
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, []);

  if (initLoading) {
    return (
      <Col span={24}>
        <Skeleton active />
      </Col>
    );
  }

  function checkExprieDate(month, year) {
    let today = new Date();
    let expDate = new Date(year, month);
    return today < expDate;
  }

  function handleActions(key, item) {
    switch (key) {
      case "delete":
        setDeleteLoading(true);
        deleteCard({ card: item.id })
          .then(res => {
            if (res.status) {
              let newCards = cards.filter(card => card.id !== item.id);
              setCards(newCards);
              setTotal(total - 1);
              setCardsList(newCards.slice(0, pageSize));
              if (newCards.length == 0) {
                setFieldValue("card", "NewCard");
              }
            }
          })
          .finally(() => setDeleteLoading(false));
        break;
      default:
        break;
    }
  }

  if (total == 0) {
    return null;
  }

  return (
    <>
      <Col span={24}>
        <List
          style={{
            margin: "0 auto"
          }}
          pagination={{
            onChange: page => {
              setPage(page);
              setCardsList(cards.slice((page - 1) * pageSize, page * pageSize));
            },
            defaultCurrent: 1,
            current: page,
            total: total,
            pageSize: pageSize,
            showSizeChanger: false,
            hideOnSinglePage: true
          }}
          loading={initLoading}
          itemLayout="horizontal"
          dataSource={cardsList}
          renderItem={item => (
            <List.Item
              onClick={() => {
                if (checkExprieDate(item.exp_month, item.exp_year)) {
                  setFieldValue("card", item.id);
                  setFieldValue("name", item.name);
                  setFieldValue("city", item.country);
                  setFieldValue("token", undefined);
                  setFieldValue("source", undefined);
                  setFieldValue("isNewCard", false);
                }
              }}
              style={{
                cursor: checkExprieDate(item.exp_month, item.exp_year) ? "pointer" : "inherit"
              }}
              className={`rounded-3 mb-3 px-2 bg-white ${
                values.card === item.id && !values?.isNewCard ? "border-info-color" : !checkExprieDate(item.exp_month, item.exp_year) ? "border-danger-color" : "border-muted-color"
              }`}
              actions={[
                <>
                  {deleteLoading && values.card == item.id ? (
                    <Spin />
                  ) : (
                    <i className={clsx("fas fa-trash-alt fs-6 pe-auto text-danger-color", { "pe-none": deleteLoading })} type="button" onClick={() => handleActions("delete", item)} />
                  )}
                </>
              ]}
            >
              <List.Item.Meta
                className="align-items-center"
                avatar={<CardIcon brand={item.brand.toLowerCase()} />}
                title={
                  <Typography.Text strong className="fs-5 ms-2 ms-md-5">
                    **** {item.last4}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        />
      </Col>
      <Col span={24}>
        <Typography.Title className="fs-5 text-center text-grey" level={4}>
          OR
        </Typography.Title>
      </Col>
    </>
  );
};

export default inject(stores => ({
  userStore: stores.store.userStore,
  authStore: stores.store.authStore
}))(observer(CardSelect));
