import { get, post } from "utils/api";

/**
 * Call get all bulk api
 * @returns {Promise<any>}
 */
export const getAllBulks = async params => {
  // save api response for Bulk List list
  const BulkListResponse = await get("/bulk", params);
  return BulkListResponse.data;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
export const addBulk = async (bulkData = {}, mode = "") => {
  const addResponse = await post(`/message/bulk?mode=${mode}`, bulkData);
  return addResponse.data;
};

export const getBulkDetails = async (bulkData = {}) => {
  const addResponse = await get(`/message/bulk/${bulkData._id}`);
  return addResponse.data;
};

export const resendFailedBulk = async (failedBulkId = {}) => {
  const resendResponse = await get(`/message/bulk/resend/${failedBulkId}`);
  return resendResponse.data;
};
