import { get, post, put, deleteRequest } from "utils/api";

export const getAllowedEmails = async () => {
  const response = await get("/emailmessage");
  return response.data;
};

export const add = async data => {
  const response = await post("/emailmessage", data);
  return response.data;
};
export const remove = async data => {
  const response = await deleteRequest("/emailmessage", data);
  return response.data;
};
