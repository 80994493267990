import React from "react";
import { Card, Modal, Row, Col } from "antd";
import { UsergroupAddOutlined, UserAddOutlined } from "@ant-design/icons";
import AppButton from "components/AppButton/AppButton";
import styles from "assets/css/modals.module.css";
import { inject, observer } from "mobx-react";

const AddContact = props => {
  const { groupStore, modalStore } = props;

  const showQuickContactModal = () => {
    modalStore.toggleModal("showAddContactsModal", false);
    modalStore.toggleModal("showQuickContactModal", true);
  };

  const showImportContactsModal = () => {
    groupStore.setGroupData({ name: groupStore.group.name });
    groupStore.setResponse("quickGroup", false);
    modalStore.toggleModal("importContactsModal", true);
    // modalStore.toggleModal("showImportContactsModal", true);
    modalStore.toggleModal("showAddContactsModal", false);
  };

  return (
    <>
      <Modal
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Add Contacts {groupStore.group && `To ${groupStore.group.name}`}</h4>
            <p className={`mb-0 ${styles.modalDes}`}>Add Contacts to Existing Group</p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showAddContactsModal}
        onCancel={() => modalStore.toggleModal("showAddContactsModal", false)}
        width={650}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <Row gutter={32} className="py-4">
          <Col md={2} />
          <Col md={10}>
            <Card className="border-0 shadow rounded-lg">
              <div className="justify-content-center d-flex flex-column">
                <UserAddOutlined className="fs-1 text-info-color" />
                <AppButton label="Add Single Contact" className="mt-3 w-100" light onClick={showQuickContactModal} />
              </div>
            </Card>
          </Col>
          <Col md={10}>
            <Card className="border-0 shadow rounded-lg">
              <div className="justify-content-center d-flex flex-column">
                <UsergroupAddOutlined className="fs-1 text-info-color" />
                <AppButton label="Add Bulk Contact" className="mt-3 w-100" light onClick={showImportContactsModal} />
              </div>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
      </Modal>
    </>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  modalStore: stores.store.modalStore
}))(observer(AddContact));
