import moment from "moment";
//30 days expiry time
const set = (name, value, expire = 30 * 24 * 3600) => {
  localStorage.setItem(
    name,
    JSON.stringify({
      value: value,
      expires: moment()
        .add(expire, "seconds")
        .format()
    })
  );
};

const get = name => {
  const itemStr = localStorage.getItem(name);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  if (moment().format() > item.expires) {
    localStorage.removeItem(name);
    return null;
  }
  return item.value;
};

const erase = name => {
  localStorage.removeItem(name);
};

export const cookie = {
  get,
  set,
  erase
};
