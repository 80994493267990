import React from "react";
import ImageCropModal from "./Modals/ImageCropModal";
import VideoTrimmerModal from "./Modals/VideoTrimmerModal";

const View = () => {
  return (
    <React.Fragment>
      <ImageCropModal />
      <VideoTrimmerModal />
    </React.Fragment>
  );
};

export default View;
