import React from "react";
import moment from "moment";
import { Calendar, Select, Row, Col, Typography } from "antd";

const btnStyle = {
  border: "none",
  background: " var(--main-color)",
  borderRadius: "3px",
  height: "30px",
  padding: "0px 10px",
  color: "white"
};

const AppCalendar = props => {
  const { value = moment(), onChange, onSelect, style, className } = props;
  return (
    <Calendar
      value={value}
      fullscreen={false}
      headerRender={({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        const yearOptions = [];
        const current = value.clone();
        const valMonth = value.month();
        const valYear = value.year();
        const localeData = value.localeData();
        const month = moment().month();
        const year = moment().year();
        const date = moment().date();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }
        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option className="month-item" key={`${index}`} disabled={valYear === year && index < month}>
              {months[index]}
            </Select.Option>
          );
        }
        for (let i = year; i < year + 10; i += 1) {
          yearOptions.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }
        return (
          <div className="p-2">
            <Row gutter={[10, 10]} type="flex" align="bottom">
              <Col span={15} className="d-flex gap-2">
                <Select
                  size="default"
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                  value={String(valMonth)}
                  onChange={selectedMonth => {
                    const newValue = value.clone();
                    newValue.month(parseInt(selectedMonth, 10));
                    onChange(newValue);
                  }}
                  showArrow={false}
                >
                  {monthOptions}
                </Select>
                <Select
                  bordered={false}
                  size="default"
                  dropdownMatchSelectWidth={false}
                  className="my-year-select"
                  onChange={newYear => {
                    let now = value.clone().year(newYear);
                    if (newYear === year) {
                      now = now.month(month).date(date);
                    }
                    onChange(now);
                  }}
                  value={String(valYear)}
                  showArrow={false}
                >
                  {yearOptions}
                </Select>
              </Col>
              <Col span={9} className="d-flex justify-content-end gap-2">
                <button
                  id="previous"
                  type="button"
                  onClick={() => {
                    const newValue = value.clone();
                    onChange(newValue.subtract(1, "month"));
                  }}
                  disabled={valYear === year && month === valMonth}
                  style={btnStyle}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                <button
                  id="next"
                  type="button"
                  onClick={() => {
                    const newValue = value.clone();
                    onChange(newValue.add(1, "month"));
                  }}
                  style={btnStyle}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </Col>
            </Row>
          </div>
        );
      }}
      disabledDate={current => {
        return (
          current &&
          moment(current) <
            moment()
              .subtract(1, "days")
              .endOf("day")
        );
      }}
      onChange={onChange}
      onSelect={onSelect}
      style={style}
      className={className}
      validRange={[moment(), moment().add(10, "years")]}
    />
  );
};

export default AppCalendar;
