import { runInAction, decorate, observable } from "mobx";
import { getAllFileSchedulers, addFileScheduler, deleteFileScheduler, updateFileScheduler } from "service/fileSchedulerService";
import { updateSchedulerStatus } from "service/schedulerService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableFileSchedulerStore {
  updateSchedulerStatusResponse = undefined;
  fileSchedulerList = [];
  addFileSchedulerResponse = undefined;
  updateSchedulerResponse = undefined;
  selectedFileScheduler = undefined;
  deleteSchedulerResponse = undefined;
  pageSize = 10;
  current = 1;
  totalPages = 0;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  search = "";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.getAllFileSchedulerList();
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
    this.getAllFileSchedulerList();
  };

  /**
   * get all Schedulers
   * @returns {Promise<[]>}
   */
  getAllFileSchedulerList = async isAdd => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search) params.search = this.search;
    // call & save Scheduler list response
    const tempSelectedFileSchedulerId = this.selectedFileScheduler ? this.selectedFileScheduler._id : undefined;
    const fileSchedulerListResponse = await getAllFileSchedulers(params);
    console.log(fileSchedulerListResponse);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = fileSchedulerListResponse;
        this.fileSchedulerList = isAdd ? _.uniq(_.concat(mobx.toJS(this.fileSchedulerList), items || fileSchedulerListResponse), "_id") : _.uniq(items || fileSchedulerListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedFileScheduler = undefined;
        if (tempSelectedFileSchedulerId) {
          this.selectedFileScheduler = this.fileSchedulerList.find(x => x._id == tempSelectedFileSchedulerId);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.fileSchedulerList;
  };

  /**
   * add new Scheduler
   * @param schedulerData
   * @returns {Promise<*>}
   */
  addFileScheduler = async fileSchedulerData => {
    // call api for add new Scheduler
    const addFileSchedulerResponse = await addFileScheduler(fileSchedulerData);
    try {
      runInAction(() => {
        this.addFileSchedulerResponse = addFileSchedulerResponse;
      });
      this.getAllFileSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.addFileSchedulerResponse = error;
      });
    }
    return this.addFileSchedulerResponse;
  };

  /**
   * select Scheduler Data for Update
   * @param schedulerData
   */
  selectFileScheduler = fileSchedulerData => {
    this.selectedFileScheduler = fileSchedulerData ? JSON.parse(JSON.stringify(fileSchedulerData)) : undefined;
  };

  /**
   * updates Scheduler Data
   * @param schedulerData
   * @param schedulerId
   * @returns {Promise<void>}
   */
  updateScheduler = async (schedulerData, schedulerId) => {
    // call api for update existing Scheduler
    const updateSchedulerResponse = await updateScheduler(schedulerData, schedulerId);
    try {
      runInAction(() => {
        this.updateSchedulerResponse = updateSchedulerResponse;
        this.selectedFileScheduler = undefined;
      });
      this.getAllSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.updateSchedulerResponse = error;
      });
    }
    return this.updateSchedulerResponse;
  };

  /**
   * Delete Scheduler
   * @param schedulerId
   * @returns {Promise<void>}
   */
  deleteFileScheduler = async fileSchedulerId => {
    const deleteFileSchedulerResponse = await deleteFileScheduler(fileSchedulerId);
    await this.getAllFileSchedulerList();
    try {
      runInAction(() => {
        this.deleteSchedulerResponse = deleteSchedulerResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.deleteSchedulerResponse = error;
      });
    }
    return this.deleteSchedulerResponse;
  };

  updateSchedulerStatus = async schedulerId => {
    const updateSchedulerStatusResponse = await updateSchedulerStatus(schedulerId);
    try {
      await this.getAllFileSchedulerList();
      runInAction(() => {});
    } catch (error) {
      runInAction(() => {
        this.updateSchedulerStatusResponse = error;
      });
    }
    return updateSchedulerStatusResponse;
  };
}

decorate(ObservableFileSchedulerStore, {
  updateSchedulerStatusResponse: observable,
  fileSchedulerList: observable,
  addFileSchedulerResponse: observable,
  updateSchedulerResponse: observable,
  selectedFileScheduler: observable,
  deleteSchedulerResponse: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable
});
export default new ObservableFileSchedulerStore();
