import { runInAction, decorate, observable } from "mobx";
import { getAllCategory, addCategory, deleteCategory, updateCategory } from "service/categoryService";
import _ from "lodash";

class ObservableCategoryStore {
  categoryList = [];
  categoryResponse = undefined;
  addCategoryRespone = undefined;
  categoryId = "";
  categoryName = "";

  setCategoryId = categoryId => {
    runInAction(() => {
      this.categoryId = categoryId;
    });
  };

  setCategoryName = categoryName => {
    runInAction(() => {
      this.categoryName = categoryName;
    });
  };

  getAllCategoryList = async () => {
    const items = await getAllCategory();
    try {
      runInAction(() => {
        this.categoryList = items;
      });
    } catch (error) {
      runInAction(() => {
        this.categoryList = "error";
      });
    }
    return this.categoryList;
  };

  addCategory = async categoryName => {
    const addCategoryRespone = await addCategory(categoryName);

    try {
      runInAction(() => {
        this.addCategoryResponse = addCategoryRespone;
        this.getAllCategoryList();
      });
    } catch (error) {
      runInAction(() => {
        this.addCategoryResponse = "error";
      });
    }
    return this.addCategoryResponse;
  };

  deleteCategory = async id => {
    const items = await deleteCategory(id);
    try {
      runInAction(() => {
        this.categoryResponse = items;
        this.getAllCategoryList();
      });
    } catch (error) {
      runInAction(() => {
        this.categoryResponse = "error";
      });
    }
    return this.categoryResponse;
  };

  updateCategory = async (id, name) => {
    const items = await updateCategory(id, name);
    try {
      runInAction(() => {
        this.categoryResponse = items;
        this.getAllCategoryList();
      });
    } catch (error) {
      runInAction(() => {
        this.categoryResponse = "error";
      });
    }
    return this.categoryResponse;
  };
}

decorate(ObservableCategoryStore, {
  categoryList: observable,
  categoryResponse: observable,
  addCategoryRespone: observable,
  categoryId: observable,
  categoryName: observable
});

export default new ObservableCategoryStore();
