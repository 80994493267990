import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { Radio } from "antd";
import SenderIDOption from "./SenderIDOption";
import styles from "./sender-id.module.css";
import AppLoader from "components/AppLoader/Loader";
import { getFormatedContact } from "constant/messageConstants";
import { sharedNumber } from "constant/constant";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px"
};

const SenderID = ({ className, label, authStore, setFieldValue, values, error, messageStore, touched, campaign = false, isMms = false, smallSize = false, personalNumberStore }) => {
  const { user } = authStore;
  const { personalNumberList, getPersonalNumbers } = personalNumberStore;

  const [personalSenderOptions, setPersonalSenderOptions] = useState([]);

  useEffect(() => {
    getPersonalNumbers();
  }, []);

  useEffect(() => {
    setPersonalSenderOptions(
      personalNumberList
        .filter(x => x.status === "approved")
        .map(_ => ({
          value: _.number,
          label: `${_.number} (${_?.reference})`
        }))
    );
  }, [personalNumberList]);

  useEffect(() => {
    if (values?.sender === sharedNumber) {
      setFieldValue("senders", "1");
    } else if (user?.personalSenders.includes(values?.sender)) {
      setFieldValue("senders", "4");
    } else if (user?.mobileSenders.includes(values?.sender)) {
      setFieldValue("senders", "2");
    } else if (user?.senders.includes(values?.sender)) {
      setFieldValue("senders", "3");
    }
  }, [values?.sender]);

  if (!user) {
    return (
      <div style={{ height: "97px" }}>
        <AppLoader />
      </div>
    );
  }

  const dedicatedNumbers = user?.mobileSenders.map((sender, i) => ({
    value: sender,
    label: sender
  }));

  const businessNames = user?.senders.map((sender, i) => ({
    value: sender,
    label: sender
  }));

  // const personalNumbers = [user?.mobile, ...user?.personalSenders]
  //   .filter(Boolean)
  //   .map((sender, i) => ({
  //     value: sender,
  //     label: getFormatedContact(sender)
  //   }));

  // useEffect(() => {
  //   setPersonalSenderOptions(
  //     // user?.personalSenders
  //     // ? [user?.mobile, ...user?.personalSenders]
  //     //     .filter(x => x)
  //     //     .map((sender, i) => ({
  //     //       value: sender,
  //     //       label: getFormatedContact(sender)
  //     //     }))
  //     // : [user?.mobile].map((sender, i) => ({
  //     //     value: sender,
  //     //     label: getFormatedContact(sender)
  //     //   }))
  //     user?.personalSenders
  //       ? user?.personalSenders
  //           .filter(x => x)
  //           .map((sender, i) => ({
  //             value: sender,
  //             label: getFormatedContact(sender)
  //           }))
  //       : []
  //   );
  // }, [user]);

  let Options = [
    {
      value: "1",
      label: "Use Shared Number",
      optType: "replyStopToOptOut"
    },
    {
      value: "2",
      label: `Your Dedicated Mobile Number${!smallSize ? " - Replies Will Be Sent To You As An SMS" : ""}`,
      placeholder: "Select your dedicated Number",
      options: dedicatedNumbers,
      optType: "replyStopToOptOut",
      disabled: dedicatedNumbers.length === 0
    }
  ];

  if (!isMms) {
    Options = Options.concat([
      {
        value: "3",
        label: "Your Business Name",
        placeholder: "Select your business name",
        options: businessNames,
        optType: "optout",
        disabled: businessNames.length === 0
      }
    ]);
  }

  if ((user.mobile || user.personalSenders?.length > 0) && !isMms) {
    Options = Options.concat([
      {
        value: "4",
        label: "Your Personal Number",
        placeholder: "Select your personal number",
        options: personalSenderOptions,
        optType: "optout",
        disabled: personalSenderOptions.length === 0
      }
    ]);
  }

  return (
    <>
      <div className={`${className} ${styles.senderId}`}>
        {label && label}
        <Radio.Group
          onChange={e => {
            setFieldValue("senders", e.target.value);
            setFieldValue("optType", e.target.optType);
            if (e.target.value === "1") {
              setFieldValue("sender", sharedNumber);
              messageStore.setPhoneSender(sharedNumber);
            } else {
              setFieldValue("sender", "");
              messageStore.setPhoneSender("------");
            }
          }}
          value={values?.senders}
          className="w-100"
        >
          {Options.map((option, index) => (
            <SenderIDOption
              style={radioStyle}
              isSelected={values?.senders}
              index={index}
              key={index}
              label={option.label}
              value={option.value}
              placeholder={option.placeholder}
              options={option.options}
              optType={option.optType}
              setFieldValue={setFieldValue}
              values={values}
              smallSize={smallSize}
              campaign={campaign}
              disabled={option.disabled}
            />
          ))}
        </Radio.Group>
        {error && touched && <div className="text-danger-color">{error}</div>}
      </div>
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  messageStore: stores.store.messageStore,
  personalNumberStore: stores.store.personalNumberStore
}))(observer(SenderID));
