import React from "react";
import styles from "./app-btn.module.css";
import { Button, Icon } from "antd";

function AppButton({
  label,
  onClick,
  type = "button",
  light = false,
  withoutBg = false,
  className,
  prefixIcon,
  size = "large",
  postfixIcon,
  center,
  groupBtn = false,
  authBtn,
  isSubmitting = false,
  endProps,
  groupBtnConfig = {
    firstOnClick: () => {},
    firstBtnType: "button",
    secondOnClick: () => {}
  },
  ...rest
}) {
  let finalClassNames = "btn text-nowrap";
  if (light) {
    //light mode
    if (withoutBg) {
      finalClassNames += ` ${styles.btnLightWithoutBg}`;
    } else {
      finalClassNames += ` ${styles.btnLightWithBg}`;
    }
  } else if (authBtn) {
    finalClassNames += ` ${styles.btnAuth}`;
  } else {
    //dark mode
    if (withoutBg) {
      finalClassNames += ` ${styles.btnDarkWithoutBg}`;
    } else {
      finalClassNames += ` ${styles.btnDarkWithBg}`;
    }
  }

  // size
  if (size === "small") {
    finalClassNames += ` ${styles.btnSmall}`;
  }
  if (size === "default") {
    finalClassNames += ` ${styles.btnDefault}`;
  }

  //custom classes
  if (className) {
    finalClassNames += ` rounded ${className}`;
  }
  if (center) {
    finalClassNames += " d-block mx-auto";
  }

  const iconStyle = {
    fontSize: "14px",
    verticalAlign: "middle",
    marginLeft: "8px"
  };

  if (groupBtn) {
    return (
      <Button.Group>
        <button className={`${finalClassNames} rounded-end-0 rounded-e-0 border-end-0 `} onClick={groupBtnConfig?.firstOnClick} type={groupBtnConfig.firstBtnType} {...rest}>
          {prefixIcon && prefixIcon}
        </button>
        <button className={`btn text-nowrap ${styles.btnDarkWithoutBg} rounded-start-0 rounded-s-0 border-start py-2`} onClick={groupBtnConfig?.secondOnClick} type={type} {...rest}>
          {postfixIcon && postfixIcon}
        </button>
      </Button.Group>
    );
  } else {
    return (
      <>
        <button className={finalClassNames} onClick={onClick} type={type} {...rest}>
          {!isSubmitting && prefixIcon && prefixIcon}
          {label}
          {!isSubmitting && postfixIcon && postfixIcon}
          {isSubmitting && <Icon type="loading" style={iconStyle} />}
        </button>
        {endProps && endProps}
      </>
    );
  }
}

export default AppButton;
