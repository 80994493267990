import SocialLogins from "components/Authentication/Common/SocialLogins";
import AppButton from "components/AppButton/AppButton";
import { Col, Row } from "antd";
import React from "react";

const SocialOptions = props => {
  const { hideShowUpgrade } = props;

  return (
    <Row className="my-3 w-50 mx-auto">
      <Col span={24} className="text-center">
        <AppButton
          light
          label="Continue with Email"
          withoutBg
          style={{ width: "245px" }}
          className="text-dark border-muted-color rounded-pill py-2 bg-white"
          prefixIcon={<i className="fas fa-envelope me-2" />}
          onClick={() => hideShowUpgrade(3)}
        />
      </Col>
      <Col span={24} className="d-flex flex-row mt-2">
        <div className="flex-grow-1">
          <hr />
        </div>
        <div className="mx-3 d-flex align-items-center">OR</div>
        <div className="flex-grow-1">
          <hr />
        </div>
      </Col>
      <SocialLogins isProfileSigup={true} />
    </Row>
  );
};

export default SocialOptions;
