import React from "react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import View from "components/TargetGroup/View";
import { Form, Formik } from "formik";
import AppButton from "components/AppButton/AppButton";

function Groups(props) {
  const {
    isVisible,
    hideAddGroupButton,
    groupViewProps = {},
    btnLabel = "Select",
    allowEmptyGroup = false,
    handleCancel,
    selectType = "radio",
    handleGroupSelect,
    group,
    heading = "Select Your Group"
  } = props;

  return (
    <Modal
      destroyOnClose
      centered
      title={
        <div className="text-center">
          <h4 className="pt-3 fw-bold">{heading}</h4>
        </div>
      }
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      visible={isVisible}
      onCancel={handleCancel}
      width={800}
      footer={null}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      bodyStyle={{
        paddingTop: "0px"
      }}
    >
      <Formik
        initialValues={{
          group: group
        }}
        onSubmit={(values, { setSubmitting }) => {
          // setSubmitting(false);
          handleGroupSelect(values, setSubmitting);
        }}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="text-center">
            <View selectType={selectType} campaign setFieldValue={setFieldValue} campaignGroup={values.group} smallSize={false} {...groupViewProps} hideAddGroupButton={hideAddGroupButton} />
            <AppButton label={btnLabel} type="submit" light disabled={(values.groupObj && values.groupObj.status === "Queued" && !allowEmptyGroup) || isSubmitting} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default Groups;
