import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Tabs, Icon } from "antd";
import Dashboard from "./Dashboard/Dashboard";
import CampaignList from "./Campaign/CampaignList";
import Histroy from "./History/History";
import TemplateList from "./Template/TemplateList";
import BuyCredits from "./BuyCredits/BuyCredits";
import GroupList from "./Group/GroupList";
import Invoice from "./BuyCredits/Invoice";
import QuickActions from "pages/Sms/Dashboard/QuickActions";
import AppLoader from "Mobile/components/Loader/Loader";

const { TabPane } = Tabs;

function TabsMenu(props) {
  const { index, body, authStore } = props;
  const location = useLocation();

  const { user } = authStore;

  const history = useHistory();

  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0");

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    setActiveTab(activeTab);
  }, [activeTab]);

  if (!user) return <AppLoader />;

  let tabOptions = [
    {
      icon: "home",
      label: "Dashboard",
      path: "/",
      roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
      body: <Dashboard />
    },
    // {
    //   key: "0",
    //   icon: "thunderbolt",
    //   label: "Shortcuts",
    //   path: "/quick-actions",
    //   body: <QuickActions />
    // },
    {
      key: "2",
      icon: "notification",
      label: "Campaign",
      path: "/campaign",
      access: ["sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      body: <CampaignList />
    },
    {
      key: "3",
      icon: "snippets",
      label: "Template",
      path: "/template",
      access: ["template", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      body: <TemplateList />
    },
    {
      key: "4",
      icon: "contacts",
      label: "Audience",
      path: "/group",
      access: ["group", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      body: <GroupList />
    },
    {
      key: "5",
      icon: "credit-card",
      label: authStore?.user?.profileType !== "postpaid" ? "Buy Credit" : "Invoice",
      path: authStore?.user?.profileType !== "postpaid" ? "/buy-credits" : "/invoice",
      body: authStore?.user?.profileType !== "postpaid" ? <BuyCredits /> : <Invoice />,
      roles: ["user", "admin", "superAdmin", "guest", "trial"]
    },
    {
      key: "6",
      icon: "history",
      label: "History",
      path: "/history",
      body: <Histroy />,
      access: ["history", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"]
    }
  ];

  return (
    <>
      <Tabs
        activeKey={activeTab}
        tabBarStyle={{ boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)" }}
        onChange={e => {
          localStorage.setItem("activeTab", e);
          setActiveTab(e);
        }}
      >
        {tabOptions.map((item, i) => {
          if (item.roles.includes(user?.role) || (item.access && item.access.some(r => user?.access.includes(r))))
            return (
              <TabPane
                tab={
                  <NavLink
                    to={{
                      pathname: item.path,
                      state: { from: location.pathname }
                    }}
                    className={"text-muted"}
                    strict
                    exact
                  >
                    <span>
                      <Icon type={item.icon} />
                      <br />
                      {item.label}
                    </span>
                  </NavLink>
                }
                key={i}
              >
                <div className="p-3">{props.children}</div>
              </TabPane>
            );
        })}
      </Tabs>
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore
}))(observer(TabsMenu));
