import Resizer from "react-image-file-resizer";
import { MAX_IMG_HEIGHT, MAX_IMG_WIDTH } from "./constant";

// image compressor
export const imageResizer = file => {
  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        MAX_IMG_WIDTH,
        MAX_IMG_HEIGHT,
        "JPEG",
        99,
        0,
        uri => {
          resolve(new File([uri], file?.name, { type: file?.type }));
        },
        "blob"
      );
    } catch (err) {
      console.log(err);
      reject(file);
    }
  });
};

// pdf to image conversion

export const pdfToImage = file => {
  return new Promise(async (resolve, reject) => {
    try {
      // create pdf instance
      const instance = await pdfjsLib.getDocument({
        url: URL.createObjectURL(file)
      });

      // creating canvas element to render instance as image
      const canvas = document.createElement("canvas");
      canvas.width = 400;
      const ctx = canvas.getContext("2d");

      const page = await instance.getPage(1);

      let scale_required = canvas.width / page.getViewport(1).width;
      let viewport = page.getViewport(scale_required);
      canvas.height = viewport.height;

      // render image on canvas
      page
        .render({
          canvasContext: ctx,
          viewport: viewport
        })
        .then(() => {
          canvas.toBlob(blob => {
            resolve(new File([blob], `${file?.name.split(".")[0]}.jpeg` || "image.jpeg", { type: "image/jpeg" }));
            canvas.remove();
          }, "image/jpeg");
        });
    } catch (er) {
      console.log(er);
      resolve(file);
    }
  });
};
