import React, { useEffect, useState, createRef, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Pagination, Menu, Tabs, Dropdown, Tag, Typography } from "antd";
import moment from "moment";
import MobileCard from "Mobile/components/Card/Card";
import AppButton from "components/AppButton/AppButton";
import Header from "Mobile/components/Header/Header";
import AlertModal from "components/Modals/AlertModal";
import AppLoader from "Mobile/components/Loader/Loader";
import { dateTimeFormat, sharedNumber } from "constant/constant";
import ScheduleMessage from "components/GatewayMessage/Modals/ScheduleMessage";
import { messageStatus } from "constant/commonList";
import CampaignTabs from "components/MainCard/TabsView";

const { TabPane } = Tabs;

const CampaignList = props => {
  const { gatewayBulkStore, messageStore, authStore, modalStore, gatewayMmsStore } = props;
  const { user, allSenders } = authStore;
  const { isMms } = modalStore;
  const [focusedReport, setFocusedReport] = useState();
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tabName, setTabName] = useState(location?.state && location?.state?.key ? location.state.key : "history");
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleSuccessAlert, setRescheduleSuccessAlert] = useState(false);
  const [cancelScheduleAlert, setCancelScheduleAlert] = useState(false);
  const [cancelScheduleSuccessAlert, setCancelScheduleSuccessAlert] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [draftAlert, setDraftAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState("Something went wrong!");
  const [alertType, setAlertType] = useState("error");
  const [alertOpen, setAlertOpen] = useState(false);

  const { setCampaignData, getScheduledSMSBulkList, getAllBulkList, getAllSavedBulks, bulkList, selectedBulk, selectBulk, total, pageSize, current, setPage, getExportBulkLogList } = useMemo(() => {
    if (isMms) {
      return {
        ...gatewayMmsStore,
        getExportBulkLogList: gatewayMmsStore.getExportLogList,
        getScheduledSMSBulkList: gatewayMmsStore.getScheduledMMSBulkList,
        getAllBulkList: gatewayMmsStore.getAllGatewayMMSBulk
      };
    } else {
      return gatewayBulkStore;
    }
  });

  useEffect(() => {
    if (user?.role == "subaccount" && !user?.access?.includes("mms") && user?.role == "subaccount" && !user?.access?.includes("sms")) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    if (tabName === "history") {
      getAllBulkList().then(() => setLoading(false));
    } else if (tabName === "saved") {
      getAllSavedBulks().then(() => setLoading(false));
    } else {
      getScheduledSMSBulkList().finally(() => setLoading(false));
    }
  }, [tabName, current, rescheduleSuccessAlert, cancelScheduleSuccessAlert, isMms]);

  if (!bulkList) {
    return <AppLoader />;
  }

  const exportDataLog = async status => {
    const data = await getExportBulkLogList({
      bulk: focusedReport?._id,
      status: status == "all" ? undefined : status
    });
    if (data.success) {
      modalStore.setData("exportMessage", data.message);
      modalStore.setData("exportEmail", data.email);
      modalStore.toggleModal("showExportNotification", true);
      setAlertMessage(data.message);
      setAlertType("Success");
      // setAlertOpen(true);
    } else {
      setAlertMessage(data.message);
      setAlertType("Error");
      setAlertOpen(true);
    }
  };
  const menu = (
    <Menu>
      {messageStatus.map((item, index) => (
        <Menu.Item key={index} onClick={() => exportDataLog(item.value)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  let tabsData = [
    {
      key: "history",
      label: "Recents"
    },
    {
      key: "saved",
      label: "Saved"
    },
    {
      key: "scheduled",
      label: "Scheduled"
    }
  ];

  const handleCreateCampaign = (values, run = false) => {
    if (run) {
      values.groupObj = values.group;
      values.group = values.groupObj._id;
      values.optType = values.replyStopToOptOut ? "replyStopToOptOut" : "optout";
      if (modalStore.isMms) {
        values.url = values.media.url;
        values.mediaType = values.media.filetype;
        values.mediaName = values.media.fileName;
        values.media = undefined;
        messageStore.setCurrentImage(values.media.url, true);
      }
      setCampaignData(values);
      localStorage.setItem("step", 3);
      localStorage.setItem("campaign", JSON.stringify(values));
      history.push("/campaign/create");
    } else if (localStorage.getItem("campaign")) {
      setDraftAlert(true);
    } else {
      localStorage.setItem("step", 0);
      localStorage.removeItem("campaign");
      setCampaignData(null);
      messageStore.setCurrentMessage("");
      messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
      messageStore.setCurrentImage(null);
      history.push("/campaign/create");
    }
  };

  function rescheduleMessage(values) {
    gatewayBulkStore.updateScheduleBulk(values).then(res => {
      if (res.status) {
        setRescheduleSuccessAlert(true);
      }
    });
    setRescheduleModal(false);
  }

  return (
    <div className="pb-3">
      <Header headerName="Campaign List" btn btnLabel="Add Campaign" onClick={handleCreateCampaign} />
      <hr />
      <div className="my-3">
        <CampaignTabs isMobile>
          <Tabs
            defaultActiveKey={tabName}
            onChange={e => {
              setTabName(e);
              setPage(1);
            }}
            className="p-2"
          >
            {tabsData.map(tab => (
              <TabPane tab={tab.label} key={tab.key}>
                <Row>
                  <Col span={24}>
                    {loading ? (
                      <AppLoader />
                    ) : bulkList.length > 0 ? (
                      <>
                        {bulkList.map((item, i) => (
                          <MobileCard
                            onClick={() => {
                              if (tabName === "pending" || tabName === "quicksms") {
                                return;
                              }
                              setFocusedReport(item);
                              if (selected === item._id) {
                                setSelected(0);
                              } else {
                                setSelected(item._id);
                              }
                            }}
                            selected={selected}
                            index={item._id}
                            key={i}
                            desc={
                              <>
                                <Col span={24}>
                                  <Typography.Text ellipsis>
                                    Campaign Name - <span className="fw-bold text-info-color text-wrap">{item?.name}</span>
                                  </Typography.Text>
                                </Col>
                                <Col span={24} className="d-flex flex-row">
                                  {tabName === "saved" ? (
                                    <>
                                      <span className="text-nowrap me-">Message - </span>

                                      <span className="text-wrap me-">{item?.message}</span>
                                    </>
                                  ) : (
                                    <>
                                      <span className={`text-nowrap me-2`}>Status - </span>
                                      <Tag color={messageStatus.find(el => el.value === item.status)?.color}>{item.status ? messageStatus.find(el => el.value === item.status)?.label : "Sent"}</Tag>
                                    </>
                                  )}
                                </Col>
                              </>
                            }
                            content={
                              <Row>
                                {item?.scheduleAt && (
                                  <Col span={24} className="d-flex flex-row align-items-center">
                                    <span className={`text-nowrap me-2`}>Scheduled Time</span>
                                    <h6 className={`fw-bold m-0 text-info-color`}> {moment(item?.scheduleAt).format(dateTimeFormat)}</h6>
                                  </Col>
                                )}
                                <Col span={24} className="d-flex flex-row align-items-center">
                                  <span className={`text-nowrap me-2`}>Created Time</span>
                                  <h6 className={`fw-bold m-0 text-info-color`}> {moment(item?.createdAt).format(dateTimeFormat)}</h6>
                                </Col>
                              </Row>
                            }
                            extra={
                              tabName === "saved" ? (
                                <AppButton label="Use" className="text-info-color bg-light rounded-pill mt-2" style={{ fontSize: "12px" }} onClick={() => handleCreateCampaign(item, true)} />
                              ) : item.status === "schduled" ? (
                                <>
                                  {user?.role === "subaccount" ? (
                                    user?.roleAccess.find(item => (item.feature === modalStore.isMms ? "mms" : "sms"))?.edit
                                  ) : true ? (
                                    <div className="d-flex flex-row gap-3">
                                      <AppButton
                                        label="Reschedule"
                                        onClick={() => {
                                          setRescheduleModal(true);
                                          selectBulk(item);
                                        }}
                                        className="text-info-color bg-light rounded-pill mt-2"
                                        style={{ fontSize: "12px" }}
                                      />
                                      <AppButton
                                        onClick={() => {
                                          setCancelScheduleAlert(true);
                                          selectBulk(item);
                                        }}
                                        label="Cancel"
                                        className="text-info-color bg-light rounded-pill mt-2"
                                        style={{ fontSize: "12px" }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : item.status === "cancel" || item.status === "complete" ? null : (
                                <Dropdown overlay={menu} trigger={["click"]}>
                                  <AppButton label="Export Log" className="text-info-color bg-light rounded-pill mt-2" style={{ fontSize: "12px" }} />
                                </Dropdown>
                              )
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <div className="text-center">
                        <p className="text-info-color mt-4">No Campaign Found</p>
                      </div>
                    )}
                  </Col>
                  <Col span={24}>
                    <Pagination
                      defaultCurrent={1}
                      total={total}
                      pageSize={pageSize}
                      onChange={page => setPage(page)}
                      className="text-start"
                      simple={true}
                      current={current}
                      hideOnSinglePage
                      showQuickJumper
                    />
                  </Col>
                </Row>
              </TabPane>
            ))}
          </Tabs>
        </CampaignTabs>
      </div>

      <AlertModal
        visible={draftAlert}
        title="Use draft campaign"
        description="You have a pending campaign stored. Wanna use it?"
        primaryBtnProps={{
          label: "Use Draft",
          onClick: () => {
            history.push("/campaign/create");
            setDraftAlert(false);
          }
        }}
        secondaryBtn
        secondaryBtnProps={{
          label: "Create New",
          onClick: () => {
            setDraftAlert(false);
            localStorage.removeItem("campaign");
            setCampaignData(null);
            localStorage.setItem("step", 0);
            history.push("/campaign/create");
            messageStore.setCurrentMessage("");
            messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
            messageStore.setCurrentImage(null);
          }
        }}
        onCancel={() => setDraftAlert(false)}
        closable
        warning
      />
      <ScheduleMessage
        isVisible={rescheduleModal}
        handleCancel={() => {
          setRescheduleModal(false);
          selectBulk(null);
        }}
        onSubmit={values => {
          let data = {
            queueJobId: "accept",
            scduleid: values.bulk._id,
            scduledate: values.selectedDateTime
          };
          rescheduleMessage(data);
        }}
        modify
        formValues={selectedBulk}
      />
      <AlertModal
        visible={alertOpen}
        title={alertType}
        description={alertMessage}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setAlertOpen(false)
        }}
        closable
        onCancel={() => setAlertOpen(false)}
        error={alertType === "Error"}
      />
      <AlertModal
        visible={rescheduleSuccessAlert}
        title="Campaign Rescheduled"
        description={`${selectedBulk ? selectedBulk.name : ""} Campaign has been successfully rescheduled.`}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setRescheduleSuccessAlert(false)
        }}
        closable
        onCancel={() => setRescheduleSuccessAlert(false)}
      />
      <AlertModal
        visible={cancelScheduleAlert}
        title={`Cancel Scheduled Campaign`}
        description="Are you sure you want to cancel this campaign?"
        primaryBtnProps={{
          onClick: () => {
            setCancelLoading(true);
            gatewayBulkStore
              .cancelScheduleBulk({ scduleid: selectedBulk._id })
              .then(res => {
                if (res.status) {
                  setCancelScheduleSuccessAlert(true);
                }
              })
              .finally(() => {
                setCancelLoading(false);
                setCancelScheduleAlert(false);
                selectBulk(null);
              });
          }
        }}
        secondaryBtn
        secondaryBtnProps={{
          onClick: () => {
            setCancelScheduleAlert(false);
            selectBulk(null);
          }
        }}
        loading={cancelLoading}
        warning
      />
      <AlertModal
        visible={cancelScheduleSuccessAlert}
        title="Scheduled Campaign Canceled!"
        content={`Campaign which is scheduled is cancelled successfully and Debited Balance will be added to your account soon.`}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setCancelScheduleSuccessAlert(false);
          }
        }}
      />
    </div>
  );
};

export default inject(stores => ({
  gatewayBulkStore: stores.store.gatewayBulkStore,
  messageStore: stores.store.messageStore,
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  gatewayMmsStore: stores.store.gatewayMmsStore
}))(observer(CampaignList));
