import React from "react";
import { Form, Input } from "antd";
import styles from "./formItems.module.scss";

function FormInput({
  label,
  value,
  onChange,
  name,
  placeholder = "Enter",
  type = "text",
  secondary = false,
  size = "large",
  prefix,
  style,
  suffix,
  error,
  touched,
  disabled,
  containerClassname = "",
  inputClassname = "",
  containerProps = {},
  inputProps = {},
  addonAfter,
  textarea = false,
  required = false
}) {
  let finalClassNames = styles.formInputPrimary;
  if (secondary) {
    finalClassNames = styles.formInputSecondary;
  }

  return (
    <Form.Item
      label={label}
      validateStatus={error && touched ? "error" : ""}
      help={error && touched ? error : ""}
      className={`${finalClassNames} ${containerClassname}`}
      {...containerProps}
      style={style && style}
    >
      {textarea ? (
        <Input.TextArea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          prefix={prefix}
          suffix={suffix}
          size={size}
          className={`${inputClassname}`}
          disabled={disabled}
          {...inputProps}
          required={required}
          addonafter={addonAfter}
        />
      ) : (
        <Input
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          prefix={prefix}
          suffix={suffix}
          size={size}
          className={`${inputClassname}`}
          disabled={disabled}
          {...inputProps}
          required={required}
          addonafter={addonAfter}
        />
      )}
    </Form.Item>
  );
}

export default FormInput;
