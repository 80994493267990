import React, { useEffect, useState } from "react";
import { Col, Switch } from "antd";
import { inject, observer } from "mobx-react";
import AppDropdown from "components/AppDropdown/AppDropdown";
import { messageTypes } from "constant/commonList";

const BundleType = ({ modalStore }) => {
  const [checked, setChecked] = useState("sms");

  let options = messageTypes;

  options = options.map(item => {
    return {
      ...item,
      icon: item.value == "mms" ? "fas fa-envelope" : "fas fa-sms"
    };
  });

  useEffect(() => {
    setChecked(modalStore.isMms ? "mms" : "sms");
  }, [modalStore.isMms]);
  return (
    <Col span={24} className="text-center">
      <AppDropdown
        options={options}
        value={checked}
        label={checked}
        btnClassName="text-uppercase px-3 fw-bold"
        btnProps={{
          light: true,
          withoutBg: true,
          postfixIcon: <i className="fa fa-chevron-down ms-5" />
        }}
        onClick={value => {
          setChecked(value);
          modalStore.setIsMms(value === "mms");
        }}
      />
    </Col>
    // <Col span={24} className="buy-switch text-center">
    //   <Switch
    //     checkedChildren="SMS"
    //     className="mb-4"
    //     unCheckedChildren="MMS"
    //     checked={checked}
    //     onChange={checked => modalStore.setIsMms(checked)}
    //   />
    // </Col>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(BundleType));
