import React from "react";
import { Upload } from "antd";
import uploadImg from "assets/images/common/uploadfile.svg";
import dragImg from "assets/images/common/dragfile.svg";

const { Dragger } = Upload;

function UploadFile(props) {
  const { upload = false, label } = props;

  return (
    <>
      {upload ? (
        <Upload {...props} className="shadow ant-upload-drag" maxCount={1}>
          <h5 className="text-center fw-bold w-75 mx-auto mt-3">{label}</h5>
          <div className="d-flex justify-content-center align-items-center py-5">
            <img src={uploadImg} alt="upload" loading="lazy" />
          </div>
        </Upload>
      ) : (
        <Dragger {...props} className="shadow" maxCount={1}>
          <h5 className="text-center fw-bold w-75 mx-auto mt-3">{label}</h5>
          <div className="d-flex justify-content-center align-items-center py-3">
            <img src={dragImg} alt="upload" loading="lazy" />
          </div>
        </Dragger>
      )}
    </>
  );
}

export default UploadFile;
