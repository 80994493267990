import { get, put } from "utils/api";

/**
 * Call to check shortcode
 * @returns {Promise<any>}
 */

export const checkShortCode = async code => {
  const checkShortCodeResponse = await get(`/reply/check/${code}`);
  return checkShortCodeResponse;
};

export const confirmReply = async (code, params) => {
  const confirmReplyResponse = await put(`/reply/confirm/${code}`, params);
  return confirmReplyResponse;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
