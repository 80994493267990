import { get, post, put, deleteRequest } from "utils/api";
export const sendMail = async (params = {}) => {
  const sendMailResponse = await post(`/emailmessage/emailtosupport`, params);
  return sendMailResponse.data;
};

export const getMailMessage = async params => {
  const getMailMessageResponse = await get(`/emailmessage`, params);
  return getMailMessageResponse.data;
};

export const sendEnqiuryMail = async data => {
  const sendEnqiuryMailResponse = await post(`/inquiry`, data);
  return sendEnqiuryMailResponse.data;
};
