import React, { useEffect, useState } from "react";
import { PUBLIC_URL } from "constant/apiService";
import { useParams, useHistory } from "react-router-dom";

const Waiting = () => {
  const history = useHistory();
  const { redirect } = useParams();

  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      history.replace(`/${redirect}`);
    }
  }, [countDown]);

  return (
    <div className="m-3 vh-center flex-column gap-3 bg-white" style={{ minHeight: "calc(100vh - 70px)" }}>
      <img src={PUBLIC_URL + "/images/running-robo_1.gif"} alt="loading" width={400} />
      <h4 className="fw-bold fs-5">Redirecting to dashboard in {countDown} sec...</h4>
      {/* <div className="fw-normal mt-2">
        <p className="fw-bold">Olympic Games Paris 2024</p>
        <p className="text-muted">26 July 2024 – 11 August 2024</p>
      </div> */}
    </div>
  );
};

export default Waiting;
