import React from "react";
import { inject, observer } from "mobx-react";
import Header from "Mobile/components/Header/Header";
import AppLoader from "Mobile/components/Loader/Loader";
import PurchaseGateway from "pages/Purchase/PurchaseGateway";

const BuyCredits = props => {
  const { authStore } = props;

  if (!authStore?.user) {
    return <AppLoader />;
  }

  return (
    <>
      <Header headerName="Buy Credit" />
      <hr />
      <PurchaseGateway isMobile />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(BuyCredits));
