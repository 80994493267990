import React from "react";
import { Checkbox, Form } from "antd";

const FormCheckBoxInput = props => {
  const { name, label, disabled, placeholder, values, errors, touched, handleChange, containerClassname, onClick, ...rest } = props;
  return (
    <Form.Item label={label} validateStatus={errors && touched ? "error" : ""} help={errors && touched ? errors : null} className={containerClassname}>
      <Checkbox
        disabled={disabled}
        checked={values[name]}
        name={name}
        onChange={handleChange}
        onClick={e => {
          e.stopPropagation();
          onClick && onClick(e);
        }}
        {...rest}
      >
        {placeholder}
      </Checkbox>
    </Form.Item>
  );
};

export default FormCheckBoxInput;
