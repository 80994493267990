import { post } from "utils/api";

/**
 * Send SMS
 * @returns {Promise<any>}
 */
export const SendSMS = async params => {
  const SMSResponse = await post("/sms", params);
  return SMSResponse.data;
};
