import React, { useState, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Tooltip, Typography } from "antd";
import AppButton from "components/AppButton/AppButton";
import UploadFile from "components/UploadFile/UploadFile";
import AlertModal from "components/Modals/AlertModal";
import { FileExcelOutlined, LoadingOutlined } from "@ant-design/icons";
import { beforeUpload } from "constant/contacts";
import FormInput from "components/FormItems/FormInput";

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required("Select a file to upload")
});

const FileImportForm = props => {
  const { modalStore, groupStore, authStore, integration } = props;
  const { group, setGroupData } = groupStore;
  const formikRef = useRef();
  const [fileSizeAlert, setFileSizeAlert] = useState(false);
  const [contactLimitAlert, setContactLimitAlert] = useState(false);
  const [fileSupportAlert, setFileSupportAlert] = useState(false);
  const [nameEdit, setNameEdit] = useState(false);

  const initialValues = {
    name: group ? group.name : "",
    file: ""
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (group && parseInt(group.totalContacts) + values?.xlsFileData.length > authStore.user?.contactLimit) {
            setContactLimitAlert(true);
            modalStore.toggleModal("showImportContactsModal", false);
            setSubmitting(false);
            resetForm();
            return false;
          }
          setGroupData(values);
          modalStore.toggleModal("showImportContactsModal", false);
          modalStore.toggleModal("showMergeLabelModal", true);
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row type="flex" justify="center">
              <Col span={24}>
                {values.file ? (
                  <div className="position relative w-100">
                    <i
                      className="text-danger-color fas fa-trash-alt position-absolute top-0 end-0 m-2"
                      type="button"
                      onClick={() => {
                        setFieldValue("file", "");
                        setFieldValue("xlsFileData", []);
                      }}
                    />
                    <div className="text-center d-flex flex-column border border-info-color px-4 py-5 rounded shadow">
                      <div style={{ width: "100px", height: "100px" }} className="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto">
                        <FileExcelOutlined className="fs-1 text-info-color" />
                      </div>
                      <Typography.Text strong className="text-dark fs-6 mt-4">
                        Group Name
                        <Tooltip title={nameEdit ? "Save" : "Edit"}>
                          <i
                            className={`fas fa-${nameEdit ? "check" : "edit"} text-info-color ms-2`}
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                              setNameEdit(!nameEdit);
                            }}
                          />
                        </Tooltip>
                      </Typography.Text>
                      {nameEdit ? (
                        <FormInput
                          name="name"
                          placeholder="Group Name"
                          containerClassname="mx-auto"
                          size="default"
                          style={{ width: "200px" }}
                          value={values.name}
                          inputProps={{
                            maxLength: 80
                          }}
                          onChange={handleChange}
                        />
                      ) : (
                        <Typography.Text ellipsis className="w-100 text-info-color fs-6 mb-4 mt-2">
                          {values.name}
                        </Typography.Text>
                      )}
                    </div>
                  </div>
                ) : (
                  <Form.Item name="file" validateStatus={errors.file && touched.file && "error"} help={touched.file && errors.file}>
                    <UploadFile
                      label="Click or drag and drop to upload file"
                      upload={true}
                      accept=".xls,.xlsx,.csv"
                      showUploadList={false}
                      beforeUpload={file => {
                        beforeUpload({
                          authStore,
                          file,
                          setFieldValue,
                          setFileSizeAlert,
                          setFileSupportAlert,
                          setContactLimitAlert
                        });
                        if (!group) {
                          setFieldValue("name", file.name);
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={24} className="text-center vh-center gap-2">
                {integration.importContactFormProps?.btn && (
                  <AppButton
                    light
                    withoutBg
                    label="Previous"
                    className="mt-3 px-3"
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                    onClick={integration.importContactFormProps?.btn}
                    prefixIcon={<i className="bx bx-left-arrow-alt me-1" />}
                  />
                )}
                <AppButton isSubmitting={isSubmitting} label="Next" light type="submit" disabled={isSubmitting} className="mt-3 px-3" postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />} />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <AlertModal
        visible={fileSizeAlert}
        title="File size to large"
        description="Selected file size is to large, file size must be less than 5MB."
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setFileSizeAlert(false)
        }}
        closable
        onCancel={() => setFileSizeAlert(false)}
        error
      />
      <AlertModal
        visible={fileSupportAlert}
        title="File not supported"
        description={"File must be in .xls, .xlsx or .csv format"}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setFileSupportAlert(false)
        }}
        closable
        onCancel={() => setFileSupportAlert(false)}
        error
      />
      <AlertModal
        visible={contactLimitAlert}
        title={`Contact limit exceeded!`}
        description={`Your per group contact limit is ${authStore.user?.contactLimit}`}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setContactLimitAlert(false)
        }}
        closable
        onCancel={() => setContactLimitAlert(false)}
        error
      />
    </>
  );
};
export default inject(stores => ({
  groupStore: stores.store.groupStore,
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore,
  integration: stores.store.integrationStore
}))(observer(FileImportForm));
