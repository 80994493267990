import { get } from "utils/api";

/**
 * Call get all bulk api
 * @returns {Promise<any>}
 */
export const getAllIncomings = async params => {
  // save api response for Bulk List list
  const getAllIncomingsResponse = await get("/incoming", params);
  return getAllIncomingsResponse.data;
};
