import React, { useEffect, useState } from "react";
import FileUpload from "assets/img/fileUpload.png";
import HubspotLogo from "assets/images/thirdparty app/hubspot.png";
import ActiveCampLogo from "assets/images/thirdparty app/activeCampaignLogo.png";
import ZohoLogo from "assets/images/thirdparty app/zoho.png";
import GuniLogo from "assets/images/thirdparty app/guniSms.png";

const BrandProgressiveBar = ({ className, method }) => {
  className = "vh-center gap-2 " + className;

  const [width, setWidth] = useState(90);
  const [brand, setBrand] = useState(FileUpload);

  useEffect(() => {
    if (method === "hubspot") {
      setWidth(40);
      setBrand(HubspotLogo);
    } else if (method === "zoho") {
      setWidth(95);
      setBrand(ZohoLogo);
    } else if (method === "ac") {
      setWidth(65);
      setBrand(ActiveCampLogo);
    } else {
      setWidth(50);
      setBrand(FileUpload);
    }
  }, [method]);

  return (
    <div className={className}>
      <img alt="logo" src={brand} width={width} className="img-fit" />
      <span class="progressLine">
        <span class="progressPoint"></span>
      </span>
      <img width={100} src={GuniLogo} alt="guni logo" className="ms-1 img-fit" />
    </div>
  );
};

export default BrandProgressiveBar;
