import { decorate, observable, runInAction } from "mobx";
import { getConversation, sendSMS } from "service/messageService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableMessageStoreOld {
  conversation = [];
  pageSize = 10;
  current = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  search = "";
  selectedRoom = {};

  //iphone frame ui params
  iphoneFrameMessage = null;
  iphoneFrameSender = null;
  iphoneFrameImageUrl = null;

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setSelectedRoom = selectedRoom => {
    runInAction(() => {
      this.selectedRoom = selectedRoom;
      this.pageSize = 10;
      this.current = 1;
      this.total = 0;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * get all test cases
   * @returns {Promise<[]>}
   */
  getConversationHistory = async (room, isAdd) => {
    // call & save test case list response
    const params = {
      room,
      page: isAdd ? this.current + 1 : this.current
      // sortField: this.sortField,
      // sortOrder: this.sortOrder
    };
    if (this.search.length > 0) params.search = this.search.search;
    const messageHistoryResponse = await getConversation(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = messageHistoryResponse;
        this.conversation = isAdd ? _.uniq(_.concat(mobx.toJS(this.conversation), items || messageHistoryResponse), "_id") : _.uniq(items || messageHistoryResponse, "_id");
        this.selectedTestCase = undefined;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.conversation;
  };

  /**
   * Start Test Case
   * @param data
   * @returns {Promise<any>}
   */
  sendSMS = async (data, mode) => {
    return await sendSMS(data, mode);
  };

  addMessage = message => {
    try {
      runInAction(() => {
        if (this.selectedRoom && this.selectedRoom._id && message && message.room) {
          if (this.selectedRoom._id === message.room) {
            const oldLength = this.conversation.length;
            const newMessages = _.mergeById(this.conversation, message, "_id", true);
            const newLength = newMessages.length;
            if (oldLength < newLength) {
              this.conversation = newMessages;
              this.total = newMessages.length;
            }
          }
        }
      });
    } catch (error) {
      console.error("Error", error);
    }
    return this.conversation;
  };
}

decorate(ObservableMessageStoreOld, {
  selectedRoom: observable,
  conversation: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  iphoneFrameMessage: observable,
  iphoneFrameSender: observable,
  iphoneFrameImageUrl: observable
});
export default new ObservableMessageStoreOld();
