import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Col, Pagination, Typography, Tabs } from "antd";
import AppButton from "components/AppButton/AppButton";
import MobileCard from "Mobile/components/Card/Card";
import Header from "Mobile/components/Header/Header";
import AlertModal from "components/Modals/AlertModal";
import TabsView from "components/MainCard/TabsView";
import AppLoader from "Mobile/components/Loader/Loader";

const { TabPane } = Tabs;

const TemplateList = props => {
  const { templateStore, modalStore, authStore } = props;
  const { user } = authStore;
  const { isMms } = modalStore;
  const { getAllTemplateList, templateList, setPage, current, deleteTemplate, selectTemplate, total, setVisibility, visibility } = templateStore;

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [tabMenu, setTabMenu] = useState([{ label: "My Templates", value: "user" }]);

  useEffect(() => {
    if (user?.role == "subaccount" && !user?.access?.includes("mms") && user?.role == "subaccount" && !user?.access?.includes("sms")) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    getAllTemplateList({
      mode: isMms ? "mms" : "sms",
      visibility: isMms ? "user" : visibility
    }).finally(() => {
      setLoading(false);
    });
    setTabMenu([{ label: "My Templates", value: "user" }, !isMms && { label: "Pre-Made", value: "global" }].filter(Boolean));
  }, [current, visibility, isMms]);

  const editTemplate = src => {
    selectTemplate(src);
    if (src.global) {
      history.push(`/template/save/${src._id}`);
    } else {
      history.push(`/template/edit/${src._id}`);
    }
  };

  if (!templateList) {
    return <AppLoader />;
  }

  return (
    <div className="pb-3">
      <Header headerName="Template List" btn onClick={() => selectTemplate("")} btnLabel="Add Template" path="/template/add" />
      <hr />
      <TabsView isMobile>
        <Tabs onChange={e => setVisibility(e)} defaultActiveKey={visibility} className="p-2">
          {tabMenu.map(item => (
            <TabPane tab={item.label} key={item.value}>
              {templateList?.length > 0 ? (
                <>
                  {templateList.map((item, i) => (
                    <MobileCard
                      key={i}
                      index={item._id}
                      selected={selected}
                      onClick={() => {
                        if (selected === item._id) {
                          setSelected(0);
                        } else {
                          setSelected(item._id);
                        }
                      }}
                      desc={
                        <>
                          <Col span={24} className="d-flex flex-row">
                            <span className={`text-nowrap me-2`}>Template Name - </span>
                            <Typography.Text ellipsis className={`fw-bold`}>
                              {item?.name}
                            </Typography.Text>
                          </Col>
                          {/* <AppButton
                            label={"Use"}
                            onClick={() => {
                              editTemplate(item);
                            }}
                            className="text-info-color bg-light rounded-pill mt-2 me-2"
                            style={{ fontSize: "12px" }}
                          /> */}
                        </>
                      }
                      content={
                        <>
                          {isMms && (
                            <>
                              <Typography.Paragraph
                                className="text-break"
                                ellipsis={{
                                  rows: 3,
                                  expandable: true,
                                  symbol: "more"
                                }}
                                style={{ whiteSpace: "break-spaces" }}
                              >
                                <Typography.Text strong>Subject:</Typography.Text> {item?.subject}
                              </Typography.Paragraph>
                              {item?.media && (
                                <div
                                  style={{
                                    maxWidth: "fit-content",
                                    background: "#149cbe30"
                                  }}
                                  className="rounded p-4 mb-3"
                                >
                                  <i className="fas fa-file fs-1 text-info-color" />
                                </div>
                              )}
                            </>
                          )}
                          <Typography.Paragraph
                            className="text-break"
                            ellipsis={{
                              rows: 3,
                              expandable: true,
                              symbol: "more"
                            }}
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            <Typography.Text strong>Message:</Typography.Text> {item?.content}
                          </Typography.Paragraph>
                        </>
                      }
                      extra={
                        <>
                          <AppButton
                            label={item.global ? "Use" : "Edit Content"}
                            onClick={() => {
                              editTemplate(item);
                            }}
                            className="text-info-color bg-light rounded-pill mt-2 me-2"
                            style={{ fontSize: "12px" }}
                          />
                          {!item.global && (
                            <AppButton
                              label="Delete"
                              onClick={() => {
                                setDeleteAlert(true);
                                setTemplateId(item._id);
                              }}
                              className="text-info-color bg-light rounded-pill mt-2"
                              style={{ fontSize: "12px" }}
                            />
                          )}
                        </>
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  {loading ? (
                    <AppLoader />
                  ) : (
                    <div className="text-center">
                      <p className="text-info-color mt-4">No Template Found</p>
                    </div>
                  )}
                </>
              )}
              <Pagination current={current} total={total} onChange={page => setPage(page)} className="text-start mt-2" simple={true} showQuickJumper hideOnSinglePage />
            </TabPane>
          ))}
        </Tabs>
      </TabsView>

      <AlertModal
        visible={deleteAlert}
        title="Delete Template"
        description="Are you sure you want to delete this template?"
        primaryBtnProps={{
          onClick: () => {
            deleteTemplate(templateId)
              .then(res => {
                if (res.status) {
                  setDeleteSuccess(true);
                }
              })
              .finally(() => {
                setDeleteAlert(false);
                setTemplateId("");
              });
          }
        }}
        secondaryBtnProps={{
          onClick: () => setDeleteAlert(false)
        }}
        secondaryBtn
        warning
      />
      <AlertModal
        visible={deleteSuccess}
        title="Template Deleted!"
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setDeleteSuccess(false)
        }}
        closable
        onCancel={() => setDeleteSuccess(false)}
      />
    </div>
  );
};

export default inject(stores => ({
  templateStore: stores.store.templateStore,
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore
}))(observer(TemplateList));
