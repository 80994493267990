import React from "react";
import { Avatar } from "antd";
import _ from "lodash";
import { themeColors } from "constant/commonList";

function ChatAvatar(props) {
  const { contactName, contactNumber } = props;
  let content;
  if (contactName) {
    if (contactName?.includes(" ")) {
      const [firstName, lastName] = contactName.split(" ");
      content = _.upperCase(firstName?.[0] + lastName?.[0]);
    } else {
      content = _.upperCase(contactName?.[0]);
    }
  } else {
    content = contactNumber && contactNumber.slice(-2);
  }

  function getColor() {
    let num = contactNumber && contactNumber.slice(-1);
    if (num < 5) return themeColors[num];
    else return themeColors[num - 5];
  }

  let style = {
    background: getColor(),
    width: "40px",
    height: "40px",
    fontSize: "18px",
    lineHeight: "42px"
  };

  return (
    <Avatar style={style} className="mx-2 fw-900">
      {content || ""}
    </Avatar>
  );
}

export default ChatAvatar;
